import { httpsCallable } from 'firebase/functions';

import { functions } from '../../../../config/firebase';

class Analytics {
  static create({
    channelId,
    data,
  }: {
    channelId: string;
    data: {
      language: string;
      pathname: string;
      referrer: string | null;
      sid: string;
      uid: string;
      userAgent: string;
    };
  }): void {
    const func = httpsCallable(functions, 'analytics');

    func({ channelId, data, kind: 'create' });
  }

  static async kpis({
    channelId,
    end,
    start,
  }: {
    channelId: string;
    end?: string;
    start?: string;
  }): Promise<{ nbPages: number; nbSessions: number; nbUsers: number }> {
    const func = httpsCallable(functions, 'analytics');

    return func({
      channelId,
      data: {
        end,
        start,
      },
      kind: 'kpis',
    }).then(
      r =>
        r.data as {
          nbPages: number;
          nbSessions: number;
          nbUsers: number;
        },
    );
  }

  static async page({
    channelId,
    end,
    pathname,
    start,
  }: {
    channelId: string;
    end?: string;
    pathname: string;
    start?: string;
  }): Promise<
    {
      createdAt: string;
      count: number;
    }[]
  > {
    const func = httpsCallable(functions, 'analytics');

    return func({
      channelId,
      data: {
        end,
        pathname,
        start,
      },
      kind: 'page',
    }).then(
      r =>
        r.data as {
          createdAt: string;
          count: number;
        }[],
    );
  }

  static async users({
    channelId,
    end,
    start,
  }: {
    channelId: string;
    end?: string;
    start?: string;
  }): Promise<
    {
      createdAt: string;
      count: number;
    }[]
  > {
    const func = httpsCallable(functions, 'analytics');

    return func({
      channelId,
      data: {
        end,
        start,
      },
      kind: 'users',
    }).then(
      r =>
        r.data as {
          createdAt: string;
          count: number;
        }[],
    );
  }
}

export default Analytics;
