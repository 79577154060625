import objectHash from 'object-hash';
import React, { FC, SyntheticEvent } from 'react';

interface CheckboxProps {
  checked?: boolean;
  label: string;
  name: string;
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  value: string;
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  label,
  name,
  onChange,
  value,
}) => {
  const id = objectHash({ label, value });

  return (
    <label className="flex space-x-3 items-center" htmlFor={id}>
      <input
        checked={checked}
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
