import { Box, DataProps, DataSection, Group } from 'packages/formidable';
import React, { FC } from 'react';

export interface DataButtonStylesProps
  extends Omit<DataProps, 'componentType'> {
  formName: string;
  name: string;
}
const DataButtonStyles: FC<DataButtonStylesProps> = function ({
  display,
  name,
  formName,
  title,
  params,
}) {
  const datas = {
    componentType: 'input',
    label: 'Déclinaison couleur',
    name: 'variant',
    type: 'text',
  };

  return (
    <Box>
      <Group
        display={display}
        formName={formName}
        params={params}
        title={title}
      >
        <DataSection
          datas={datas}
          formName={formName}
          name={name}
          params={{
            ...params,
            name,
          }}
        />
      </Group>
    </Box>
  );
};

export default DataButtonStyles;
