import React, { FC, useEffect, useRef } from 'react';

// import Icon from '~/components/Icon';
// import IconFileVideo from '~/icons/FileVideo';

interface MediaOptions {
  background?: string;
  crop?: string;
  height?: number;
  width?: number;
}

const MediaVideo: FC<{
  className?: string;
  media: any;
  options?: MediaOptions;
  thumbnail?: boolean;
}> = ({ className, media, options, thumbnail }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { height = 640, width = 640 } = options ?? {};

  useEffect(() => {
    const { current } = videoRef;

    const resize = () => {
      if (current) {
        current.style.height = `${current.offsetWidth}px`;
      }
    };

    if (current) {
      window.addEventListener('resize', resize);
      resize();
    }

    return () => {
      if (current) {
        window.removeEventListener('resize', resize);
      }
    };
  }, [videoRef]);

  const src = `${media.origin}/${media.pathname}`;

  return (
    <video
      ref={videoRef}
      className={className}
      controls={!thumbnail}
      crossOrigin="anonymous"
      muted
      src={src}
      style={{
        height,
        width,
      }}
    />
  );
};

export default MediaVideo;
