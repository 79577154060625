import styled from '@emotion/styled';
import { ContentType, DocumentType } from '@innedit/innedit-type';
import classnames from 'classnames';
import { ContentData } from 'packages/innedit';
import { rem, rgba } from 'polished';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';

import Button from '~/components/Button';
import IconDelete from '~/icons/Delete';
import IconEdit from '~/icons/Edit';
import control from '~/styles/control';

const Box = styled.div`
  &.has-textarea:focus-within {
    border-color: ${control.border.color.hover};
    box-shadow: 0 0 ${rem(1)} ${rem(3)}
      ${rgba(control.border.color.hover, 0.125)};
    background: ${control.bg.color.focus};
  }
`;
const Textarea = styled.textarea`
  resize: none;
`.withComponent(TextareaAutosize);

interface DataContentsExtraItemProps {
  onChange: () => void;
  reset?: boolean;
  submitSucceeded?: boolean;
  value: DocumentType<ContentType>;
}

const DataContentsExtraItem: FC<DataContentsExtraItemProps> = function ({
  onChange,
  reset,
  submitSucceeded,
  value,
}) {
  // const dispatch = useDispatch();
  const [textChanged, setTextChanged] = useState<boolean>(false);
  const { t } = useTranslation();
  const [text, setText] = useState<string | undefined>(value.text);

  const contentData = new ContentData({
    espaceId: value.espaceId,
    parentCollectionName: value.parentCollectionName,
    parentId: value.parentId,
  });

  useEffect(() => {
    if (submitSucceeded && textChanged) {
      handleSubmitOnClick();
    }
  }, [submitSucceeded, textChanged]);

  useEffect(() => {
    if (reset && textChanged) {
      setText(value.text);
    }
  }, [reset, textChanged]);

  useEffect(() => {
    const tmp =
      value.text && text
        ? 0 !== text?.localeCompare(value.text)
        : value.text !== text;
    setTextChanged(tmp);

    if (tmp) {
      onChange();
    }
  }, [text, value.text]);

  const handleOnChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  const handleDeleteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    // TODO Ajouter une validation
    await contentData.delete(value.id);
  };

  const handleSubmitOnClick = async () => {
    await contentData.update(value.id, {
      ...value,
      text,
    });
  };

  const to = `/espaces/${value.espaceId}/contents/${value.parentCollectionName}/${value.parentId}/${value.id}/update/`;

  const nb = text?.replace(/\n/g, ' ').trim().split(' ').length;

  return (
    <div className="flex flex-col">
      <Box className={classnames('has-textarea rounded border')}>
        <div className="px-3 py-1 outline-offset-4">
          <Textarea
            className="w-full bg-transparent leading-6 outline-none focus:outline-none"
            onChange={handleOnChange}
            value={text}
          />
        </div>
      </Box>
      <div className="flex justify-between px-3 py-1">
        <div className="flex space-x-1 text-[10px] text-light-800">
          <span>{t(`utility.counter.type.word`, { count: nb })}</span>
        </div>

        <div className="flex space-x-2">
          <Button iconLeft={IconEdit} size="xs" to={to} variant="link" />
          <Button
            iconLeft={IconDelete}
            onClick={handleDeleteOnClick}
            size="xs"
            variant="link"
          />
        </div>
      </div>
    </div>
  );
};

export default DataContentsExtraItem;
