import { DocumentType, ModelType } from '@innedit/innedit-type';
import { diff } from 'deep-object-diff';
import React, { ReactElement, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset, submit } from 'redux-form';

import Actions, { MenuType } from '~/components/Actions';
import IconArchive from '~/icons/Archive';
import IconBoxArchivedSlash from '~/icons/BoxArchivedSlash';
import IconDelete from '~/icons/Delete';
import { ReducersProps } from '~/reducers';
import { colors } from '~/styles/theme';

interface FormActionsProps<T extends ModelType> {
  archiveOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  doc?: DocumentType<T>;
  formName: string;
  menu?: MenuType;
}
const FormActions = <T extends ModelType>({
  archiveOnClick,
  deleteOnClick,
  doc,
  formName,
  menu,
}: FormActionsProps<T>): ReactElement | null => {
  const dispatch = useDispatch();

  const pristine: boolean = useSelector(
    (globalState: ReducersProps<T>) =>
      !globalState.form[formName]?.submitting &&
      0 ===
        Object.keys(
          diff(
            globalState.form[formName]?.initial,
            globalState.form[formName]?.values,
          ),
        ).length,
  );

  const newMenu = {
    left: pristine && menu?.left ? [...menu.left] : [],
    right: pristine && menu?.right ? [...menu.right] : [],
  };

  const handleCancelOnClick = () => {
    dispatch(reset(formName));
  };

  const handleOnClick = () => {
    dispatch(submit(formName));
  };

  if (deleteOnClick) {
    newMenu.left.push({
      icon: IconDelete,
      iconColor: colors.danger['500'],
      label: 'Supprimer',
      onClick: deleteOnClick,
    });
  }

  if (archiveOnClick) {
    newMenu.left.push({
      icon: doc?.archived ? IconBoxArchivedSlash : IconArchive,
      label: doc?.archived ? 'Déarchiver' : 'Archiver',
      onClick: archiveOnClick,
    });
  }

  if (!pristine) {
    newMenu.right.push({
      disabled: pristine,
      label: 'Annuler',
      onClick: handleCancelOnClick,
    });
  }
  newMenu.right.push({
    disabled: pristine,
    label: doc?.id ? 'Enregistrer' : 'Créer',
    onClick: handleOnClick,
  });

  return (
    <div className="flex justify-between">
      <nav className="">
        {newMenu.left && newMenu.left.length > 0 && (
          <Actions items={newMenu.left} />
        )}
      </nav>

      <nav>
        {newMenu.right && newMenu.right.length > 0 && (
          <Actions className="flex space-x-3" items={newMenu.right} />
        )}
      </nav>
    </div>
  );
};

export default FormActions;
