import { DocumentType, ProfessionalType } from '@innedit/innedit-type';
import { ProfessionalData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { Field, FormSection } from 'redux-form';

import { Box, DataProps } from '../../../packages/formidable';

export interface DataProfessionalsMultiSelectProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  label?: string;
  name: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: keyof ProfessionalType;
}
const DataProfessionalsMultiSelect: FC<DataProfessionalsMultiSelectProps> = ({
  espaceId,
  className,
  label,
  name,
  orderDirection = 'asc',
  orderField = 'label',
}) => {
  const [options, setOptions] = useState<DocumentType<ProfessionalType>[]>();

  useEffect(() => {
    let isMounted = true;
    const professionalData = new ProfessionalData({
      espaceId,
      orderDirection,
      orderField,
    });
    const wheres: { [key: string]: any } = {
      hidden: false,
      stripeAccountHidden: false,
      stripeAccountVerified: true,
    };

    const unsub: () => void = professionalData.watch(
      docs => {
        if (isMounted) {
          setOptions(docs);
        }

        return isMounted;
      },
      {
        wheres,
      },
    );

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, orderDirection, orderField]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <Box>
      <FormSection name={name}>
        <div className={className}>
          <div className="label">
            <span>{label}</span>
          </div>

          {options.map(option => (
            <React.Fragment key={`${name}.${option.id}`}>
              <label
                className="flex flex-row space-x-2 items-center content-center"
                htmlFor={`${name}.${option.id}`}
              >
                <Field
                  component="input"
                  id={`${name}.${option.id}`}
                  name={option.id}
                  type="checkbox"
                />
                <span>{option.label}</span>
              </label>
            </React.Fragment>
          ))}
        </div>
      </FormSection>
    </Box>
  );
};

export default DataProfessionalsMultiSelect;
