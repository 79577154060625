import { ContentType, DocumentType } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import { ContentData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';
import { DataContentsCrudProps } from '~/datas/props';
import contentParams from '~/params/content.json';

import Item from './Item';

const DataContentsCrud: FC<DataContentsCrudProps> = ({
  display,
  espaceId,
  formName,
  name,
  params,
  parentCollectionName,
  parentId,
  title,
  ...props
}) => {
  const dispatch = useDispatch();
  const [contents, setContents] = useState<DocumentType<ContentType>[]>();

  const contentData = new ContentData({
    espaceId,
    parentCollectionName,
    parentId,
    params: contentParams,
    parentField: name,
  });

  useEffect(() => {
    let isMounted = true;
    let unsub: (() => void) | undefined;

    contentData.watch(newDocs => {
      if (isMounted) {
        setContents(newDocs);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [parentId]);

  const handleAddOnClick = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if ('create' === params?.type) {
      await dispatch(submit(formName));
      // // TODO corriger pour les pages qui sont associés à un canal
      // await navigate(
      //   `/espaces/${espaceId}/${parentCollectionName}/${parentId}/update/`,
      //   { replace: true },
      // );
    }

    const data = contentData.initialize({
      order: contents ? contents.length : 0,
    });
    contentData
      .create(data)
      .then(content =>
        navigate(
          `/espaces/${espaceId}/contents/${parentCollectionName}/${parentId}/${content.id}/update`,
          { replace: 'contents' === params?.collectionName },
        ),
      )
      .catch(console.error);
  };

  const handleEditOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const id = event.currentTarget.getAttribute('data-id');
    if (id) {
      await navigate(
        `/espaces/${espaceId}/contents/${parentCollectionName}/${parentId}/${id}/update`,
        { replace: 'contents' === params?.collectionName },
      );
    }
  };

  const handleUpOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (undefined !== i && contents && i > 0) {
      // On inverse le index et le index-1
      const contentMinus = contents[i - 1];
      contentData.update(contents[i].id, {
        order: i - 1,
      });
      contentData.update(contentMinus.id, {
        order: i,
      });
    }
  };

  const handleDownOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (contents && i < contents.length - 1) {
      // On inverse le index et le index-1
      const contentPlus = contents[i + 1];
      contentData.update(contents[i].id, {
        order: i + 1,
      });
      contentData.update(contentPlus.id, {
        order: i,
      });
    }
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (
      Number.isInteger(i) &&
      contents &&
      window.confirm('Confirmation de la suppression du contenu')
    ) {
      const document = contents[i];
      contentData.delete(document.id);
      if (params.docId === document.id) {
        // On a supprimé le document courant
        window.history.back();
      }
    }
  };

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      display={display}
      formName={formName}
      params={params}
      title={title}
      {...props}
    >
      {undefined === contents && <p>Chargement en cours</p>}

      {contents !== undefined && 0 < contents.length && (
        <>
          {contents.map((content, idx) => (
            <Item
              key={content.id}
              deleteOnClick={handleDeleteOnClick}
              document={content}
              downOnClick={handleDownOnClick}
              editOnClick={handleEditOnClick}
              index={idx}
              upOnClick={handleUpOnClick}
            />
          ))}
        </>
      )}
    </HOCGroup>
  );
};

export default DataContentsCrud;
