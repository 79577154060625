import Typesense from 'typesense';

export type {
  SearchResponse,
  SearchResponseHit,
} from 'typesense/lib/Typesense/Documents';

const client = new Typesense.Client({
  apiKey: String(process.env.GATSBY_TYPESENSE_API_KEY),
  connectionTimeoutSeconds: 2,
  nodes: [
    {
      host: String(process.env.GATSBY_TYPESENSE_HOST),
      port: 443,
      protocol: 'https',
    },
  ],
});

export default client;
