import { DataFieldProps, DataSection } from 'packages/formidable';
import { CollectionData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export interface DataSchemasFieldsAttributesProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  formName: string;
}

const DataSchemasFieldsAttributes: FC<DataSchemasFieldsAttributesProps> = ({
  espaceId,
  formName,
  name,
  params,
}) => {
  const [fields, setFields] = useState<any[]>();
  const item = params && params[formName];

  useEffect(() => {
    let isMounted = true;
    if (item.collectionId) {
      if (item.collectionId.startsWith('collections/')) {
        const collectionData = new CollectionData({ espaceId });
        collectionData
          .findById(item.collectionId.slice(12))
          .then(snapshot => {
            if (isMounted) {
              setFields(snapshot.fields);
            }

            return isMounted;
          })
          .catch(toast.error);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [item.collectionId]);

  if (!fields || 0 === fields.length) {
    return null;
  }

  return (
    <DataSection
      datas={fields.map(field => ({
        componentType: 'attributes',
        display: 'inside',
        name: field.name,
        title: field.label,
      }))}
      formName={formName}
      name={name}
      params={params}
    />
  );
};

export default DataSchemasFieldsAttributes;
