import { DocumentType, PaymentType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import objectHash from 'object-hash';
import { GenericData, OrderData } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';

interface ItemTransferRequiresActionProps {
  payment: DocumentType<PaymentType>;
}

const ItemTransferRequiresAction: FC<ItemTransferRequiresActionProps> =
  function ({ payment }) {
    const handleOnClick = async (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const genericData = new GenericData<PaymentType>({
        collectionName: 'payments',
      });
      await genericData.update(payment.id, {
        amountReceived: payment.amount,
        status: 'succeeded',
      });
      if (payment.orderId) {
        const orderData = new OrderData({
          espaceId: payment.espaceId,
        });
        const today = dayjs();
        await orderData.update(payment.orderId, {
          paidAt: today.toISOString(),
          status: 'paid',
          updatedAt: today.toISOString(),
        });
      }
    };

    const handleCancelOnSubmit = async (
      event: SyntheticEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();

      const genericData = new GenericData<PaymentType>({
        collectionName: 'payments',
      });
      await genericData.update(payment.id, {
        status: 'canceled',
      });
    };

    return (
      <>
        <div className="mt-6 flex flex-col space-y-3">
          {payment.items?.map((item, index) => {
            const solde =
              item.quantity * item.price - (item.refundedAmount || 0);

            return (
              <div
                key={objectHash({ index, item })}
                className={classnames('flex flex-col', {
                  'line-through': Boolean(item.canceledAt),
                })}
              >
                <div className="flex items-center space-x-3">
                  {item.thumbnail && (
                    <img alt={item.label} src={item.thumbnail} width={30} />
                  )}
                  <span className="overflow-hidden truncate text-ellipsis">
                    {item.label}
                  </span>
                  {undefined !== item.refundedAmount &&
                    item.refundedAmount > 0 &&
                    0 === solde && <span>Remboursé</span>}
                  {undefined !== item.refundedAmount &&
                    item.refundedAmount > 0 &&
                    solde > 0 && (
                      <span className="whitespace-nowrap">{solde} €</span>
                    )}
                  {solde > 0 && (
                    <span
                      className={classnames('whitespace-nowrap', {
                        'line-through':
                          undefined !== item.refundedAmount &&
                          item.refundedAmount > 0,
                      })}
                    >
                      {item.quantity > 1
                        ? `${item.quantity} x ${item.price} € = ${
                            item.quantity * item.price
                          } €`
                        : `${item.quantity * item.price} €`}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-6 flex justify-center space-x-3">
          <Button
            color="danger"
            onClick={handleCancelOnSubmit}
            size="sm"
            variant="outline"
          >
            Annuler le paiement
          </Button>
          <Button
            color="neutral"
            onClick={handleOnClick}
            size="sm"
            variant="outline"
          >
            Valider le paiement
          </Button>
        </div>
      </>
    );
  };

export default ItemTransferRequiresAction;
