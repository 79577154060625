import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconEdit: FC<IconType> = ({ color }) => (
  <svg height="100%" viewBox="0 0 16 16" width="100%">
    <path
      d="M14.9985 14.8005C15.5507 14.8005 15.9989 15.069 15.9989 15.4002C15.9989 15.7315 15.5507 16 14.9985 16H1.00033C0.448202 16 0 15.7315 0 15.4002C0 15.069 0.447119 14.7994 0.999252 14.7994L14.9985 14.8005ZM9.74785 0.413612C10.4277 -0.160173 11.4281 -0.134191 12.0755 0.474238L14.4735 2.87223C15.1447 3.51097 15.1717 4.57085 14.5352 5.24315L6.66024 13.1181C6.37551 13.3996 6.00093 13.576 5.60144 13.6161L1.95303 13.9484H1.874C1.64124 13.9506 1.41822 13.8586 1.25258 13.6951C1.06854 13.5111 0.975435 13.2545 0.999252 12.9947L1.376 9.34626C1.41498 8.94677 1.59144 8.57327 1.874 8.28746L9.74785 0.413612ZM7.99943 4.60333L3.07354 9.49566L2.83645 12.1113L5.46179 11.8666L10.3617 6.96667L7.99943 4.60441V4.60333ZM10.8597 1.69976L9.15458 3.45034L11.4995 5.79528L13.2501 4.08908L10.8608 1.70084L10.8597 1.69976Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconEdit;
