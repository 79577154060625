import { Theme, ThemeProvider } from '@emotion/react';
import { DataType } from '@innedit/innedit-type';
import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import createStore from './createStore';

interface FormidableProviderProps {
  children?: ReactNode;
  extendData?: (props: DataType) => ReactElement | null;
  extraArguments?: any;
  extraReducers?: any;
  initializeStore?: (store: Store) => void;
  initialState?: any;
  sc?: { [key: string]: any };
  theme?: Theme;
}

interface FormidableStateProps {
  extendData?: (props: DataType) => ReactElement | null;
  sc?: { [key: string]: any };
  store?: Store;
}

const defaultState: FormidableStateProps = {
  extendData: undefined,
  sc: undefined,
  store: undefined,
};

const FormidableContext = React.createContext(defaultState);

const FormidableProvider: FC<FormidableProviderProps> = ({
  children,
  extendData,
  extraArguments,
  extraReducers,
  initializeStore,
  initialState = {},
  sc,
  theme,
}) => {
  const [store] = useState<Store>(
    createStore(initialState, extraReducers, extraArguments),
  );

  if (initializeStore) {
    initializeStore(store);
  }

  return (
    <FormidableContext.Provider
      value={{
        extendData,
        sc,
        store,
      }}
    >
      <Provider store={store}>
        {theme ? (
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        ) : (
          children
        )}
      </Provider>
    </FormidableContext.Provider>
  );
};

export default FormidableContext;
export { FormidableProvider };
