import styled from '@emotion/styled';
import objectHash from 'object-hash';
import {
  FormActionProps,
  FormDivActionProps,
  FormProps,
} from 'packages/formidable/components/props';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';
import { DecoratedFormProps, InjectedFormProps, reduxForm } from 'redux-form';

import Button from '~/components/Button';
import { FormMessage } from '~/components/Field';

const FormSC = styled.form``;
const FormBodySC = styled.div``;
const FormFooterSC = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface FormRenderProps extends FormProps {
  children?: ReactNode;
  errorValues?: any;
  formValues?: any;
}

const Actions: FC<{
  id?: string;
  values?: FormActionProps | FormActionProps[] | FormDivActionProps[];
}> = ({ id, values }) => {
  if (!values) {
    return null;
  }

  const tmp: (FormActionProps | FormDivActionProps)[] = Array.isArray(values)
    ? values
    : [values];

  return (
    <>
      {tmp.map((a: FormActionProps | FormDivActionProps) => {
        if ((a as FormDivActionProps).actions) {
          const div = a as FormDivActionProps;

          return (
            <div
              key={`form_actions_div_${objectHash(div.actions)}`}
              className={div.className}
            >
              {div.actions.map(({ label, ...actionProps }, i) => (
                <Button
                  key={`form_actions_${objectHash({ id, label, index: i })}`}
                  {...actionProps}
                >
                  {label}
                </Button>
              ))}
            </div>
          );
        }

        return ((Array.isArray(a) ? a : [a]) as FormActionProps[]).map(
          ({ label, ...actionProps }, i) => (
            <Button
              key={`form_actions_${objectHash({ id, label, index: i })}`}
              {...actionProps}
            >
              {label}
            </Button>
          ),
        );
      })}
    </>
  );
};

const Form: React.FC<
  FormRenderProps & InjectedFormProps<any, FormRenderProps>
> = props => {
  const {
    actions,
    bodyProps,
    cancelProps,
    children,
    className,
    error,
    // errorValues,
    footerProps,
    // formValues,
    handleSubmit,
    hideSubmitButton = false,
    id,
    isSubmissive = true,
    // invalid,
    name,
    pristine,
    removePristine = false,
    submitProps,
    submitting,
    // valid,
  } = props;
  const { t } = useTranslation();

  return (
    <FormSC
      className={className}
      id={id}
      name={`${name}-form`}
      onSubmit={handleSubmit}
    >
      <FormBodySC {...bodyProps}>{children}</FormBodySC>
      <FormFooterSC {...footerProps}>
        {error && (
          <FormMessage color="error">{t ? t(error) : error}</FormMessage>
        )}
        <Actions id={id} values={actions} />
        {cancelProps?.onClick && (
          <Button {...cancelProps}>
            {t
              ? t(cancelProps?.label || 'cancel')
              : cancelProps?.label || 'cancel'}
          </Button>
        )}
        {!hideSubmitButton && (
          <Button
            {...submitProps}
            disabled={
              // !isSubmissive || invalid || pristine || submitting || !valid
              !isSubmissive || (!removePristine && pristine) || submitting
            }
            type="submit"
          >
            {(submitProps?.label ||
              (!submitProps?.iconLeft && !submitProps?.iconRight)) &&
              (t
                ? t(submitProps?.label || 'submit')
                : submitProps?.label || 'submit')}
          </Button>
        )}
      </FormFooterSC>
    </FormSC>
  );
};

type StateProps = {
  asyncChangeFields?: string[];
  asyncValidate?: (
    values: FormData,
    dispatch: Dispatch<any>,
    props: DecoratedFormProps<FormData, any>,
    blurredField: string,
  ) => Promise<any>;
  destroyOnUnmount: boolean;
  enableReinitialize: boolean;
  forceUnregisterOnUnmount: boolean;
  form: string;
  keepDirtyOnReinitialize?: boolean;
  touchOnChange?: boolean;
  updateUnregisteredFields?: boolean;
  validate?: (values: any, props: any) => any;
};

const ReduxForm = reduxForm<any, FormRenderProps>({})(Form);

const mapStateToProps = (
  state: DefaultRootState,
  props: FormRenderProps,
): StateProps => ({
  asyncChangeFields: props.asyncChangeFields,
  asyncValidate: props.asyncValidate,
  destroyOnUnmount:
    undefined !== props.destroyOnUnmount ? props.destroyOnUnmount : true,
  enableReinitialize: !!props.enableReinitialize,
  forceUnregisterOnUnmount: !!props.forceUnregisterOnUnmount,
  form: props.name,
  keepDirtyOnReinitialize: !!props.keepDirtyOnReinitialize,
  touchOnChange:
    undefined !== props.touchOnChange ? props.touchOnChange : false,
  updateUnregisteredFields:
    undefined !== props.updateUnregisteredFields
      ? props.updateUnregisteredFields
      : false,
  validate: props.validate,
});

export default connect(mapStateToProps)(ReduxForm);
