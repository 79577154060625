import React, { FC, SyntheticEvent } from 'react';

import Button from '../../components/Button';
import ButtonGroup from '../../components/Button/Group';
import IconDelete from '../../icons/Delete';
import { DataProps, DataSection } from '../../packages/formidable';
import { spacing } from '../../styles/theme';
import { ItemEL } from '../Contents/Crud/Item';

export interface DataOptionProps {
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}
const DataOption: FC<DataOptionProps> = ({ deleteOnClick, index, name }) => (
  <ItemEL className="flex flex-col min-h-12 border p-1 rounded bg-light-300">
    <DataSection
      className="flex-1"
      datas={[
        {
          cols: ['none', 5],
          componentType: 'grid',
          datas: [
            {
              componentType: 'input',
              labelShow: false,
              name: 'label',
              placeholder: 'Libellé',
              wrapperProps: {
                col: ['full', 3],
              },
            },
            {
              componentType: 'input',
              labelShow: false,
              name: 'value',
              placeholder: 'Valeur',
              type: 'text',
              wrapperProps: {
                col: ['full', 2],
              },
            },
          ],
          gap: spacing[1],
        } as DataProps,
      ]}
      name={name}
    />
    <hr className="mt-2" />
    <div className="mt-3 flex justify-between text-neutral-700">
      <div className="flex space-x-3 text-xs" />
      <div className="actions justify-center items-center">
        <ButtonGroup>
          {deleteOnClick && (
            <Button
              color="neutral"
              datas={{
                'data-index': index,
              }}
              iconLeft={IconDelete}
              onClick={deleteOnClick}
              size="xs"
              variant="outline"
            />
          )}
        </ButtonGroup>
      </div>
    </div>
  </ItemEL>
);

export default DataOption;
