import { DataType, DocumentType, MediaType } from '@innedit/innedit-type';
import { Link } from 'gatsby';
import objectHash from 'object-hash';
import { Data, DataProps } from 'packages/formidable';
import { MediaData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import Media from '~/components/Media';

export interface DataMediaParentProps extends Omit<DataProps, 'componentType'> {
  datas?: DataType[];
  espaceId: string;
  formName: string;
}

const DataMediaParent: FC<DataMediaParentProps> = ({
  datas,
  espaceId,
  formName,
  params,
  label,
  ...props
}) => {
  // const [document, setDocument] = useState<DocumentType<any>>();
  const [medias, setMedias] = useState<DocumentType<MediaType>[]>();

  const { parentCollectionName, parentId } = params[formName] ?? {};
  useEffect(() => {
    let isMounted = true;

    if (parentCollectionName && parentId) {
      const mediaData = new MediaData({
        espaceId,
        parentCollectionName,
        parentId,
      });

      mediaData
        .find()
        .then(docs => {
          if (isMounted) {
            setMedias(docs);
          }

          return isMounted;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [parentCollectionName, parentId]);

  return (
    <HOCGroup
      bodyProps={{
        className: 'bg-light-500',
      }}
      customInfos={
        parentCollectionName && parentId ? (
          <div className="col-span-3">
            <Button
              to={`/espaces/${espaceId}/${parentCollectionName}/${parentId}/update/`}
              variant="link"
            >
              Voir le document
            </Button>
          </div>
        ) : undefined
      }
      label={label ?? 'Associés'}
    >
      <div className="grid grid-cols-3 gap-1.5">
        {/* {page.medias && page.medias.length > 0 && ( */}
        {/*  <div> */}
        {/*    <Media */}
        {/*      className="rounded" */}
        {/*      media={page.medias[0]} */}
        {/*      options="w=65&h=65" */}
        {/*    /> */}
        {/*  </div> */}
        {/* )} */}

        {medias &&
          medias.map(media => (
            <Link
              key={media.id}
              to={`/espaces/${espaceId}/medias/${media.id}/update`}
            >
              <Media
                media={{
                  ...media,
                  origin: String(process.env.GATSBY_IMAGES_DOMAIN_NAME),
                  pathname: media.fullPath,
                }}
                options={{
                  crop: 'contain',
                  height: 100,
                  width: 100,
                }}
                thumbnail
              />
            </Link>
          ))}
      </div>
      {datas &&
        datas.length > 0 &&
        datas.map(data => (
          <Data
            key={objectHash(data)}
            formName={formName}
            params={params}
            {...props}
            {...data}
          />
        ))}
    </HOCGroup>
  );
};

export default DataMediaParent;
