import {
  DocumentType,
  ProduitType,
  SubscriptionProductType,
} from '@innedit/innedit-type';
import { DataSection } from 'packages/formidable';
import { PriceData, ProduitData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import getLabel from '~/components/Price/getLabel';
import IconDelete from '~/icons/Delete';

export interface DataSubscriptionProductsItemProps {
  doc: SubscriptionProductType;
  espaceId: string;
  formName: string;
  index: number;
  name: string;
  params?: { [key: string]: any };
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

const DataSubscriptionProductsItem: FC<DataSubscriptionProductsItemProps> =
  function ({ doc, espaceId, formName, index, name, params, removeOnClick }) {
    const [product, setProduct] = useState<DocumentType<ProduitType>>();
    const [options, setOptions] =
      useState<{ label: string; value: string }[]>();
    const { t } = useTranslation();

    useEffect(() => {
      let isMounted = true;
      const produitData = new ProduitData({ espaceId });
      produitData
        .findById(doc.id)
        .then(snapshot => {
          if (isMounted) {
            setProduct(snapshot);
            const priceData = new PriceData({
              espaceId,
              parentCollectionName: 'produits',
              parentId: doc.id,
            });

            priceData
              .find()
              .then(docs => {
                if (isMounted) {
                  setOptions(
                    docs.map(d => ({
                      label: `${getLabel(d, t)} - ${t(
                        `prices.types.${d.type}`,
                      )}`,
                      value: d.id,
                    })),
                  );
                }

                return isMounted;
              })
              .catch(error => toast.error(error.message));
          }

          return isMounted;
        })
        .catch(error => toast.error(error.message));

      return () => {
        isMounted = false;
      };
    }, [doc.id]);

    return (
      <div className="grid grid-cols-5 items-center justify-center">
        <span className="col-span-2">{product?.label}</span>
        <div className="col-span-3 flex">
          <DataSection
            datas={[
              {
                cols: 3,
                componentType: 'grid',
                datas: [
                  {
                    options,
                    componentType: 'select',
                    labelShow: false,
                    name: 'priceId',
                    wrapperProps: {
                      col: 2,
                    },
                  },
                  {
                    componentType: 'input',
                    labelShow: false,
                    name: 'quantity',
                    type: 'number',
                  },
                ],
                gap: '0.5rem',
              },
            ]}
            formName={formName}
            name={name}
            params={params}
          />

          <Button
            color="danger"
            data-index={index}
            iconLeft={IconDelete}
            onClick={removeOnClick}
            size="sm"
            square
            variant="ghost"
          />
        </div>
      </div>
    );
  };

export default DataSubscriptionProductsItem;
