import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataMenuProps } from '~/datas/props';

import DataMenuRender from './Render';

const DataMenu: FC<DataMenuProps> = ({ name, ...others }) => (
  <FieldArray component={DataMenuRender} name={name} props={others} />
);

export default DataMenu;
