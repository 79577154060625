import type { DeliveryType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Delivery extends ModelEspace<DeliveryType> {
  constructor(props: Omit<ModelEspaceProps<DeliveryType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'livraisons',
      orderDirection: props.orderDirection || 'desc',
      orderField: props.orderField || 'label',
    });
  }
}

export default Delivery;
