import '~/styles/base.css';
import 'dayjs/locale/fr';

import { navigate } from 'gatsby';
import { FormidableProvider } from 'packages/formidable';
import { UserData } from 'packages/innedit';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Translation as TranslationI18N } from 'react-i18next';

import { isMobile, isWebapp } from '~/actions/actions';
import loadEspace from '~/actions/espace/load';
import { isLogout, load } from '~/actions/user';
import Button from '~/components/Button';
import { FieldMessage, FieldTemplate } from '~/components/Field';
import { TabsBar, TabsBarItem } from '~/components/Tabs';
import Translation, {
  TranslationItem,
  TranslationItemLang,
} from '~/components/Translation';
import extendData from '~/datas';
import i18n from '~/i18n';
import reducers from '~/reducers';
import theme from '~/styles/theme';
import { mobilecheck } from '~/utils/functions';

let isMobileScreen = false;
let isWebappScreen = false;
if (typeof document !== `undefined` && typeof window !== `undefined`) {
  isMobileScreen = mobilecheck();
  const viewport = document.querySelector('meta[name=viewport]');
  if (viewport) {
    viewport.setAttribute(
      'content',
      'width=device-width, height=device-height, initial-scale=1, initial-scale=1',
    );
  }

  if (isMobileScreen) {
    document.documentElement.classList.remove('desktop');
    document.documentElement.classList.add('mobile');
  } else {
    document.documentElement.classList.add('desktop');
    document.documentElement.classList.remove('mobile');
  }

  isWebappScreen = !!window.navigator.standalone;
  if (isWebappScreen) {
    document.documentElement.classList.add('webapp');
  } else {
    document.documentElement.classList.remove('webapp');
  }
}

let defaultStore;
const initializeStore = store => {
  defaultStore = store;
  store.dispatch(isMobile(isMobileScreen));
  store.dispatch(isWebapp(isWebappScreen));

  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    const [p1, espaceId] = window.location.pathname.substring(1).split('/');
    UserData.onChange(user => {
      if (user) {
        store.dispatch(
          load(
            user,
            'espaces' === p1 && espaceId && espaceId !== 'create'
              ? espaceId
              : undefined,
          ),
        );
      } else {
        store.dispatch(isLogout());
        navigate('/');
      }
    });
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const [template, espaceId] = location.pathname.slice(1).split('/');
  if ('espaces' === template) {
    // C'est une page espace
    let prevEspaceId;
    let prevTemplate;
    if (prevLocation) {
      [prevTemplate, prevEspaceId] = prevLocation.pathname.slice(1).split('/');
    }

    if (
      defaultStore &&
      espaceId &&
      espaceId !== 'create' &&
      (!prevTemplate || 'espaces' !== prevTemplate || espaceId !== prevEspaceId)
    ) {
      // la page d'avant n'était pas de cet espace
      defaultStore.dispatch(loadEspace(espaceId));
    }
  }
};

const WrapRootElement = ({ element }) => (
  <DndProvider backend={isMobileScreen ? TouchBackend : HTML5Backend}>
    <TranslationI18N i18n={i18n}>
      {t => (
        <FormidableProvider
          extendData={extendData}
          extraReducers={reducers}
          initializeStore={initializeStore}
          sc={{
            button: Button,
            fieldMessage: FieldMessage,
            fieldTemplate: FieldTemplate,
            tabsBar: TabsBar,
            tabsBarItem: TabsBarItem,
            translation: Translation,
            translationItem: TranslationItem,
            translationItemLang: TranslationItemLang,
          }}
          theme={{
            ...theme,
            mobile: isMobileScreen,
            webapp: isWebappScreen,
          }}
        >
          {element}
        </FormidableProvider>
      )}
    </TranslationI18N>
  </DndProvider>
);
export const wrapRootElement = WrapRootElement;
