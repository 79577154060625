import React, { FC, memo, SyntheticEvent } from 'react';

import ViewModal from '../../../components/View/Modal';
import { DataProps, DataSection } from '../../../packages/formidable';
import documentsSelect from './datas/documentsSelect.json';
import input from './datas/input.json';
import select from './datas/select.json';
import textarea from './datas/textarea.json';

interface ModalProps {
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  name: string;
  params?: { [key: string]: any };
  title: string;
}

const Modal: FC<ModalProps> = ({
  closeOnClick,
  formName,
  name,
  params,
  title,
}) => (
  <ViewModal
    buttons={{
      right: [{ label: 'Fermer', onClick: closeOnClick }],
    }}
    closeOnClick={closeOnClick}
    title={title}
  >
    <DataSection
      datas={
        [
          {
            componentType: 'flex',
            datas: [
              {
                componentType: 'input',
                description: 'Obligatoire',
                fieldProps: {
                  className: 'reverse',
                },
                label: 'Obligatoire',
                labelShow: false,
                name: 'required',
                templateProps: {
                  className: 'flex-row-reverse text-light-800',
                },
                type: 'checkbox',
              },
              {
                componentType: 'input',
                description: 'Désactivé',
                fieldProps: {
                  className: 'reverse',
                },
                label: 'Désactivé',
                labelShow: false,
                name: 'disabled',
                templateProps: {
                  className: 'flex-row-reverse text-light-800',
                },
                type: 'checkbox',
              },
            ],
            direction: 'row',
          },
          {
            cols: ['none', 2],
            componentType: 'grid',
            datas: [
              {
                componentType: 'schemas/datas-label',
                label: 'Libellé',
                name: 'label',
                required: true,
              },
              {
                componentType: 'select',
                label: 'Type',
                name: 'componentType',
                options: [
                  { label: 'array - Liste de datas', value: 'array' },
                  { label: 'input - Champs interactif', value: 'input' },
                  { label: 'object - Objet JSON', value: 'object' },
                  {
                    label: 'medias - Ensemble de médias',
                    value: 'medias',
                  },
                  {
                    label: 'contents - Ensemble de contenus',
                    value: 'contents/crud',
                  },
                  { label: "select - Liste d'options", value: 'select' },
                  {
                    label: "select - Liste des items d'une collection",
                    value: 'documents/select',
                  },
                  {
                    label: 'textarea - Champs texte sur multiligne',
                    value: 'textarea',
                  },
                ],
              },
            ],
          },
          {
            componentType: 'input',
            label: 'Attribut',
            name: 'name',
            placeholder: "Nom de l'attribut",
          },
          {
            componentType: 'condition',
            datas: input as DataProps[],
            test: {
              field: `${name}.componentType`,
              operator: '==',
              value: 'input',
            },
          },
          {
            componentType: 'condition',
            datas: documentsSelect as DataProps[],
            test: {
              field: `${name}.componentType`,
              operator: '==',
              value: 'documents/select',
            },
          },
          {
            componentType: 'condition',
            datas: select as DataProps[],
            test: {
              field: `${name}.componentType`,
              operator: '==',
              value: 'select',
            },
          },
          {
            componentType: 'condition',
            datas: textarea as DataProps[],
            test: {
              field: `${name}.componentType`,
              operator: '==',
              value: 'textarea',
            },
          },
        ] as any[]
      }
      formName={formName}
      name={name}
      params={{
        ...params,
        name,
      }}
    />
  </ViewModal>
);

const propsAreEqual = (prevProps: ModalProps, nextProps: ModalProps): boolean =>
  prevProps.formName === nextProps.formName &&
  prevProps.name === nextProps.name;

export default memo(Modal, propsAreEqual);
