import { DocumentType, ModelType } from '@innedit/innedit-type';
import { diff } from 'deep-object-diff';
import { navigate } from 'gatsby';
import { Form } from 'packages/formidable';
import { ModelData } from 'packages/innedit';
import React, {
  KeyboardEvent,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { change, submit } from 'redux-form';

import { MenuType } from '~/components/Actions';
import { ActionProps } from '~/components/Actions/props';
import Button from '~/components/Button';
import ViewModal from '~/components/View/Modal';
import FormActions from '~/containers/Espace/Form/Actions';

// import FooterActions from './FooterActions';
import HeaderActions from './HeaderActions';

const useDocId = (docId?: string) => {
  const ref = useRef<string>();

  useEffect(() => {
    ref.current = docId;
  });

  return ref.current;
};

export interface FormProps<T extends ModelType> {
  closeOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  collectionName?: string;
  createOnClick?: (document: DocumentType<T>) => void;
  deleteOnClick?: () => void;
  deleteError?: string;
  displayArchive?: boolean;
  displayClose?: boolean;
  displayComments?: boolean;
  displayDescription?: boolean;
  displayDuplicate?: boolean;
  displayFeatured?: boolean;
  displayHeader?: boolean;
  displayHidden?: boolean;
  displayRemove?: boolean;
  displayView?: boolean;
  docId: string;
  forceUnregisterOnUnmount?: boolean;
  formName?: string;
  footerProps?: { [key: string]: any };
  hideSubmitButton?: boolean;
  id?: string;
  initializeData?: Partial<T>;
  keepDirtyOnReinitialize?: boolean;
  menu?: MenuType;
  model: ModelData<T>;
  onSubmit?: (values: Partial<T>) => void;
  params?: { [key: string]: any };
  parentCollectionName?: string;
  parentId?: string;
  subMenu?: (ActionProps | ActionProps[])[];
  submitProps?: { [key: string]: any };
  title?: string;
  type: 'create' | 'update';
}

const CMSForm = <T extends ModelType>({
  children,
  closeOnClick,
  createOnClick,
  deleteOnClick,
  deleteError,
  displayArchive = true,
  displayClose = true,
  displayComments = true,
  displayDescription = false,
  displayDuplicate = false,
  displayFeatured = false,
  displayHeader = true,
  displayHidden = false,
  displayRemove = true,
  displayView,
  docId,
  forceUnregisterOnUnmount,
  formName,
  footerProps,
  hideSubmitButton = true,
  id,
  initializeData,
  keepDirtyOnReinitialize,
  menu,
  model,
  onSubmit,
  params,
  parentCollectionName,
  parentId,
  subMenu,
  submitProps,
  title,
  type,
}: PropsWithChildren<FormProps<T>>): ReactElement => {
  const name =
    formName ??
    model.collectionName.substring(0, model.collectionName.length - 1);
  const [openDelete, setOpenDelete] = useState<boolean>();
  const [initialValues, setInitialValues] = useState<Partial<T>>();
  const [doc, setDoc] = useState<DocumentType<T>>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const previousDocId = useDocId(docId);

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if ('s' === e.key && e.metaKey) {
      e.preventDefault();
      // Enregistrement
      dispatch(submit(name));
    }
  };

  useEffect(() => {
    let unsub: () => void;

    if (docId) {
      if (previousDocId !== docId) {
        setInitialValues(model.initialize(initializeData));
      }
      unsub = model.watchById(docId, snapshot => {
        setDoc(snapshot);
      });
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId]);

  useEffect(() => {
    if ('create' === type) {
      setInitialValues(model.initialize(initializeData));
    } else if (doc) {
      // le document a été mis à jour
      if (initialValues && Object.keys(initialValues).length > 0) {
        const newDiffValues: Partial<T> = diff(
          initialValues,
          model.extractAttributes(doc, initialValues),
        );
        // On change uniquement les valeurs qui ont été mises à jour
        if (newDiffValues && Object.keys(newDiffValues).length > 0) {
          Object.keys(newDiffValues).forEach(key => {
            dispatch(change(name, key, doc[key as keyof T], false));
          });
          // il faut réinitialiser le formulaire
          setInitialValues(model.extractAttributes(doc));
        }
      } else {
        setInitialValues(model.extractAttributes(doc));
      }
    }
  }, [doc, type]);

  const handleToggleArchiveDoc = async () => {
    if (doc && doc.id) {
      await toast.promise(model.archive(doc.id, !doc.archived), {
        error: "Problème lors de la mise à jour de l'archivage du document",
        pending: doc.archived ? 'Déarchivage en cours' : 'Archivage en cours',
        success: doc.archived ? 'Déarchivage réussi' : 'Archivage réussi',
      });
    } else {
      await toast(`${t('archiveError')} : no item id`);
    }
  };

  const handleDeleteDoc = async () => {
    if (doc && doc.id) {
      await toast.promise(model.delete(doc.id), {
        error: 'Problème lors de la suppression',
        pending: 'Suppression en cours',
        success: 'Suppression réussie',
      });
    } else {
      await toast(`${t(deleteError || 'deleteError')} : no item id`);
    }
  };

  const handleCloseOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    if (closeOnClick) {
      closeOnClick(event);
    } else {
      await navigate(-1);
    }
  };

  const handleToggleArchiveOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.preventDefault();

    await handleToggleArchiveDoc();
  };

  const handleDeleteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.preventDefault();

    setOpenDelete(true);
  };

  const handleDeleteConfirmOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    await handleDeleteDoc();
    setOpenDelete(false);

    if (deleteOnClick) {
      deleteOnClick();
    } else {
      navigate(-1);
    }
  };

  const handleOnSubmit = async (values: Partial<T>): Promise<void> => {
    // document.documentEleme nt.scrollTo(0, 0);
    let data;

    if (!doc || !doc.id) {
      // Le document n'existe pas. Il faut le créer

      data = await toast.promise(model.create(values, docId), {
        error: 'Problème lors de la création',
        pending: 'Création en cours',
        success: 'Création réussie',
      });

      if (data && createOnClick) {
        await createOnClick(data);
      }
    } else {
      await toast.promise(model.set(doc.id, values), {
        error: "Problème lors de l'enregistrement",
        pending: 'Enregistrement en cours',
        success: 'Enregistrement réussi',
      });
    }

    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <div onKeyDown={handleOnKeyDown} role="presentation">
      {openDelete && (
        <ViewModal
          closeOnClick={handleCloseDelete}
          maxWidth="500px"
          title="Suppression"
        >
          <p>Est-ce que vous souhaitez vraiment supprimer ce document ?</p>
          <div className="mt-3 flex items-center justify-center space-x-3">
            <Button
              color="neutral"
              onClick={handleCloseDelete}
              variant="outline"
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={handleDeleteConfirmOnClick}
              variant="solid"
            >
              Oui
            </Button>
          </div>
        </ViewModal>
      )}
      {displayHeader && (
        <HeaderActions
          archiveOnClick={
            displayArchive ? handleToggleArchiveOnClick : undefined
          }
          closeOnClick={handleCloseOnClick}
          deleteOnClick={
            displayRemove && (!displayArchive || doc?.archived)
              ? handleDeleteOnClick
              : undefined
          }
          displayClose={displayClose}
          displayComments={displayComments}
          displayDescription={displayDescription}
          displayDuplicate={displayDuplicate}
          displayFeatured={displayFeatured}
          displayHidden={displayHidden}
          displayView={displayView}
          doc={doc}
          formName={name}
          menu={menu}
          model={model}
          subMenu={subMenu}
          title={title}
        />
      )}

      <Form
        className={displayHeader ? 'mt-6' : ''}
        datas={model.getDatas()}
        destroyOnUnmount={false}
        enableReinitialize
        footerProps={footerProps ?? { className: 'mt-6' }}
        forceUnregisterOnUnmount={forceUnregisterOnUnmount}
        hideSubmitButton={hideSubmitButton}
        id={id || `view-form__${name}`}
        initialValues={initialValues || model.initialize()}
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        name={name}
        onSubmit={handleOnSubmit}
        params={{
          ...params,
          docId,
          parentCollectionName,
          parentId,
          type,
          collectionName: model.collectionName,
          espaceId: model.espaceId,
          [name]: doc,
        }}
        submitProps={submitProps}
      />
      {children}

      {!displayHeader && (
        <FormActions
          archiveOnClick={
            displayArchive ? handleToggleArchiveOnClick : undefined
          }
          deleteOnClick={
            displayRemove && (!displayArchive || doc?.archived)
              ? handleDeleteOnClick
              : undefined
          }
          doc={doc}
          formName={name}
          menu={menu}
        />
      )}
    </div>
  );
};

export default CMSForm;
