import React, { FC, SyntheticEvent, useState } from 'react';

import IconArrowDown from '~/icons/ArrowDown';
import IconArrowUp from '~/icons/ArrowUp';

import Button from '../../../components/Button';
import ButtonGroup from '../../../components/Button/Group';
import IconDelete from '../../../icons/Delete';
import IconEdit from '../../../icons/Edit';
import { DataProps, DataSection } from '../../../packages/formidable';
import { spacing } from '../../../styles/theme';
import { ItemEL } from '../../Contents/Crud/Item';
import Modal from './Modal';

export interface DataSchemasFieldsItemProps {
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  downOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  index: number;
  name: string;
  params?: {
    [key: string]: any;
  };
  upOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
}
const DataSchemasFieldsItem: FC<DataSchemasFieldsItemProps> = ({
  deleteOnClick,
  downOnClick,
  formName,
  index,
  name,
  params,
  upOnClick,
}) => {
  const [openModal, setOpenModal] = useState<boolean>();

  const parentName = name.slice(0, name.indexOf('['));
  const item =
    params &&
    params[formName] &&
    params[formName][parentName] &&
    params[formName][parentName][index];

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEditOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenModal(true);
  };

  return (
    <>
      {openModal && (
        <Modal
          closeOnClick={handleCloseModal}
          formName={formName}
          name={name}
          params={params}
          title={name}
        />
      )}
      <ItemEL className="flex flex-col min-h-12 border p-1 rounded bg-light-300">
        <DataSection
          className="flex-1"
          datas={[
            {
              cols: ['none', 2],
              componentType: 'grid',
              datas: [
                {
                  componentType: 'schemas/datas-label',
                  labelShow: false,
                  name: 'label',
                  placeholder: 'Libellé',
                  required: true,
                },
                {
                  componentType: 'select',
                  labelShow: false,
                  name: 'componentType',
                  options: [
                    { label: 'array - Liste de datas', value: 'array' },
                    { label: 'input - Champs interactif', value: 'input' },
                    { label: 'object - Objet JSON', value: 'object' },
                    {
                      label: 'medias - Ensemble de médias',
                      value: 'medias',
                    },
                    {
                      label: 'contents - Ensemble de contenus',
                      value: 'contents/crud',
                    },
                    { label: "select - Liste d'options", value: 'select' },
                    {
                      label: "select - Liste des items d'une collection",
                      value: 'documents/select',
                    },
                    {
                      label: 'textarea - Champs texte sur multiligne',
                      value: 'textarea',
                    },
                  ],
                  required: true,
                },
              ],
              gap: spacing[1],
            } as DataProps,
          ]}
          formName={formName}
          name={name}
        />
        <hr className="mt-1" />
        <div className="mt-1 flex justify-between items-center text-neutral-700">
          <div className="flex space-x-3 text-xs ml-2">
            {item?.name && <span>{item.name}</span>}
            {item?.collectionId && <span>{item.collectionId}</span>}
            {item?.required && (
              <span className="text-danger-400">obligatoire</span>
            )}
          </div>
          <div className="actions justify-center items-center">
            <ButtonGroup>
              <Button
                color="neutral"
                data-index={index}
                iconLeft={IconArrowUp}
                onClick={upOnClick}
                size="xs"
                variant="outline"
              />
              <Button
                color="neutral"
                data-index={index}
                iconLeft={IconArrowDown}
                onClick={downOnClick}
                size="xs"
                variant="outline"
              />
              <Button
                color="neutral"
                iconLeft={IconEdit}
                onClick={handleEditOnClick}
                size="xs"
                variant="outline"
              />
              {deleteOnClick && (
                <Button
                  color="neutral"
                  datas={{
                    'data-index': index,
                  }}
                  iconLeft={IconDelete}
                  onClick={deleteOnClick}
                  size="xs"
                  variant="outline"
                />
              )}
            </ButtonGroup>
          </div>
        </div>
      </ItemEL>
    </>
  );
};

export default DataSchemasFieldsItem;
