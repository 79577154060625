import { DataProps } from 'packages/formidable';
import React, { FC } from 'react';

import DataOrdersSelect from './Orders/Select';
import ProduitSku from './Produits/Sku';

export interface DataEspacesProps extends DataProps {
  espaceId?: string;
  collectionName?: string;
  datas?: DataProps | DataProps[];
  formName: string;
  type: string;
}
const DataEspaces: FC<DataEspacesProps> = props => {
  const { espaceId, name, type } = props;

  switch (type) {
    case 'orders/select': {
      if (!espaceId) {
        return (
          <div>{type}: erreur de paramètre : espaceId est obligatoire</div>
        );
      }

      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return <DataOrdersSelect {...props} espaceId={espaceId} name={name} />;
    }

    case 'produit/sku':
      if (!espaceId) {
        return (
          <div>{type}: erreur de paramètre : espaceId est obligatoire</div>
        );
      }

      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return (
        <ProduitSku
          {...props}
          componentType="produit/sku"
          espaceId={espaceId}
          name={name}
        />
      );

    default:
  }

  return <div>{`espace : ce type de composant n'existe pas :  ${type}`}</div>;
};

export default DataEspaces;
