import { TemplateType } from '@innedit/innedit-type';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'packages/innedit/config/firebase';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Template extends ModelEspace<TemplateType> {
  constructor(props: Omit<ModelEspaceProps<TemplateType>, 'collectionName'>) {
    super({
      canDoSearch: false,
      collectionName: 'templates',
      orderDirection: 'asc',
      orderField: 'label',
      queryBy: 'label',
      ...props,
    });
  }

  static sendTest = async ({
    email,
    id,
  }: {
    email: string;
    id: string;
  }): Promise<void> => {
    const func = httpsCallable(functions, 'sendTemplate');
    await func({ id, to: email });
  };
}

export default Template;
