import React, { FC } from 'react';

import Actions, { MenuType } from '~/components/Actions';

interface ModalFooterProps {
  buttons?: MenuType;
}

const ModalFooter: FC<ModalFooterProps> = ({ buttons }) => {
  if (!buttons || (0 === buttons.left?.length && 0 === buttons.right?.length)) {
    return null;
  }

  return (
    <div className="flex space-x-3 items-center justify-between p-[20px] border-t">
      <div>{buttons.left && <Actions items={buttons.left} />}</div>
      <div>{buttons.right && <Actions items={buttons.right} />}</div>
    </div>
  );
};

export default ModalFooter;
