import { DataField } from 'packages/formidable';
// import { ChannelData } from 'packages/innedit';
import React, { VFC } from 'react';

// import { toast } from 'react-toastify';
// import slug from 'slug';
import { DataColorSelectProps } from '~/datas/props';

const DataColorSelect: VFC<DataColorSelectProps> = ({
  channelId,
  espaceId,
  name,
  ...props
}) => {
  // const [colors, setColors] = useState<{ label: string; color: string }[]>();

  // const channelData = new ChannelData({ espaceId });

  // useEffect(() => {
  //   if (channelId) {
  //     channelData
  //       .findById(channelId)
  //       .then(channel => {
  //         if (setColors) {
  //           setColors(channel.colors);
  //         }
  //
  //         return true;
  //       })
  //       .catch(toast.error);
  //   }
  // }, [channelId]);

  const options = [
    {
      label: 'primary',
      value: 'primary',
    },
    {
      label: 'secondary',
      value: 'secondary',
    },
    {
      label: 'tertiary',
      value: 'tertiary',
    },
    {
      label: 'quaternary',
      value: 'quaternary',
    },
    {
      label: 'light',
      value: 'light',
    },
    {
      label: 'dark',
      value: 'dark',
    },
    {
      label: 'neutral',
      value: 'neutral',
    },
    {
      label: 'success',
      value: 'success',
    },
    {
      label: 'info',
      value: 'info',
    },
    {
      label: 'warning',
      value: 'warning',
    },
    {
      label: 'danger',
      value: 'danger',
    },
  ];

  // if (colors && colors.length > 0) {
  //   options.unshift({
  //     label: 'Personnalisée',
  //     options: colors.map(color => ({
  //       label: color.label,
  //       value: slug(color.label),
  //     })),
  //   });
  // }

  return (
    <>
      <DataField
        {...props}
        componentType="select"
        name={`${name}`}
        options={options}
      />
    </>
  );
};

export default DataColorSelect;
