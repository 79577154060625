import classnames from 'classnames';
import dayjs from 'dayjs';
import React, { FC } from 'react';

import IconTail from '~/icons/Tail';

interface MessageBubbleProps {
  date?: string;
  tail?: boolean;
  text: string;
  type?: 'recipient' | 'sender';
}

const MessageBubbleItem: FC<MessageBubbleProps> = function ({
  tail,
  text,
  type,
}) {
  return (
    <div
      className={classnames('flex flex-col space-y-6', {
        'justify-end': 'sender' === type,
        'justify-start': 'recipient' === type,
      })}
    >
      <div className="relative flex max-w-[75%]">
        <div
          dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br/>') }}
          className={classnames(
            'rounded-[18px] px-3 py-1.5 text-[17px] leading-[22px] md:text-[15px]',
            {
              'bg-[#007AFF] text-light-50': 'sender' === type,
              'bg-[#E9E9EB]': 'recipient' === type,
            },
          )}
        />
        {tail && (
          <IconTail
            className={classnames('absolute bottom-0', {
              '-left-[5px] text-[#E9E9EB]': 'recipient' === type,
              '-right-[5px] -scale-x-100 text-[#007AFF]': 'sender' === type,
            })}
            // color={'#007AFF'}
          />
        )}
      </div>
    </div>
  );
};

const MessageBubble: FC<MessageBubbleProps> = function ({
  date,
  text,
  tail = true,
  type = 'recipient',
}) {
  const message = text.trim();

  return (
    <div className="flex flex-col space-y-[2px]">
      {date && (
        <span className="text-[12px] text-light-800">
          {dayjs(date).format('ddd DD MMM à hh:mm')}
        </span>
      )}
      <MessageBubbleItem key={message} tail={tail} text={message} type={type} />
    </div>
  );
};

export default MessageBubble;
