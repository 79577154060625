import { UserType } from '@innedit/innedit-type';
import { DataProps } from 'packages/formidable';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { ReducersProps } from '~/reducers';

export interface DataUtilityQRCodeProps
  extends Omit<DataProps, 'componentType'> {
  field: keyof UserType;
  formName: string;
}

const DataUtilityQRCode: FC<DataUtilityQRCodeProps> = ({ field, formName }) => {
  const values = useSelector(
    (state: ReducersProps<UserType>) => state.form[formName].values,
  );
  const url = values[field] as string;

  return (
    <div className="flex justify-center">
      <img alt="qrcode" src={url} />
    </div>
  );
};

export default DataUtilityQRCode;
