/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import colors from './colors';
import spacing from './spacing';

const control = {
  bg: {
    color: {
      base: colors.neutral[50],
      focus: colors.light[400],
    },
  },
  border: {
    color: {
      base: colors.primary[300],
      hover: colors.primary[300],
    },
    size: { base: 1 },
    type: 'solid',
  },
  icon: {
    color: {
      base: colors.primary,
    },
    size: {
      base: 16,
    },
  },
  size: {
    height: {
      base: '2.5rem',
    },
  },
  padding: {
    x: {
      base: spacing[4],
      small: spacing[3],
      icon: spacing[10],
      iconSmall: spacing[10],
    },
    y: {
      base: 0,
      small: 0,
    },
  },
  radius: {
    base: spacing['1'],
  },
};

export default control;
