import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { PricesTiersProps } from '~/datas/props';

import PricesTiersRender from './Render';

const PricesTiers: FC<PricesTiersProps> = props => (
  <FieldArray {...props} component={PricesTiersRender} name={props.name} />
);

export default PricesTiers;
