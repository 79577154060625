import { DocumentType, PaymentType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import RequiresAction from '~/datas/Paiement/Manager/Transfer/RequiresAction';
import ItemTransferSucceeded from '~/datas/Paiement/Manager/Transfer/Succeeded';

const ItemTransfer: FC<{ payment: DocumentType<PaymentType> }> = function ({
  payment,
}) {
  return (
    <>
      {'requires_action' === payment.status && (
        <RequiresAction payment={payment} />
      )}
      {'requires_action' !== payment.status && (
        <ItemTransferSucceeded payment={payment} />
      )}
    </>
  );
};

export default ItemTransfer;
