import { ActionType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Action extends ModelEspace<ActionType> {
  constructor(props: Omit<ModelEspaceProps<ActionType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'actions',
      orderDirection: 'asc',
      orderField: 'trigger',
    });
  }

  initialize(data: Partial<ActionType> | undefined): Partial<ActionType> {
    return this.clean({ ...data, language: data?.language || 'FR' });
  }
}

export default Action;
