import { ChannelType, DocumentType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import IconDelete from '~/icons/Delete';
import IconEdit from '~/icons/Edit';

export interface ListItemProps {
  espaceId: string;
  index: number;
  doc: DocumentType<ChannelType>;
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

const Item: FC<
  ListItemProps & {
    index: number;
  }
> = ({ espaceId, index, doc, removeOnClick }) => (
  <div className="flex">
    <div>{doc.label}</div>

    <div className="flex space-x-1 self-end">
      {doc.id && (
        <Button
          className="no-left-radius"
          color="neutral"
          size="sm"
          tooltip={doc.id}
          type="tooltip"
          variant="ghost"
        />
      )}
      <Button
        color="secondary"
        iconLeft={IconEdit}
        size="sm"
        to={`/espaces/${espaceId}/channels/${doc.id}/update`}
        variant="ghost"
      />
      <Button
        color="danger"
        data-index={index}
        iconLeft={IconDelete}
        onClick={removeOnClick}
        size="sm"
        square
        variant="groove"
      />
    </div>
  </div>
);

export default Item;
