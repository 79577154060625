import { DataFieldProps } from 'packages/formidable/components/Data/props';
import React, { FC, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { WrappedFieldProps } from 'redux-form';

import Textarea from '~/components/Textarea';

const FieldTextarea: FC<
  WrappedFieldProps &
    DataFieldProps & {
      rows?: number;
    }
> = ({
  className,
  disabled,
  id,
  input,
  meta: { error, touched },
  placeholder,
  rows,
  showCounter,
}) => {
  const { t } = useTranslation();

  const { value } = input;

  // value.replace(/\n/g, ' ').trim().split(' ').length;
  const wordMatchRegExp = /[^\s]+/g; // Regular expression
  const words = value.matchAll(wordMatchRegExp);
  // matchAll returns an iterator, convert to array to get word count
  const wordCount = [...words].length;

  const nb = 'word' === showCounter ? wordCount : value.length;

  const handleOnKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if ('Tab' === event.key && !event.shiftKey) {
      // execCommand operations are "Cmd|Ctrl+Z"-able
      // note: execCommand is deprecated and may not work in the future
      document.execCommand('insertText', false, '\t');
      event.preventDefault();
    }
  };

  return (
    <>
      <Textarea
        {...input}
        className={className}
        disabled={disabled}
        id={id}
        onKeyDown={handleOnKeyDown}
        placeholder={placeholder ? t(placeholder) : placeholder}
        rows={rows}
        status={touched && error ? 'error' : undefined}
      />
      {showCounter && nb > 0 && (
        <span className="absolute -bottom-[16px] right-0 text-xxs">
          {t(`utility.counter.type.${showCounter ?? 'character'}`, {
            count: nb,
          })}
        </span>
      )}
    </>
  );
};

export default FieldTextarea;
