import { DataProps } from 'packages/formidable';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReducersProps } from '~/reducers';

export interface DataUtilityCounterProps
  extends Omit<DataProps, 'componentType'> {
  formName: string;
  field: string;
  type?: 'character' | 'word';
}

const DataUtilityCounter: FC<DataUtilityCounterProps> = ({
  field,
  formName,
  type = 'word',
}) => {
  const { t } = useTranslation();

  const values = useSelector(
    (state: ReducersProps<any>) => state.form[formName].values,
  );

  const value = values && values[field];

  if (undefined === value) {
    return null;
  }

  // value.replace(/\n/g, ' ').trim().split(' ').length;
  const wordMatchRegExp = /[^\s]+/g; // Regular expression
  const words = value.matchAll(wordMatchRegExp);
  // matchAll returns an iterator, convert to array to get word count
  const wordCount = [...words].length;

  const nb = 'word' === type ? wordCount : value.length;

  return (
    <span className="absolute right-6 -mt-5 text-xxs">
      {t(`utility.counter.type.${type}`, { count: nb })}
    </span>
  );
};

export default DataUtilityCounter;
