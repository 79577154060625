import React, { FC } from 'react';

const Icon: FC<{
  bgColor?: string;
  className?: string;
  color?: string;
  label?: string;
  size?: number;
  value: FC;
}> = ({ bgColor, className, color, label, size, value }) => {
  const IconCmp: any = value;

  return (
    <IconCmp
      bgColor={bgColor}
      className={className}
      color={color}
      label={label}
      size={size}
    />
  );
};
export default Icon;
