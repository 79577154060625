import { CollectionType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import FirebaseFirestore, {
  getDocs,
  limit,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import { firestore } from 'packages/innedit/config/firebase';
import Model, { ModelProps } from 'packages/innedit/datas/Model';

import client from '../../config/typesense';

class Collection extends Model<CollectionType> {
  constructor(props?: Omit<ModelProps<CollectionType>, 'collectionName'>) {
    super({
      collectionName: 'collections',
      ...props,
    });
  }

  public initialize(data?: Partial<CollectionType>): Partial<CollectionType> {
    return super.initialize({
      visibility: 'public',
      ...data,
    });
  }

  public async resetIndex(): Promise<void> {
    // On ne peut faire un reset que sur ces propres collections
    if (this.espaceId) {
      // On supprime toutes les collections de cet espace de l'index et on ajout les documents existants
      await client
        .collections(this.collectionName)
        .documents()
        .delete({
          filter_by: `espaceId:=${this.espaceId}`,
        });
      const batch = writeBatch(firestore);

      const constaints: FirebaseFirestore.QueryConstraint[] = [
        where('deleted', '==', false),
      ];

      if (this.espaceId) {
        constaints.push(where('espaceId', '==', this.espaceId));
      }

      // TODO traiter tous les documents - la limite de 500 est liée au batch
      constaints.push(limit(500));
      const q = query(this.getCollectionRef(), ...constaints);

      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        const date = dayjs().toISOString();
        querySnapshot.docs.forEach(doc => {
          batch.update(doc.ref, {
            updatedAt: date,
          } as any);
        });

        await batch.commit();
      }
    }
  }
}

export default Collection;
