import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataProductOptionsProps } from '~/datas/props';

import DataProductOptionsRender from './Render';

const DataProductOptions: FC<DataProductOptionsProps> = function ({
  name,
  ...others
}) {
  return (
    <FieldArray
      component={DataProductOptionsRender}
      name={name}
      props={others}
    />
  );
};

export default DataProductOptions;
