import {
  DeliveryInfoType,
  DeliveryType,
  DocumentType,
  getDeliveryInfo,
  ProduitType,
} from '@innedit/innedit-type';
import { DataProps } from 'packages/formidable';
import { DeliveryData } from 'packages/innedit';
import React, { useEffect, useState, VoidFunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import TransportRule from './TransportRule';

export interface DataDeliveryEstimationProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  docId?: string;
  formName: string;
}

const DataDeliveryEstimation: VoidFunctionComponent<
  DataDeliveryEstimationProps
> = ({ espaceId, docId, formName, params }) => {
  const [transports, setTransports] = useState<DocumentType<DeliveryType>[]>(
    [],
  );
  const values: ProduitType = useSelector(
    (state: any) => state.form[formName].values,
  ) as ProduitType;

  useEffect(() => {
    let isMounted = true;
    let unsub: any;

    if (docId) {
      const deliveryData = new DeliveryData({
        espaceId,
      });

      unsub = deliveryData.watch(newDocs => {
        if (isMounted) {
          setTransports(newDocs);
        }
      });
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, docId]);

  if (!docId) {
    return null;
  }

  let shippingInfo: DeliveryInfoType;
  try {
    shippingInfo = getDeliveryInfo(1, values);
  } catch (error) {
    return <div>{(error as Error).message}</div>;
  }

  if (!shippingInfo.volume && !shippingInfo.weight) {
    return <div>Impossible de calculer la livraison</div>;
  }

  return (
    <>
      <dl className="grid grid-cols-2">
        {shippingInfo.totalVolume > 0 && (
          <>
            <dt>Volume</dt>
            <dd>
              {`${shippingInfo.nbVolume} x ${
                Math.round(shippingInfo.volume * 1000) / 1000
              } = ${Math.round(shippingInfo.totalVolume * 1000) / 1000} m3`}
            </dd>
          </>
        )}

        {shippingInfo.totalVW > 0 && (
          <>
            <dt>Poids volumétrique</dt>
            <dd>{`${shippingInfo.nbVolume} x ${
              Math.round(shippingInfo.volumetricWeight * 100) / 100
            } = ${Math.round(shippingInfo.totalVW * 100) / 100} kg`}</dd>
          </>
        )}

        {shippingInfo.totalWeight > 0 && (
          <>
            <dt>Poids</dt>
            <dd>{`${shippingInfo.nbWeight} x ${shippingInfo.weight} = ${shippingInfo.totalWeight} kg`}</dd>
          </>
        )}
        {!shippingInfo.express && (
          <>
            <dt>Livraison express</dt>
            <dd>Impossible</dd>
          </>
        )}
        {shippingInfo.dimensionMax > 0 && (
          <>
            <dt>Dévelopé</dt>
            <dd>{`${shippingInfo.dimensionMax} cm`}</dd>
          </>
        )}
      </dl>

      <div>
        {transports.map(delivery => (
          <TransportRule
            key={delivery.id}
            delivery={delivery}
            info={shippingInfo}
          />
        ))}
      </div>
    </>
  );
};

export default DataDeliveryEstimation;
