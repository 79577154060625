import { DocumentType, PriceType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import Price from '~/components/Price';
import IconDelete from '~/icons/Delete';
import IconEdit from '~/icons/Edit';

export interface ListItemProps {
  doc: DocumentType<PriceType>;
  editOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  showDisplayMode?: boolean;
}

const Item: FC<ListItemProps> = ({
  doc,
  editOnClick,
  removeOnClick,
  showDisplayMode,
}) => (
  <div className="flex items-center space-x-3">
    {doc && (
      <div className="flex-1">
        <Price data={doc} showDisplayMode={showDisplayMode} />
      </div>
    )}
    <div>
      <Button
        color="secondary"
        datas={{
          'data-id': doc.id,
        }}
        iconLeft={IconEdit}
        onClick={editOnClick}
        size="sm"
        variant="ghost"
      />
      <Button
        color="danger"
        datas={{
          'data-id': doc.id,
        }}
        iconLeft={IconDelete}
        onClick={removeOnClick}
        size="sm"
        variant="ghost"
      />
    </div>
  </div>
);

export default Item;
