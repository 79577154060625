import {
  ChannelType,
  CollectionType,
  DocumentType,
  EspaceType,
  SchemaType,
  UserType,
} from '@innedit/innedit-type';

import {
  ESPACE_LOAD_ERROR,
  ESPACE_LOAD_REQUEST,
  ESPACE_LOAD_SUCCESS,
  ESPACE_SET_CHANNELS,
  ESPACE_SET_COLLECTIONS,
  HIDE_MENU,
  IS_MOBILE,
  IS_WEBAPP,
  SET_COLLECTION_NAME,
  SET_DOCUMENT_ID,
  TOGGLE_MENU,
  USER_IS_LOGOUT,
  USER_LOAD_ERROR,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_UNSUBSCRIBE,
} from '~/constants/actionTypes2';

export interface ReducerCMSProps {
  channels?: DocumentType<ChannelType>[];
  collectionName?: string;
  collections?: DocumentType<CollectionType>[];
  documentId?: string;
  error?: string;
  espace?: DocumentType<EspaceType>;
  espaceIsLoading: boolean;
  info?: string;
  mobile: boolean;
  openMenu: boolean;
  schemas?: DocumentType<SchemaType>[];
  unsubUser?: () => void;
  user?: DocumentType<UserType>;
  userIsLoading: boolean;
  userIsLogged: boolean;
  userUnsub?: () => void;
  webapp: boolean;
}

const initialState: ReducerCMSProps = {
  channels: undefined,
  collectionName: undefined,
  collections: undefined,
  documentId: undefined,
  error: undefined,
  espace: undefined,
  espaceIsLoading: true,
  info: undefined,
  mobile: false,
  openMenu: false,
  schemas: undefined,
  user: undefined,
  userIsLoading: true,
  userIsLogged: false,
  userUnsub: undefined,
  webapp: false,
};

const reducerCMS = (
  state = initialState,
  action: { [key: string]: any },
): ReducerCMSProps => {
  switch (action.type) {
    case ESPACE_SET_CHANNELS:
      return { ...state, channels: action.channels };

    case ESPACE_SET_COLLECTIONS:
      return { ...state, collections: action.collections };

    case HIDE_MENU:
      return { ...state, openMenu: false };

    case IS_MOBILE:
      return { ...state, mobile: action.test };

    case IS_WEBAPP:
      return { ...state, webapp: action.test };

    case TOGGLE_MENU:
      return { ...state, openMenu: !state.openMenu };

    case USER_LOAD_REQUEST:
      return {
        ...state,
        user: undefined,
        userIsLoading: true,
        userIsLogged: false,
      };

    case USER_LOAD_SUCCESS:
      return {
        ...state,
        error: undefined,
        info: undefined,
        user: action.user,
        userIsLoading: false,
        userIsLogged: true,
      };

    case USER_LOAD_ERROR:
      return {
        ...state,
        error: action.message,
        user: undefined,
        userIsLoading: false,
        userIsLogged: false,
      };

    case ESPACE_LOAD_REQUEST:
      return {
        ...state,
        espace: undefined,
        espaceIsLoading: true,
      };

    case ESPACE_LOAD_SUCCESS:
      return {
        ...state,
        espace: action.espace,
        espaceIsLoading: false,
      };

    case ESPACE_LOAD_ERROR:
      return {
        ...state,
        espace: undefined,
        espaceIsLoading: false,
      };

    case USER_IS_LOGOUT:
      return {
        ...state,
        channels: undefined,
        error: undefined,
        espace: undefined,
        espaceIsLoading: false,
        info: "L'utilisateur est déconnecté",
        user: undefined,
        userIsLoading: false,
        userIsLogged: false,
      };

    case USER_UNSUBSCRIBE: {
      // TODO voir pour lancer la fonctoin userUnsub() pour annuler le watch user
      return { ...state, userUnsub: action.unsubscribe };
    }

    case SET_COLLECTION_NAME: {
      return { ...state, collectionName: action.collectionName };
    }

    case SET_DOCUMENT_ID: {
      return { ...state, documentId: action.documentId };
    }

    default:
  }

  return state;
};

export default reducerCMS;
