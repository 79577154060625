import {
  ContactAttributesType,
  ContactType,
  DocumentType,
} from '@innedit/innedit-type';
import { Link } from 'gatsby';
import compact from 'lodash/compact';
import { Box } from 'packages/formidable';
import { ContactData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { change } from 'redux-form';

import Button from '~/components/Button';
import Editable from '~/datas/Contact/Editable';
import NotEditable from '~/datas/Contact/NotEditable';
import { DataContactProps } from '~/datas/props';
import IconLink from '~/icons/Link';
import IconUser from '~/icons/User';
import capitalize from '~/utils/capitalize';

const DataContact: FC<DataContactProps> = function ({
  editable = true,
  espaceId,
  formName,
  params,
}) {
  const dataForm: Partial<ContactAttributesType> | undefined =
    params && params[formName];
  const [contacts, setContacts] = useState<DocumentType<ContactType>[]>();
  const [contact, setContact] = useState<DocumentType<ContactType>>();
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    if (dataForm) {
      // setContact(dataForm.contactId);
      const q =
        dataForm.contactName || dataForm.contactEmail || dataForm.contactPhone;
      const model = new ContactData({ espaceId });
      if (!dataForm.contactId) {
        if (q) {
          model
            .search(q)
            .then(result => {
              if (isMounted) {
                setContacts(result?.hits?.map(({ document }) => document));
              }

              return isMounted;
            })
            .catch(toast.error);
        }
      } else {
        // On récupère le contact
        model
          .findById(dataForm.contactId)
          .then(newContact => setContact(newContact))
          .catch(error => toast.error(error.message));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [contact, JSON.stringify(dataForm)]);

  const handleAssociateOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const id = event.currentTarget.getAttribute('data-id');
    if (undefined !== id && null !== id) {
      dispatch(change(formName, 'contactId', id));
      const model = new ContactData({ espaceId });
      // TODO ajouter le userId au contact s'il n'existe pas
      model
        .findById(id)
        .then(newContact => setContact(newContact))
        .catch(error => toast.error(error.message));
    }
  };

  const handleCreateOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const contactModel = new ContactData({ espaceId });
    if (dataForm && dataForm.contactName) {
      const [firstName, ...lastNames] = dataForm.contactName.trim().split(' ');
      const newContact = await contactModel.create({
        firstName,
        address: dataForm.contactAddress,
        city: dataForm.contactCity,
        country: dataForm.contactCountry,
        email: dataForm.contactEmail,
        lastName: lastNames.map(capitalize).join(' '),
        phone: dataForm.contactPhone,
        userId: dataForm.userId,
        zip: dataForm.contactZip,
      } as ContactType);

      dispatch(change(formName, 'contactId', newContact.id));
      setContact(newContact);
    }
  };

  if (editable) {
    return <Editable formName={formName} params={params} />;
  }

  return (
    <div className="mb-6">
      <div className="flex flex-row items-center space-x-3">
        {dataForm && (
          <div className="flex flex-1 items-end justify-between">
            <NotEditable datas={dataForm} espaceId={espaceId} />

            {contacts !== undefined && contact === undefined && (
              <div>
                <Button
                  color="neutral"
                  onClick={handleCreateOnClick}
                  size="sm"
                  text="Créer un contact"
                  variant="outline"
                />
              </div>
            )}
          </div>
        )}

        {contact && (
          <div className="rounded bg-light-50 px-3 py-1.5 text-right">
            <div className="space-x-1.5">
              <span>{contact.firstName}</span>
              <span>{contact.lastName}</span>
            </div>
            <div>{contact.phone}</div>
            <div>{contact.email}</div>
            <div>
              {contact.address} {contact.zip} {contact.city}
            </div>
            <Button
              className="mt-3"
              color="neutral"
              iconRight={IconUser}
              size="sm"
              text="Voir le contact"
              to={`/espaces/${contact.espaceId}/contacts/${contact.id}/update`}
              variant="link"
            />
          </div>
        )}
      </div>

      {undefined !== contacts && (
        <div className="mt-6 flex flex-row">
          <Box>
            {editable && contact && <div>le contact est associé</div>}
            {!contact && (
              <>
                <h3>Liste des contacts trouvés</h3>
                <ul style={{ listStyle: 'disc' }}>
                  {contacts?.map(({ email, id, label, phone }) => (
                    <li key={id} className="flex items-center justify-between">
                      <Link to={`/espaces/${espaceId}/contacts/${id}/update`}>
                        {compact([label, email, phone]).join(' - ')}
                      </Link>
                      {(undefined === contact || contact !== id) && (
                        <Button
                          color="neutral"
                          datas={{
                            'data-id': id,
                          }}
                          iconLeft={IconLink}
                          onClick={handleAssociateOnClick}
                          size="sm"
                          text="Associer"
                          variant="outline"
                        />
                      )}
                      {contact && contact === id && <div>Associé</div>}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

export default DataContact;
