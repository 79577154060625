import { EspaceType } from '@innedit/innedit-type';

import Model, { ModelProps } from '../Model';

class Espace extends Model<EspaceType> {
  constructor(
    props?: Omit<ModelProps<EspaceType>, 'collectionName' | 'espaceId'>,
  ) {
    super({
      collectionName: 'espaces',
      orderDirection: 'asc',
      orderField: 'label',
      ...props,
    });
  }
}

export default Espace;
