import React, {
  FC,
  PropsWithChildren,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import IconCard from '../../icons/Card';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { ButtonProps } from './props';
import ButtonSC from './styles';

const ButtonTooltip: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  className,
  clipboard,
  square,
  text,
  type,
  value,
  ...others
}) => {
  const tooltipRef = useRef<HTMLButtonElement>(null);
  const [showToolTip, setToolTip] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const show = () => {
      setToolTip(true);
    };

    const hide = () => {
      setToolTip(false);
    };

    const clean = () => {
      if (tooltipRef && tooltipRef.current) {
        tooltipRef.current.removeEventListener('mouseover', show);
        tooltipRef.current.removeEventListener('mouseout', hide);
      }
    };

    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.addEventListener('mouseover', show);
      tooltipRef.current.addEventListener('mouseout', hide);
    }

    return () => {
      clean();
    };
  }, []);

  const onClick = (e: SyntheticEvent<HTMLElement>) => {
    if (clipboard && value) {
      e.preventDefault();

      if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(value);
      }
    }
  };

  return (
    <ButtonSC
      ref={tooltipRef}
      className={`${className} overflow-visible`}
      data-value={value}
      onClick={onClick}
      {...others}
      square={square || false}
    >
      {children}
      <Icon value={IconCard} />
      {text && <span className="text">{t(text)}</span>}
      {showToolTip && (
        <Tooltip
          direction="left"
          style={{ right: '50px', top: '6px' }}
          value={value}
        />
      )}
    </ButtonSC>
  );
};

export default ButtonTooltip;
