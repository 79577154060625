import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '~/components/Button';
import ItemEditing from '~/datas/Product/Options/ItemEditing';
import IconEdit from '~/icons/Edit';

export interface DataProductOptionsItemProps {
  data?: any;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}
const DataProductOptionsItem: FC<DataProductOptionsItemProps> = function ({
  data,
  deleteOnClick,
  index,
  name,
}) {
  const [isEditing, setIsEditing] = useState<boolean>(!data.label);

  const handleToggleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsEditing(p => !p);
  };

  const { label, values } = data as {
    label: string;
    values?: string[];
  };
  if (isEditing) {
    return (
      <ItemEditing
        closeOnClick={handleToggleOnClick}
        deleteOnClick={deleteOnClick}
        index={index}
        name={name}
      />
    );
  }

  return (
    <div className="flex flex-col min-h-12 justify-between items-start  space-y-2">
      <div className="flex justify-between w-full pr-1">
        <span className="font-normal">{label}</span>
        <Button
          color="neutral"
          iconLeft={IconEdit}
          onClick={handleToggleOnClick}
          size="sm"
          variant="link"
        />
      </div>
      {values && values.length > 0 && (
        <div className="flex space-x-1.5 w-full">
          {values.map(value => (
            <span
              key={value}
              className="rounded px-1.5 py-0.5 bg-neutral-200 text-xs"
            >
              {value}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default DataProductOptionsItem;
