import { httpsCallable } from 'firebase/functions';

import { functions } from '../config/firebase';

class Facia {
  static call({
    kind,
    limit,
    params,
    q,
    start,
  }: {
    kind: string;
    limit?: number;
    params?: Record<string, string | undefined>;
    q: string;
    start?: number;
  }): Promise<any> {
    const facia = httpsCallable(functions, 'facia');

    return facia({ kind, limit, params, q, start }).then(result => result.data);
  }
}

export default Facia;
