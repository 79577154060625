import {
  calculateWithShippingValue,
  DeliveryInfoType,
  DeliveryRuleType,
  DeliveryType,
  DocumentType,
} from '@innedit/innedit-type';
import React, { FC } from 'react';

const TransportRule: FC<{
  delivery: DocumentType<DeliveryType>;
  info: DeliveryInfoType;
}> = ({ delivery, info }) => {
  let bestExpress: DeliveryRuleType | undefined;
  let best: Partial<DeliveryRuleType> = {
    coefVolume: delivery.coefVolume,
    coefWeight: delivery.coefWeight,
    pec: delivery.pec,
    weight: undefined,
  };

  const volume = info.totalVolume || 0;
  const vw = info.totalVW || info.totalWeight || 0;
  const weight = info.totalWeight || 0;

  delivery.rules?.forEach(valeur => {
    if (!valeur.express || info.express) {
      if (valeur.express) {
        // il faut prendre le poids unitaire par colis
        const expressWeight =
          info.nbWeight > 0
            ? info.totalWeight / info.nbWeight
            : info.totalVW / info.nbVolume;
        // il n'y a que le poids qui est pris en compte pour les livraisons express
        if (expressWeight && Number(valeur.weight >= expressWeight)) {
          if (undefined === bestExpress) {
            bestExpress = valeur;
          } else {
            let bestDiff = 100000;
            if (bestExpress.weight) {
              // le poids existe
              bestDiff = Number(bestExpress.weight) - expressWeight;
            }
            let valeurDiff = 100000;
            if (valeur.weight) {
              // le poids existe
              valeurDiff = Number(valeur.weight) - expressWeight;
            }

            if (bestDiff > valeurDiff) {
              bestExpress = valeur;
            } else if (bestDiff === valeurDiff) {
              // il faut calculer la moins chere
              const bestPrice = Number(bestExpress.pec || 0);

              const valeurPrice = Number(valeur.pec || 0);

              if (valeurPrice < bestPrice) {
                bestExpress = valeur;
              }
            }
          }
        }
      } else if (vw && (!valeur.weight || Number(valeur.weight >= vw))) {
        // Cette régle est applicable
        if (undefined === best) {
          best = valeur;
        } else {
          let bestDiff = 100000;
          if (best.weight) {
            // le poids existe
            bestDiff = Number(best.weight) - weight;
          }
          let valeurDiff = 100000;
          if (valeur.weight) {
            // le poids existe
            valeurDiff = Number(valeur.weight) - weight;
          }
          if (bestDiff > valeurDiff) {
            best = valeur;
          } else if (bestDiff === valeurDiff) {
            // il faut calculer la moins chere
            const bestPrice =
              volume * Number(best.coefVolume || 0) +
              weight * Number(best.coefWeight || 0) +
              Number(best.pec || 0);

            const valeurPrice =
              volume * Number(valeur.coefVolume || 0) +
              weight * Number(valeur.coefWeight || 0) +
              Number(valeur.pec || 0);

            if (valeurPrice < bestPrice) {
              best = valeur;
            }
          }
        }
      }
    }
  });

  return (
    <div className="grid w-full grid-cols-3 gap-6">
      <span className="col-span-2">{delivery.label}</span>
      <div className="flex space-x-6">
        {best && (
          <span className="text-right">
            {`${calculateWithShippingValue(best, info)}€`}
          </span>
        )}

        {bestExpress && (
          <span className="text-right">
            {`${calculateWithShippingValue(bestExpress, info)}€`}
          </span>
        )}
        {!best && !bestExpress && <span>Aucune règle</span>}
      </div>
    </div>
  );
};
export default TransportRule;
