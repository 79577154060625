import { DataWithChildren } from 'packages/formidable';
import React, { FC } from 'react';

import { DataContentProps } from '~/datas/props';
import content from '~/params/content.json';

const DataContent: FC<DataContentProps> = props => (
  <DataWithChildren {...props} componentType="box" datas={content} />
);

export default DataContent;
