import { DiscountType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Discount extends ModelEspace<DiscountType> {
  constructor(props: Omit<ModelEspaceProps<DiscountType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'discounts',
      orderDirection: props.orderDirection || 'desc',
      orderField: props.orderField || 'createdAt',
    });
  }

  public initialize(data?: Partial<DiscountType>): Partial<DiscountType> {
    const newData = { ...data };

    return super.initialize({
      ...newData,
      hidden: newData.hidden ?? false,
      kind: newData.kind ?? 'order',
      method: newData.method ?? 'code',
      type: newData.type ?? 'amount',
    });
  }
}

export default Discount;
