import { DocumentType, PriceType } from '@innedit/innedit-type';
import { GroupBody } from 'packages/formidable/components/Group';
import { PriceData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '~/components/Button';
import { Box, DataProps } from '~/packages/formidable';
import { ReducersProps } from '~/reducers';
import displayCurrency from '~/utils/displayCurrency';

export interface DataPricesProps extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  formName: string;
  parentCollectionName: string;
  parentId: string;
}

const exchangeRates: { [currency: string]: { [currency: string]: number } } = {
  EUR: {
    EUR: 1,
    GBP: 0.87,
    USD: 1.05,
  },
  GBP: {
    EUR: 1.15,
    GBP: 1,
    USD: 1.21,
  },
  USD: {
    EUR: 0.95,
    GBP: 0.83,
    USD: 1,
  },
};

const convertPriceToCurrency = (
  amount: number,
  oldCurrency: string,
  newCurrency: string,
): number =>
  Math.round(amount * exchangeRates[oldCurrency][newCurrency] * 100) / 100;

const DataPrices: FC<DataPricesProps> = ({
  espaceId,
  formName,
  parentCollectionName,
  parentId,
}) => {
  const { t } = useTranslation();
  const [prices, setPrices] = useState<DocumentType<PriceType>[]>();

  const values = useSelector(
    (state: ReducersProps<PriceType>) => state.form[formName].values,
  );
  const currency = values && values.currency;

  useEffect(() => {
    let isMounted = true;
    if (parentId) {
      const priceData = new PriceData({
        espaceId,
        parentCollectionName,
        parentId,
      });

      priceData.watch(
        newPrices => {
          if (isMounted) {
            setPrices(newPrices);
          }
        },
        {
          orderDirection: 'desc',
          orderField: 'datetime',
        },
      );
    }

    return () => {
      isMounted = false;
    };
  }, [parentCollectionName, parentId]);

  return (
    <Box>
      {prices && (
        <GroupBody>
          {prices.map(price => (
            <Button
              key={price.id}
              className="flex justify-between"
              to={`../${price.id}/`}
              variant="clear"
            >
              <div>
                <span>
                  {price.amount} {displayCurrency(price.currency)}
                </span>
                <span className="ml-2 text-light-700">
                  {t(`prices.types.${price.type}`)}
                </span>
              </div>

              {currency && price.currency !== currency && (
                <span className="text-neutral-600">
                  {` ⇄ ${
                    price.amount &&
                    convertPriceToCurrency(
                      price.amount,
                      price.currency ?? 'EUR',
                      currency,
                    )
                  } ${displayCurrency(currency)}`}
                </span>
              )}
            </Button>
          ))}
        </GroupBody>
      )}
    </Box>
  );
};

export default DataPrices;
