import { OrderProductType } from '@innedit/innedit-type';
import { ProduitData } from 'packages/innedit';
import React, {
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { arrayPush } from 'redux-form';

import Button from '~/components/Button';
import Input from '~/components/Input';
import IconClose from '~/icons/Close';
import IconSearch from '~/icons/Search';

const OrderProductSearchModal: FC<{
  close: () => void;
  espaceId: string;
  formName: string;
  value?: string;
}> = function ({ close, espaceId, formName, value: defaultValue }) {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [q, setQ] = useState<string | undefined>(defaultValue ?? '');

  const [docs, setDocs] = useState<OrderProductType[]>();

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setQ(value);

    if ('' === value) {
      setDocs(undefined);
    }
  };

  const search = () => {
    const model = new ProduitData({
      espaceId,
      orderDirection: 'desc',
      orderField: 'datetime',
      wheres: {
        parent: '',
      },
    });

    model
      .search(q || '', {
        limit: 40,
      })
      .then(searchResult => {
        setDocs(
          searchResult?.hits?.map(({ document }) => {
            let thumbnail = '';
            if (document.medias && document.medias.length > 0) {
              const media = JSON.parse(document.medias[0] as unknown as string);
              thumbnail = `${media.origin}/w_1080,h_1080,c_limit,fm_webp/${media.pathname}`;
            }

            return {
              thumbnail,
              id: document.id,
              isPhysical: true,
              label: document.label,
              price: document.price ?? 0,
              quantity: 1,
            };
          }),
        );

        return true;
      })
      .catch(error => {
        toast.error(error.message);
        console.error(error.message);
      });
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if ('Enter' === e.key) {
      e.preventDefault();

      search();
    }
  };

  const handleResetOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setQ('');
    setDocs(undefined);
  };

  const handleSearchOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    search();
  };

  const handleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const index = event.currentTarget.getAttribute('data-index');

    if (index !== null) {
      if (docs && docs[parseInt(index, 10)]) {
        const doc = docs[parseInt(index, 10)];
        dispatch(arrayPush(formName, 'products', doc));
        close();
      }
    }
  };

  return (
    <div>
      <div ref={ref} className="flex">
        <div className="relative flex-1">
          <Input
            className=" rounded-r-none pr-[36px]"
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            placeholder="Recherche des produits"
            value={q}
          />
          {q && (
            <Button
              className="absolute right-[13px] top-[13px] w-[16px]"
              color="tertiary"
              iconLeft={IconClose}
              onClick={handleResetOnClick}
              variant="link"
            />
          )}
        </div>

        <Button
          className="rounded-l-none"
          color="neutral"
          iconLeft={IconSearch}
          onClick={handleSearchOnClick}
        />
      </div>
      <div>
        {docs && docs.length > 0 && (
          <div className="mt-6 flex flex-col space-y-2">
            {docs.map((doc, index) => (
              <div key={doc.id} className="flex justify-between">
                <div className="flex space-x-3">
                  <img
                    alt={doc.label}
                    className="max-h-[40px] max-w-[40px] bg-neutral-100"
                    height={40}
                    src={doc.thumbnail}
                  />
                  <div>
                    <span>{doc.label}</span>
                  </div>
                </div>
                <Button
                  datas={{ 'data-index': index }}
                  onClick={handleOnClick}
                  variant="link"
                >
                  Sélectionner
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderProductSearchModal;
