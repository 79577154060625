import React, { FC, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { change } from 'redux-form';

import { ReducersProps } from '~/reducers';

import Button from '../../../components/Button';
import IconMagic from '../../../icons/Magic';
import { DataField, DataFieldProps } from '../../../packages/formidable';
import phoneUtil, { PNF } from '../../../utils/phoneUtil';

export interface DataFieldPhoneProps
  extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
}

const DataFieldPhone: FC<DataFieldPhoneProps> = function ({
  countryField = 'country',
  formName,
  name,
  ...props
}) {
  const dispatch = useDispatch();
  const values = useSelector(
    (state: ReducersProps<any>) =>
      state.form[formName] && state.form[formName].values,
  );

  const handleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (values && values[name]) {
      const number = values[name];
      const country = values[countryField] ?? 'FR';

      let phoneNumber;
      let isValid;
      try {
        phoneNumber = phoneUtil.parseAndKeepRawInput(number, country);
        isValid = phoneUtil.isValidNumberForRegion(phoneNumber, country);

        if (phoneNumber && isValid) {
          const formattedNumber: string = phoneUtil.format(
            phoneNumber,
            PNF.INTERNATIONAL, // E164
          );
          dispatch(change(formName, name, formattedNumber));
        } else {
          toast.error("Le numéro de téléphone n'est pas valide");
        }
      } catch (error) {
        toast.error("Le numéro de téléphone n'est pas valide");
      }
    }
  };

  const Action = (
    <Button
      className="rounded-l-none h-[42px]"
      color="neutral"
      iconLeft={IconMagic}
      onClick={handleOnClick}
      variant="solid"
    />
  );

  return (
    <DataField
      {...props}
      componentType="input"
      customAction={Action}
      fieldProps={{
        autoComplete: 'tel',
        className: 'rounded-r-none border-r-0',
        inputMode: 'tel',
      }}
      formName={formName}
      name={name}
      type="text"
    />
  );
};

export default DataFieldPhone;
