import React, { FC, SyntheticEvent } from 'react';

import Button from '../../../components/Button';
import IconEdit from '../../../icons/Edit';
import { DataField } from '../../../packages/formidable';

const Item: FC<{
  data: any;
  editOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}> = ({ data, editOnClick, index, name }) => (
  <DataField
    componentType="input"
    customAction={
      <div className="flex">
        <Button
          className="rounded-l-none"
          color="neutral"
          data-index={index}
          iconLeft={IconEdit}
          onClick={editOnClick}
          variant="field"
        />
      </div>
    }
    fieldProps={{ className: 'rounded-r-none border-r-0' }}
    label={data.label}
    name={`${name}.value`}
    type="text"
  />
);

export default Item;
