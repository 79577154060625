import { DataType, initializeValues } from '@innedit/innedit-type';
import { diff } from 'deep-object-diff';
import hash from 'object-hash';
import { DataProps } from 'packages/formidable/components/Data/props';
import { FormProps } from 'packages/formidable/components/props';
import React, { FC, PropsWithChildren, useState } from 'react';
import { Dispatch } from 'redux';
import { DecoratedFormProps, submit } from 'redux-form';

import Data from '../Data';
import FormRender from './Render';

const Form: FC<PropsWithChildren<FormProps>> = ({
  actions,
  asyncChangeFields,
  asyncValidate,
  autosave,
  bodyProps,
  cancelProps,
  children,
  className,
  datas,
  destroyOnUnmount,
  enableReinitialize,
  forceUnregisterOnUnmount,
  footerProps,
  hideSubmitButton,
  id,
  initialValues,
  isSubmissive,
  keepDirtyOnReinitialize,
  mode,
  name,
  onChange,
  onSubmit,
  params,
  removePristine,
  submitProps,
  touchOnChange,
  updateUnregisteredFields,
  validate,
}) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [canBeSubmited, setCanBeSubmited] = useState<boolean>();

  const newDatas: DataType[] | undefined =
    datas && !Array.isArray(datas) ? [datas] : datas;

  const handleOnChange = (
    values: Partial<FormData>,
    dispatch: Dispatch<any>,
    props: DecoratedFormProps<FormData, any>,
    previousValues: Partial<FormData>,
  ): void => {
    if (onChange) {
      onChange(values, dispatch, props, previousValues);
    }

    if (autosave) {
      setCanBeSubmited(true);

      if (Object.keys(diff(values, previousValues)).length > 0) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        setTimeoutId(
          setTimeout(() => {
            if (canBeSubmited) {
              dispatch(submit(name));
            }
          }, 1000),
        );
      }
    }
  };

  return (
    <FormRender
      actions={actions}
      asyncChangeFields={asyncChangeFields}
      asyncValidate={asyncValidate}
      bodyProps={bodyProps}
      cancelProps={cancelProps}
      className={className}
      destroyOnUnmount={destroyOnUnmount}
      enableReinitialize={enableReinitialize}
      footerProps={footerProps}
      forceUnregisterOnUnmount={forceUnregisterOnUnmount}
      hideSubmitButton={hideSubmitButton}
      id={id}
      initialValues={initialValues || (newDatas && initializeValues(newDatas))}
      isSubmissive={isSubmissive}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      name={name}
      onChange={handleOnChange}
      onSubmit={onSubmit}
      removePristine={removePristine}
      submitProps={submitProps}
      touchOnChange={touchOnChange}
      updateUnregisteredFields={updateUnregisteredFields}
      validate={validate}
    >
      {newDatas &&
        newDatas.map((props: DataProps, index) => (
          <Data
            key={hash({
              ...props,
              index,
              customAction: null,
              customBottom: null,
              customInfos: null,
              customTop: null,
              datas: null,
              params: null,
            })}
            {...props}
            formName={name}
            mode={mode}
            params={params}
          />
        ))}
      {children}
    </FormRender>
  );
};

export default Form;
