import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import { DataColorsProps } from '~/datas/props';

import HOCGroup from '../../components/Group/HOC';
import DataColorsItem from './Item';

const DataColorsRender: FC<
  WrappedFieldArrayProps & Omit<DataColorsProps, 'name'>
> = ({ display, espaceId, fields, formName, label, params }) => {
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fields.push({
      bgColor: '#ffffff',
      borderColor: '#000000',
      color: '#ffffff',
      textColor: '#000000',
      value: '',
    });
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      display={display}
      formName={formName}
      title={label}
    >
      <div className="flex w-full justify-between">
        <span className="flex-1 text-center">Libellé</span>
        <span className="flex-1 text-center">Couleur</span>
        <span className="flex-1 text-center">Texte</span>
        <span className="flex-1 text-center">Arrière plan</span>
        <span className="flex-1 text-center">Bordure</span>
        <span className="w-[42px]" />
        <span className="w-[42px]" />
      </div>
      {fields.length > 0 && (
        <div className="flex flex-col space-y-0.5">
          {fields.map((field, index, datas) => (
            <DataColorsItem
              key={field}
              data={datas.get(index)}
              deleteOnClick={handleDeleteOnClick}
              espaceId={espaceId}
              formName={formName}
              index={index}
              name={field}
              params={params}
            />
          ))}
        </div>
      )}
      {0 === fields.length && <p>Aucune couleur</p>}
    </HOCGroup>
  );
};

export default DataColorsRender;
