import { Google } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { DataGoogleSearchConsoleProps } from '~/datas/props';
import IconLoading from '~/icons/Loading';

import HOCGroup from '../../components/Group/HOC';

const DataGoogleSearchConsole: FC<DataGoogleSearchConsoleProps> = ({
  display,
  field,
  formName,
  title = 'Google',
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stats, setStats] = useState<string>();

  const { values } = useSelector(
    (globalState: any) => globalState.form[formName],
  );

  const url = values && values[field];

  useEffect(() => {
    let isMounted = true;
    if (url) {
      Google.call({ kind: 'results', q: `site:${url}` })
        .then((data: any) => {
          if (isMounted) {
            setStats(data.stats);
            setIsLoading(false);
          }

          return isMounted;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [url]);

  return (
    <HOCGroup display={display} title={title}>
      {isLoading && (
        <div className="flex items-center space-x-3">
          <IconLoading /> <span>Chargement en cours...</span>
        </div>
      )}
      {!isLoading && stats}
    </HOCGroup>
  );
};

export default DataGoogleSearchConsole;
