import { DataFieldArrayProps } from 'packages/formidable/components/Data/props';
import { EspaceData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import DataDocumentsSelect from '../../Documents/Select';

export interface DataProduitProgramProps
  extends Omit<DataFieldArrayProps, 'componentType'> {
  espaceId: string;
}

const DataProduitProgram: FC<DataProduitProgramProps> = function ({
  espaceId,
  ...props
}) {
  const [collectionId, setCollectionId] = useState<string>();

  useEffect(() => {
    if (espaceId) {
      const espaceData = new EspaceData();
      espaceData
        .findById(espaceId)
        .then(espace => {
          setCollectionId(espace.products?.programCollectionId);

          return true;
        })
        .catch(e => {
          throw new Error(`loadEspace : ${e.message}`);
        });
    }
  }, [espaceId]);

  if (!collectionId) {
    return <div>Il n&apos;y a aucun programme de selectionnable</div>;
  }

  return (
    <DataDocumentsSelect
      collectionId={collectionId}
      espaceId={espaceId}
      {...props}
    />
  );
};

export default DataProduitProgram;
