import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataPaiementItemsProps } from '~/datas/props';

import ItemsRender from './Render';

const DataPaiementItems: FC<DataPaiementItemsProps & { name: string }> = ({
  ...props
}) => <FieldArray component={ItemsRender} name={props.name} props={props} />;

export default DataPaiementItems;
