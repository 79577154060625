import { PageType } from '@innedit/innedit-type';

import ModelChannel, { ModelChannelProps } from '../../../Model/Channel';

class PageData extends ModelChannel<PageType> {
  constructor(props: Omit<ModelChannelProps<PageType>, 'collectionName'>) {
    super({
      addButtonLabel: 'Créer une page',
      canDoSearch: true,
      collectionName: 'pages',
      orderDirection: 'desc',
      orderField: 'datetime',
      queryBy: 'label',
      ...props,
    });

    this.channelId = props.channelId;
  }

  public initialize(data?: Partial<PageType>): Partial<PageType> {
    return super.initialize({
      ...data,
      channelId: this.channelId,
      hideTitle: data?.hideTitle ?? false,
      isHomePage: data?.isHomePage ?? false,
    });
  }
}

export default PageData;
