/* eslint-disable max-len */
import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconSort: FC<IconType> = ({ color, size = 16 }) => (
  <svg
    enableBackground="new 0 0 100 100"
    height={size}
    viewBox="0 0 100 100"
    width={size}
    x="0px"
    y="0px"
  >
    <path
      d="M49.924,44.84c-8.066,0-16.133,0.016-24.199-0.017c-1.109-0.005-2.24-0.152-3.322-0.399  c-2.906-0.664-4.182-3.037-3.03-5.801c0.525-1.258,1.273-2.489,2.166-3.519c7.25-8.37,14.543-16.702,21.866-25.008  c0.908-1.03,1.992-1.948,3.125-2.729c2.267-1.566,4.693-1.626,6.913,0.017c1.528,1.131,2.922,2.484,4.202,3.894  c3.311,3.646,6.537,7.368,9.774,11.081c3.757,4.311,7.509,8.624,11.216,12.979c1.178,1.383,2.217,2.892,2.314,4.822  c0.102,2.034-0.784,3.476-2.746,4.056c-1.217,0.36-2.517,0.59-3.782,0.599C66.255,44.866,58.089,44.84,49.924,44.84z"
      fill={color || 'currentColor'}
    />
    <path
      d="M50.008,55.16c8,0.001,16-0.025,24,0.026c1.364,0.009,2.771,0.198,4.081,0.575  c2.402,0.689,3.556,2.79,2.647,5.116c-0.634,1.624-1.595,3.208-2.733,4.531c-6.903,8.014-13.879,15.965-20.884,23.892  c-1.105,1.251-2.385,2.396-3.745,3.364c-2.179,1.552-4.54,1.544-6.745,0.023c-1.213-0.837-2.376-1.816-3.349-2.918  c-7.24-8.203-14.437-16.444-21.614-24.702c-0.794-0.913-1.496-1.95-2.039-3.032c-1.669-3.327-0.081-6.213,3.623-6.657  c1.142-0.139,2.3-0.207,3.45-0.209C34.47,55.152,42.239,55.16,50.008,55.16z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconSort;
