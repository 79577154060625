import { ContactType } from '@innedit/innedit-type';

// import FirebaseFirestore, {
//   limit,
//   onSnapshot,
//   query,
//   startAfter,
//   where,
// } from 'firebase/firestore';
// import { mergeWith } from 'lodash';
//
// import { updateConstraints, WatchOptionsProp } from '../../functions';
import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Contact extends ModelEspace<ContactType> {
  constructor(props: Omit<ModelEspaceProps<ContactType>, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'contacts',
      orderDirection: 'asc',
      orderField: 'label',
      queryBy: 'label, email, phone, address, zip, city',
      tabs: [
        {
          itemMode: 'grid',
          label: 'Tous les contacts',
          pathname: `/espaces/${props.espaceId}/contacts`,
        },
        {
          itemMode: 'grid',
          label: 'Invitation en cours',
          pathname: `/espaces/${props.espaceId}/contacts/invitation`,
          wheres: {
            userIsInvited: true,
            userIsSubscribed: false,
          },
        },
        {
          itemMode: 'grid',
          label: 'Abonnés',
          pathname: `/espaces/${props.espaceId}/contacts/subscribers`,
          wheres: {
            userIsSubscribed: true,
          },
        },
        {
          itemMode: 'grid',
          label: 'Désabonnés',
          pathname: `/espaces/${props.espaceId}/contacts/unsubscribers`,
          wheres: {
            userIsUnsubscribed: true,
          },
        },
      ],
      ...props,
    });
  }

  // public watch(
  //   next: (docs: DocumentType<UserType>[]) => void,
  //   options?: WatchOptionsProp,
  // ): FirebaseFirestore.Unsubscribe {
  //   const newOptions = options || {};
  //
  //   if (!newOptions.wheres) {
  //     newOptions.wheres = {};
  //   }
  //
  //   newOptions.wheres.contacts = {
  //     operator: 'array-contains',
  //     value: this.espaceId,
  //   };
  //
  //   let constraints = [where('deleted', '==', false)];
  //
  //   constraints = updateConstraints(constraints, {
  //     orderDirection: newOptions?.orderDirection || this.orderDirection,
  //     orderField: newOptions?.orderField || this.orderField,
  //     wheres: newOptions?.wheres
  //       ? mergeWith(newOptions.wheres, this.wheres)
  //       : this.wheres,
  //   });
  //
  //   if (options?.startAfter) {
  //     constraints.push(startAfter(newOptions.startAfter));
  //   }
  //   constraints.push(
  //     limit(
  //       newOptions?.limit ??
  //         (parseInt(String(process.env.GATSBY_INNEDIT_WATCH_LIMIT), 10) || 250),
  //     ),
  //   );
  //
  //   return onSnapshot(query(this.getCollectionRef(), ...constraints), {
  //     next: querySnaphot => {
  //       next(querySnaphot.docs.map(d => ({ id: d.id, ...d.data() })));
  //     },
  //   });
  // }
}

export default Contact;
