import objectHash from 'object-hash';
import { ParsedQuery } from 'query-string';
import React, { FC } from 'react';

import { FilterType } from '~/components/Filter/types';

import Filter from './index';

interface FiltersProps {
  items?: FilterType[];
  params?: ParsedQuery;
}

const FilterList: FC<FiltersProps> = ({ params, items }) => (
  <>
    {items &&
      items.map((filter, index) => (
        <Filter
          key={objectHash(filter)}
          {...filter}
          index={index}
          params={params}
        />
      ))}
  </>
);

export default FilterList;
