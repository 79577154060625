import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconGripDotsVertical: FC<IconType> = ({ className, color, onClick }) => (
  <svg
    className={className}
    height="100%"
    onClick={onClick}
    viewBox="0 0 256 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm0 160a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM96 416A48 48 0 1 0 0 416a48 48 0 1 0 96 0zM208 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48 112a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM208 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconGripDotsVertical;
