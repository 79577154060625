import React, { FC, SyntheticEvent } from 'react';

import Modal from '~/components/View/Modal';
import OrderProductSearchModal from '~/datas/Orders/Products/Search/Modal';

// const usePreviousQ = (value?: string): string | undefined => {
//   const ref = useRef<string>();
//
//   useEffect(() => {
//     ref.current = value;
//   });
//
//   return ref.current;
// };

const OrderProductSearch: FC<{
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  espaceId: string;
  formName: string;
}> = function ({ closeOnClick, espaceId, formName }) {
  return (
    <Modal
      buttons={{
        right: [
          {
            label: 'Annuler',
            onClick: closeOnClick,
          },
        ],
      }}
      closeOnClick={closeOnClick}
      title="Tous les produits"
    >
      <OrderProductSearchModal
        close={closeOnClick}
        espaceId={espaceId}
        formName={formName}
      />
    </Modal>
  );
};

export default OrderProductSearch;
