import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';
import { DataProductOptionsProps } from '~/datas/props';

import DataProductOptionsItem from './Item';

const DataProductOptionsRender: FC<
  WrappedFieldArrayProps & Omit<DataProductOptionsProps, 'name'>
> = ({ display, docId, espaceId, fields, formName, label }) => {
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fields.push({
      label: '',
      values: [''],
    });
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  return (
    <HOCGroup addOnClick={handleAddOnClick} display={display} label={label}>
      {fields.length > 0 && (
        <div className="flex flex-col space-y-2">
          {fields.map((field, index, datas) => (
            <DataProductOptionsItem
              key={field}
              data={datas.get(index)}
              deleteOnClick={handleDeleteOnClick}
              index={index}
              name={field}
            />
          ))}
        </div>
      )}
      {0 === fields.length && <p>Aucune option</p>}
    </HOCGroup>
  );
};

export default DataProductOptionsRender;
