import React, { VoidFunctionComponent } from 'react';
import { FieldArray } from 'redux-form';

import { DataOrdersProductsProps } from '~/datas/props';

import ProductsRender from './Render';

const DataOrdersProducts: VoidFunctionComponent<
  DataOrdersProductsProps
> = props => (
  <FieldArray component={ProductsRender} name={props.name} props={props} />
);

export default DataOrdersProducts;
