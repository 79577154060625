import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataColorsProps } from '~/datas/props';

import DataColorsRender from './Render';

const DataColors: FC<DataColorsProps> = ({ name, ...others }) => (
  <FieldArray component={DataColorsRender} name={name} props={others} />
);

export default DataColors;
