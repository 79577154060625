import { Dispatch } from 'packages/formidable';
import { EspaceData } from 'packages/innedit';

import {
  ESPACE_LOAD_ERROR,
  ESPACE_LOAD_REQUEST,
  ESPACE_LOAD_SUCCESS,
} from '~/constants/actionTypes2';

function findById(id: string): any {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: ESPACE_LOAD_REQUEST,
    });
    const espaceData = new EspaceData({
      orderField: 'label',
    });

    return espaceData
      .findById(id)
      .then(espace => dispatch({ espace, type: ESPACE_LOAD_SUCCESS }))
      .catch(() => dispatch({ type: ESPACE_LOAD_ERROR }));
  };
}

export default findById;
