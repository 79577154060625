import { ChecklistType } from '@innedit/innedit-type';

import Model, { ModelProps } from '../../Model';

class Checklist extends Model<ChecklistType> {
  constructor(props: Omit<ModelProps<ChecklistType>, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'checklists',
      orderDirection: 'desc',
      orderField: 'createdAt',
      queryBy: 'label',
      ...props,
    });
  }

  public initialize(data?: Partial<ChecklistType>): Partial<ChecklistType> {
    return super.initialize({
      nbChildren: 0,
      ...data,
    });
  }
}

export default Checklist;
