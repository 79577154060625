/* eslint-disable max-len */
import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconCard: FC<IconType> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M15.0006 2C15.552 2 16 2.448 16 3.00062V12.9994C16 13.5532 15.552 14 14.9994 14H1.00062C0.446769 14 0 13.552 0 12.9994V3.00062C0 2.44677 0.448 2 1.00062 2H14.9994H15.0006ZM14 4H2V12H14V4ZM12.384 5.00062C12.7249 5.00062 12.9994 5.27508 12.9994 5.616V7.38462C12.9994 7.72431 12.7249 8 12.384 8H9.61477C9.27508 8 8.99938 7.72431 8.99938 7.38462V5.61477C8.99938 5.27508 9.27508 4.99938 9.61477 4.99938H12.384V5.00062Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconCard;
