import { navigate } from 'gatsby';
import React, {
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';

import IconClose from '../icons/Close';
import IconSearch from '../icons/Search';
import pathname from '../utils/pathname';
import Button from './Button';

interface SearchProps {
  q?: string;
}

const Search: FC<SearchProps> = ({ q: defaultQ }) => {
  const [q, setQ] = useState<string>(defaultQ || '');

  useEffect(() => {
    setQ(defaultQ || '');
  }, [defaultQ]);

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setQ(value);
  };

  const handleClearOnClick = () => {
    setQ('');

    return navigate(
      pathname({
        actions: [{ name: 'page' }, { name: 'q' }],
      }),
      {
        replace: true,
      },
    );
  };

  const handleOnKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    if ('Enter' === key && q) {
      await navigate(
        pathname({
          actions: [{ name: 'page' }, { name: 'q', value: q }],
        }),
        {
          replace: true,
        },
      );
    }
  };

  return (
    <div className="flex flex-1 items-center rounded relative mx-6 md:mx-0">
      <input
        className="!px-8 flex-1 relative leading-10 h-10 border-box rounded-md bg-[#E1E4EB] outline-none"
        onChange={handleOnChange}
        onKeyPress={handleOnKeyPress}
        type="text"
        value={q}
      />
      <IconSearch
        className="absolute left-2 text-black-400 top-[9px]"
        size={20}
      />
      {q && (
        <Button
          className="absolute right-0 text-black-400 top-0 bottom-0 h-[40px] w-[40px]"
          iconClassName="h-[16px] w-[16px]"
          iconLeft={IconClose}
          onClick={handleClearOnClick}
          variant="link"
        />
      )}
    </div>
  );
};

export default Search;
