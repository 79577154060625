import {
  HIDE_MENU,
  IS_MOBILE,
  IS_WEBAPP,
  SET_COLLECTION_NAME,
  SET_DOCUMENT_ID,
  TOGGLE_MENU,
} from '~/constants/actionTypes2';

export function hideMenu(): any {
  return {
    type: HIDE_MENU,
  };
}

export function toggleMenu(): any {
  return {
    type: TOGGLE_MENU,
  };
}

export function isMobile(value: boolean): any {
  return {
    test: value,
    type: IS_MOBILE,
  };
}

export function isWebapp(value: boolean): any {
  return {
    test: value,
    type: IS_WEBAPP,
  };
}

export function setDocumentId(documentId: string): any {
  return {
    documentId,
    type: SET_DOCUMENT_ID,
  };
}

export function setCollectionName(collectionName: string): any {
  return {
    collectionName,
    type: SET_COLLECTION_NAME,
  };
}
