import { Link } from 'gatsby';
import { PageData, ProduitData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import ButtonGroup from '~/components/Button/Group';
import Media from '~/components/Media';
import { ItemEL } from '~/datas/Contents/Crud/Item';
import IconDelete from '~/icons/Delete';

export interface DataAssociationProps {
  data: {
    collection: string;
    id: string;
    label?: string;
  };
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  espaceId: string;
  index: number;
}
const DataAssociation: FC<DataAssociationProps> = ({
  data,
  deleteOnClick,
  espaceId,
  index,
}) => {
  const [document, setDocument] = useState<any>();

  useEffect(() => {
    let isMounted = true;
    switch (data.collection) {
      case 'produits': {
        const produitData = new ProduitData({ espaceId });
        produitData
          .findById(data.id)
          .then(snapshot => {
            if (isMounted) {
              setDocument(snapshot);
            }

            return isMounted;
          })
          .catch(toast.error);
        break;
      }

      case 'pages': {
        const pageData = new PageData({ espaceId });
        pageData
          .findById(data.id)
          .then(snapshot => {
            if (isMounted) {
              setDocument(snapshot);
            }

            return isMounted;
          })
          .catch(toast.error);
        break;
      }
    }

    return () => {
      isMounted = false;
    };
  }, [data]);

  const media =
    document?.medias && document.medias.length > 0
      ? document.medias[0]
      : undefined;

  const to =
    data.collection !== 'pages'
      ? `/espaces/${espaceId}/${data.collection}/${data.id}/update`
      : `/espaces/${espaceId}/channels/${document?.channelId}/pages/${data.id}/update`;

  return (
    <ItemEL className="item-list grid min-h-12 grid-cols-6 flex-row items-center rounded border bg-light-300 p-1">
      {data.collection && <span className="ml-1">{data.collection}</span>}
      <div className="col-span-4 flex items-center space-x-3">
        {media && (
          <Media
            media={media}
            options={{
              height: 38,
              width: 38,
            }}
          />
        )}
        {data.label && (
          <span className="ml-1 truncate">
            <Link to={to}>{data.label}</Link>
          </span>
        )}
      </div>

      <div className="ml-1 flex justify-between text-neutral-700">
        <div className="flex space-x-3 text-xs" />
        <div className="actions items-center justify-center">
          <ButtonGroup>
            {deleteOnClick && (
              <Button
                color="neutral"
                datas={{
                  'data-index': index,
                }}
                iconLeft={IconDelete}
                onClick={deleteOnClick}
                size="xs"
                variant="outline"
              />
            )}
          </ButtonGroup>
        </div>
      </div>
    </ItemEL>
  );
};

export default DataAssociation;
