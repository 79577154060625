import { OrderProductType, ProduitType } from '@innedit/innedit-type';
import { ProduitData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '~/components/Button';
import Modal from '~/components/View/Modal';
import Form from '~/containers/Espace/Form';
import params from '~/params/produit/index.json';

interface ItemNoEditableProps {
  espaceId: string;
  item: OrderProductType;
}

const ItemNoEditable: FC<ItemNoEditableProps> = ({ espaceId, item }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleCloseOnClick = () => {
    setOpen(false);
  };

  return (
    <>
      {open && item.id && (
        <Modal closeOnClick={handleCloseOnClick} title={item.label}>
          <Form<ProduitType>
            displayArchive={false}
            displayClose={false}
            displayComments={false}
            displayHeader={false}
            displayRemove={false}
            displayView={false}
            docId={item.id}
            footerProps={{
              className: 'flex justify-end',
            }}
            hideSubmitButton
            model={new ProduitData({ espaceId, params })}
            type="update"
          />
        </Modal>
      )}

      <div className="grid grid-cols-3 items-start gap-3">
        <div className="col-span-2 flex space-x-2">
          {item.thumbnail && (
            <img alt={item.label} className="h-[40px]" src={item.thumbnail} />
          )}
          <div className="flex flex-col justify-start">
            {item.id ? (
              <Button
                className="w-auto justify-start text-start"
                onClick={handleOpenOnClick}
                variant="link"
              >
                {item.label}
              </Button>
            ) : (
              <strong>{item.label}</strong>
            )}
            <div className="flex items-center">
              {item.isPhysical ? 'Produit physique' : ''}
            </div>
          </div>
        </div>

        <div className="col-span-1 flex items-center justify-end">
          {item.quantity || 1} x {item.price}€
        </div>
      </div>
    </>
  );
};

export default ItemNoEditable;
