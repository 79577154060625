import { FC, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MODAL_ID } from '~/constants';

interface PortalProps {
  fixBody?: boolean;
}

const Portal: FC<PropsWithChildren<PortalProps>> = ({
  fixBody = true,
  children,
}) => {
  const el = document.createElement('div');

  useEffect(() => {
    if (fixBody) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (fixBody) {
        document.body.style.overflow = 'unset';
      }
    };
  }, [fixBody]);

  useEffect(() => {
    const modalRoot = document.getElementById(MODAL_ID);

    if (modalRoot) {
      modalRoot.appendChild(el);
    }

    return () => {
      if (modalRoot) {
        modalRoot.removeChild(el);
      }
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
};

export default Portal;
