import styled from '@emotion/styled';
import { DataFieldProps } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';
import { toast } from 'react-toastify';
// import { useSelector } from 'react-redux';
import { FieldArray, WrappedFieldArrayProps } from 'redux-form';
import slug from 'slug';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import IconDelete from '~/icons/Delete';
// import { ReducersProps } from '~/reducers';

export interface DataImagesProps extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
}

const Div = styled.div`
  .hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: rgba(49, 55, 61, 0.75);

    opacity: 0;
    transition: opacity 0.1s ease-in-out;

    &:hover {
      opacity: 1;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
`;

const DataImagesRender: FC<
  WrappedFieldArrayProps & Omit<DataImagesProps, 'name'>
> = ({ display, fields, label }) => {
  const deleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const index = event.currentTarget.getAttribute('data-index');

    if (index !== null) {
      fields.remove(parseInt(index, 10));
    }
  };

  const handleAddOnClick = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const text = await navigator.clipboard.readText();

    try {
      const url = new URL(text);
      fields.push(url.href);
    } catch (e) {
      toast.error("le texte n'est pas un lien");
    }
  };

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      display={display}
      title={label ?? 'Images'}
    >
      <div className="flex flex-wrap justify-center">
        {fields.map((field, index, datas) => {
          const src = datas.get(index);

          return (
            <Div
              key={slug(field)}
              className="flex justify-center items-center w-[100px] h-[100px] bg-light-500 m-2 relative"
            >
              <img
                alt={src}
                className="max-w-[100px] max-h-[100px]"
                // className=""
                src={src}
              />

              <div className="hover-overlay">
                <div>
                  <Button
                    color="light"
                    datas={{ 'data-index': index }}
                    iconLeft={IconDelete}
                    onClick={deleteOnClick}
                    variant="link"
                  />
                </div>
              </div>
            </Div>
          );
        })}
      </div>
    </HOCGroup>
  );
};

const DataImages: FC<DataImagesProps> = function ({ name, ...props }) {
  // const images: string[] | undefined = useSelector(
  //   (state: ReducersProps<any>) =>
  //     state.form[formName].values && state.form[formName].values[name],
  // );
  //
  // if (!images || 0 === images.length) {
  //   return null;
  // }

  return <FieldArray component={DataImagesRender} name={name} props={props} />;
};

export default DataImages;
