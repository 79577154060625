import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataSchemasListProps } from '~/datas/props';

import DataSchemasListRender from './Render';

const DataSchemasList: FC<DataSchemasListProps> = props => {
  const { name } = props;

  return (
    <FieldArray component={DataSchemasListRender} name={name} props={props} />
  );
};

export default DataSchemasList;
