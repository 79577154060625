import React, { ReactElement } from 'react';
import { FieldArray } from 'redux-form';

import { DataDeliveryRulesProps } from '~/datas/props';

import DataDeliveryRulesRender from './Render';

const DataDeliveryRules = (
  props: DataDeliveryRulesProps,
): ReactElement | null => (
  <FieldArray
    component={DataDeliveryRulesRender}
    name={props.name}
    props={props}
  />
);

export default DataDeliveryRules;
