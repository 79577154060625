import { Data } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import IconDelete from '~/icons/Delete';

interface DataEmailSectionColumnsItemProps {
  collectionName: string;
  deleteOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  docId: string;
  espaceId: string;
  formName: string;
  index: number;
  name: string;
}

const DataEmailSectionColumnsItem: FC<DataEmailSectionColumnsItemProps> =
  function ({
    collectionName,
    deleteOnClick,
    docId,
    espaceId,
    formName,
    index,
    name,
  }) {
    return (
      <div className="flex-1">
        <Data
          componentType="contents/crud"
          customInfos={
            <Button
              datas={{
                'data-index': index,
              }}
              iconLeft={IconDelete}
              onClick={deleteOnClick}
              variant="sub-action"
            />
          }
          display="ghost"
          formName={formName}
          name={`${name}.contents`}
          params={{
            espaceId,
            parentCollectionName: collectionName,
            parentField: `${name}.contents`,
            parentId: docId,
          }}
          title="Contenus"
          titleProps={{
            className: 'text-transparent mb-3',
          }}
        />
      </div>
    );
  };

export default DataEmailSectionColumnsItem;
