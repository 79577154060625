import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconNote: FC<IconType> = ({ className, color, onClick }) => (
  <svg
    className={className}
    height="100%"
    onClick={onClick}
    viewBox="0 0 448 512"
    width="100%"
  >
    <path
      d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V320H336c-26.5 0-48 21.5-48 48V480H64c-35.3 0-64-28.7-64-64V96zM402.7 352H448l-32 32-64 64-32 32V434.7 368c0-8.8 7.2-16 16-16h66.7zM112 376a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM88 112a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm24 144a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconNote;
