import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconTail: FC<IconType> = ({ className, color, onClick }) => (
  <svg
    className={className}
    fill="none"
    height="21"
    onClick={onClick}
    viewBox="0 0 17 21"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.112173 20.1846C5.31217 20.9846 10.4455 18.1212 12.1122 16.2879C10.3943 12.1914 21.0001 2.24186 14.0001 2.24148C12.3815 2.24148 10.999 -1.9986 5.11217 1.1846C5.09096 2.47144 5.11217 6.92582 5.11217 7.6842C5.11217 18.1842 -0.887827 19.5813 0.112173 20.1846Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconTail;
