import { SubscriptionType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Subscription extends ModelEspace<SubscriptionType> {
  constructor(
    props: Omit<ModelEspaceProps<SubscriptionType>, 'collectionName'>,
  ) {
    super({
      ...props,
      addButtonLabel: 'Créer un nouvel abonnement',
      collectionName: 'abonnements',
      orderDirection: props.orderDirection || 'desc',
      orderField: props.orderField || 'createdAt',
    });
  }
}

export default Subscription;
