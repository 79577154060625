import { DocumentType, ProduitType } from '@innedit/innedit-type';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { Box, DataProps } from 'packages/formidable';
import { GroupBody, GroupTitle } from 'packages/formidable/components/Group';
import { ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '~/components/Button';
import Media from '~/components/Media';
import displayCurrency from '~/utils/displayCurrency';

export interface DataParentProductProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  parentId: string;
}

const DataParentProduct: FC<DataParentProductProps> = ({
  espaceId,
  params,
  parentId,
}) => {
  const { t } = useTranslation();
  const [parent, setParent] = useState<DocumentType<ProduitType>>();
  const [children, setChildren] = useState<DocumentType<ProduitType>[]>();

  const docId = params?.docId;
  useEffect(() => {
    let isMounted = true;

    const produitData = new ProduitData({
      espaceId,
    });

    let unsubChildren: () => void | undefined;
    const unsub = produitData.watchById(parentId, newDocument => {
      if (isMounted && newDocument) {
        setParent(newDocument);

        unsubChildren = produitData.watch(
          docs => {
            if (isMounted) {
              setChildren(docs);
            }
          },
          {
            wheres: {
              parent: parentId,
            },
          },
        );
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
      if (unsubChildren) {
        unsubChildren();
      }
    };
  }, [parentId]);

  const to = `/espaces/${espaceId}/produits/${parentId}/update`;

  const hasMedias = parent?.medias && parent.medias.length > 0;

  return (
    <Box>
      {parent && (
        <>
          <GroupTitle>Produit</GroupTitle>
          <GroupBody className="bg-light-500">
            <div className="grid grid-cols-3 gap-3">
              {parent.medias && parent.medias.length > 0 && (
                <div>
                  <Media
                    className="rounded"
                    media={parent.medias[0]}
                    options={{
                      height: 65,
                      width: 65,
                    }}
                  />
                </div>
              )}

              <div
                className={classnames({
                  'col-span-2': hasMedias,
                  'col-span-3': !hasMedias,
                })}
              >
                <h2 className="text-md">{parent.label}</h2>
                <p className="text-neutral-500">
                  {t('product.variants.nb', { count: parent.nbChildren })}
                </p>
                <Button to={to} variant="link">
                  Retour
                </Button>
              </div>
            </div>
          </GroupBody>

          {children && children.length > 0 && (
            <>
              <GroupTitle className="mt-6">Déclinaisons</GroupTitle>
              <GroupBody className="bg-light-500 p-0">
                {children
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((child, index) => {
                    const Info = (
                      <div className="flex flex-col">
                        <strong className="truncate">{child.label}</strong>
                        <div className="flex justify-between">
                          <span>
                            {child.price &&
                              `${child.price} ${displayCurrency(
                                child.priceCurrency,
                              )}`}
                          </span>
                          {child.hasInventory && (
                            <span>
                              {t('product.variants.qty-available', {
                                count: child.qtyAvailable,
                              })}
                            </span>
                          )}
                        </div>
                      </div>
                    );

                    return (
                      <div
                        key={child.id}
                        className={classnames('', {
                          'border-t': index !== 0,
                        })}
                      >
                        {docId === child.id && (
                          <div
                            className={classnames(
                              'bg-[#e7e9ee] px-6 py-2 font-normal text-primary-500',
                              {
                                'rounded-b-[0.5rem]':
                                  children?.length === index + 1,
                                'rounded-t-[0.5rem]': 0 === index,
                              },
                            )}
                          >
                            {Info}
                          </div>
                        )}
                        {docId !== child.id && (
                          <Link
                            className={classnames(
                              'block w-full px-6 py-2 hover:bg-neutral-100',
                              {
                                'rounded-b-[0.5rem]':
                                  children?.length === index + 1,
                                'rounded-t-[0.5rem]': 0 === index,
                              },
                            )}
                            to={`/espaces/${espaceId}/produits/${child.id}/variant`}
                          >
                            {Info}
                          </Link>
                        )}
                      </div>
                    );
                  })}
              </GroupBody>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default DataParentProduct;
