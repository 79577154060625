import { DocumentType, EspaceType } from '@innedit/innedit-type';
import { EspaceData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DataField, DataFieldProps } from '../../packages/formidable';

export type DataEspacesSelectProps = Omit<DataFieldProps, 'componentType'>;

const DataEspacesSelect: FC<DataEspacesSelectProps> = ({ name, ...props }) => {
  const [espaces, setEspaces] = useState<DocumentType<EspaceType>[]>();

  useEffect(() => {
    let isMounted = true;
    const espaceData = new EspaceData();
    espaceData
      .find()
      .then(docs => {
        if (isMounted) {
          setEspaces(docs);
        }

        return isMounted;
      })
      .catch(toast.error);

    return () => {
      if (isMounted) {
        isMounted = false;
      }
    };
  }, []);

  if (undefined === espaces || 0 === espaces.length) {
    return null;
  }

  return (
    <DataField
      {...props}
      componentType="select"
      name={name}
      options={espaces.map(espace => ({
        label: espace.label,
        value: espace.id,
      }))}
    />
  );
};

export default DataEspacesSelect;
