import styled from '@emotion/styled';
import TextareaAutosize from 'react-textarea-autosize';

import { ControlSC } from '~/utils/getControlStyle';

const TextareaControl = styled(ControlSC)`
  line-height: 1.5rem;
  padding-top: 8px;
  padding-bottom: 8px;
  box-sizing: border-box;

  resize: none;

  .has-attributes &,
  .has-button & {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Textarea = TextareaControl.withComponent(TextareaAutosize);

export default Textarea;
