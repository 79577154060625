import React, { FC, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { change, submit } from 'redux-form';

import ViewModal from '../../../components/View/Modal';
import IconDelete from '../../../icons/Delete';
import IconSave from '../../../icons/Save';
import { Form } from '../../../packages/formidable';
import modalDatas from './modalDatas.json';

interface ModalProps {
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  deleteOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  field: any;
  index: number;
  name: string;
}

const Modal: FC<ModalProps> = ({
  closeOnClick,
  deleteOnClick,
  formName,
  field,
  index,
  name,
}) => {
  const dispatch = useDispatch();

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const ok = window.confirm(
      'Voulez-vous vraiment supprimer cette dimension ?',
    );

    if (ok) {
      deleteOnClick(event);

      closeOnClick();
    }
  };

  const handleOnSubmit = async (values: any) => {
    dispatch(change(formName, `${name}[${index}]`, values));

    closeOnClick();
  };

  const handleSubmitFormOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    dispatch(submit('dimension'));
  };

  return (
    <ViewModal
      buttons={{
        left: [
          {
            datas: {
              'data-index': index,
            },
            icon: IconDelete,
            label: 'Supprimer',
            onClick: handleDeleteOnClick,
          },
        ],
        right: [
          {
            label: 'Fermer',
            onClick: closeOnClick,
          },
          {
            label: 'Enregistrer et fermer',
            onClick: handleSubmitFormOnClick,
          },
        ],
      }}
      closeOnClick={closeOnClick}
      title="Dimension spécifique"
    >
      <Form
        datas={modalDatas}
        hideSubmitButton
        initialValues={field}
        name="dimension"
        onSubmit={handleOnSubmit}
        submitProps={{
          className: 'flex flex-row justify-center items-center space-x-1.5',
          iconRight: IconSave,
          label: 'Enregistrer et fermer',
        }}
      />
    </ViewModal>
  );
};

export default Modal;
