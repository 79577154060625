import { WhereProps } from 'packages/innedit';
import React, { FC } from 'react';

import { FilterType } from '~/components/Filter/types';

import FilterAction from './Action';

export interface ListTabProps {
  label: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  pathname: string;
  wheres?: WhereProps;
}

const Filter: FC<FilterType & { index: number }> = ({
  index,
  label,
  multiple,
  name,
  options,
  params,
}) => {
  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <FilterAction
      index={index}
      label={label}
      multiple={multiple}
      name={name}
      options={options}
      value={params && params[name]}
    />
  );
};

export default Filter;
