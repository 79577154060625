import { DocumentType, PriceType } from '@innedit/innedit-type';
import { PriceData as PriceModel } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, DataFieldProps } from '../../packages/formidable';
import displayCurrency from '../../utils/displayCurrency';

export interface DataPriceProps extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
}
const DataPrice: FC<DataPriceProps> = function ({
  espaceId,
  formName,
  name,
  params,
}) {
  const [price, setPrice] = useState<DocumentType<PriceType>>();
  const dataForm = params[formName];

  useEffect(() => {
    let isMounted = true;
    if (dataForm && dataForm[name]) {
      const productId = dataForm[name];
      const model = new PriceModel({ espaceId });

      model
        .findById(productId)
        .then(snapshot => {
          if (isMounted) {
            setPrice(snapshot);
          }

          return isMounted;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [dataForm, name]);

  if (!price) {
    return null;
  }

  return (
    <Box>
      {price.amount} {displayCurrency(price.currency)} {price.type}
    </Box>
  );
};

export default DataPrice;
