import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataEmailSectionsProps } from '~/datas/props';

import DataEmailSectionsRender from './Render';

const DataEmailSections: FC<DataEmailSectionsProps> = function ({
  name,
  ...props
}) {
  return (
    <FieldArray component={DataEmailSectionsRender} name={name} props={props} />
  );
};

export default DataEmailSections;
