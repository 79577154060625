import { OrderType } from '@innedit/innedit-type';
import { DataProps } from 'packages/formidable';
import { OrderData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import useOrderPayments from '~/utils/useOrderPayments';

import StyledResume from '../../components/Resume';

export interface DataOrdersResumeProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
}

const DataOrdersResume: FC<DataOrdersResumeProps> = ({ espaceId, params }) => {
  const id = params && params.docId;
  const [order, setOrder] = useState<OrderType>();
  const { paymentAmount, paymentAmountReceived, paymentAmountRefunded } =
    useOrderPayments(id);

  useEffect(() => {
    let isMounted = true;
    const orderData = new OrderData({ espaceId });
    const unsub = orderData.watchById(id, doc => {
      if (isMounted) {
        setOrder(doc);
      }
    });

    return () => {
      isMounted = false;

      if (unsub) {
        unsub();
      }
    };
  }, [id]);

  if (!order) {
    return null;
  }

  const {
    deliveryEstimation,
    deliveryMethod,
    freeShipping,
    products,
    carrierIsPaidDirectly,
  } = order;

  let { deliveryAmount } = order;

  if ('store' === deliveryMethod || freeShipping) {
    deliveryAmount = 0;
  } else if (
    undefined === deliveryAmount &&
    undefined !== deliveryEstimation &&
    deliveryEstimation >= 0
  ) {
    deliveryAmount = deliveryEstimation;
  }

  if (products) {
    const produitsAmount = OrderData.calculateProduitsAmount(products);
    const totalAmount = produitsAmount + (deliveryAmount ?? 0);
    const resteAPayer = totalAmount - (paymentAmountReceived ?? 0); // - (paymentAmountRefunded ?? 0);

    const amountRefunded =
      paymentAmount - paymentAmountReceived - paymentAmountRefunded;

    return (
      <StyledResume>
        <div className="analytics">
          <div className="total">
            <span className="label">Total</span>
            <span>{totalAmount}€</span>
          </div>
          <div className="infos">
            <h2>Détails</h2>
            <div className="datas">
              <div className="produits">
                <span>{produitsAmount}€</span>
                <span className="label">Produits</span>
              </div>
              {undefined !== paymentAmountReceived && (
                <div className="payment">
                  <span>{paymentAmountReceived}€</span>
                  <span className="label">Paiement</span>
                </div>
              )}
              {undefined !== amountRefunded && amountRefunded < 0 && (
                <div className="payment">
                  <span>{Math.abs(amountRefunded)}€</span>
                  <span className="label">Remboursement</span>
                </div>
              )}
              {'carrier' === deliveryMethod && (
                <div>
                  {!freeShipping &&
                    (deliveryAmount === undefined || deliveryAmount < 0) && (
                      <span>Sur devis</span>
                    )}
                  {!freeShipping &&
                    deliveryAmount !== undefined &&
                    0 === deliveryAmount && <span>Comprise</span>}
                  {freeShipping && <span>Offerte</span>}
                  {!freeShipping &&
                    deliveryAmount !== undefined &&
                    0 < deliveryAmount && <span>{deliveryAmount}€</span>}
                  <span className="label">
                    Livraison {!!carrierIsPaidDirectly && '(au transporteur)'}
                  </span>
                </div>
              )}
              {resteAPayer !== 0 && (
                <div className="payment">
                  <span>{resteAPayer}€</span>
                  <span className="label">Reste à payer</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div />
      </StyledResume>
    );
  }

  return null;
};

export default DataOrdersResume;
