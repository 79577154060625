import styled from '@emotion/styled';
import { ChannelType, DocumentType } from '@innedit/innedit-type';
import { DataWithChildren } from 'packages/formidable';
import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ChannelsListProps } from '~/datas/props';

const ChannelStyled = styled.div`
  div > div {
    margin-bottom: 0 !important;
  }
`;

const ChannelsList: FC<ChannelsListProps> = ({
  display,
  field = 'isVisible',
  name,
  title = "Canaux d'interactions",
}) => {
  const mounted = useRef(false);

  const [channels] = useSelector(
    (state: { cms: { channels: DocumentType<ChannelType>[] }; form: any }) => [
      state.cms.channels,
    ],
  ) as [DocumentType<ChannelType>[]];

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  if (!channels || 0 === channels.length) {
    return null;
  }

  const newChannels = channels;

  if (0 === newChannels.length) {
    return null;
  }

  return (
    <ChannelStyled>
      <DataWithChildren
        componentType="group"
        datas={newChannels.map(channel => ({
          componentType: 'input',
          description: channel.label,
          labelShow: false,
          name: `${name}.${channel.id}.${field}`,
          type: 'checkbox',
        }))}
        display={display}
        title={title}
      />
    </ChannelStyled>
  );
};

export default ChannelsList;
