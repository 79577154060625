import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconWindowMenu: FC<IconType> = ({
  className,
  color,
  onClick,
  size = 16,
}) => (
  <svg
    className={className}
    height="100%"
    onClick={onClick}
    viewBox="0 0 512 512"
    width="100%"
  >
    <path
      d="M2.159,446.27C8.238,465.807 26.469,480 48,480L464,480C490.5,480 512,458.5 512,432L512,80C512,78.344 511.916,76.707 511.752,75.094L511.024,70.329C506.547,48.461 487.187,32 464,32L48,32C21.5,32 0,53.5 0,80L0,432C0,436.969 0.756,441.762 2.159,446.27ZM208.205,80L458,80C461.3,80 464,82.7 464,86L464,426C464,429.3 461.3,432 458,432L208.205,432L208.205,80ZM160.205,80L160.205,432L54,432C50.7,432 48,429.3 48,426L48,86C48,82.7 50.7,80 54,80L160.205,80Z"
      fill={color || 'currentColor'}
    />

    <g transform="matrix(1.00455,0,0,3.85512,-2.12944,-426.045)">
      <rect
        fill={color || 'currentColor'}
        height="10.376"
        width="79.637"
        x="65.932"
        y="142.084"
      />
    </g>
    <g transform="matrix(1.00455,0,0,3.85512,-2.12944,-358.898)">
      <rect
        fill={color || 'currentColor'}
        height="10.376"
        width="79.637"
        x="65.932"
        y="142.084"
      />
    </g>
    <g transform="matrix(1.00455,0,0,3.85512,-2.12944,-291.751)">
      <rect
        fill={color || 'currentColor'}
        height="10.376"
        width="79.637"
        x="65.932"
        y="142.084"
      />
    </g>
  </svg>
);

export default IconWindowMenu;
