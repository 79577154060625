import dayjs from 'dayjs';
import { Box } from 'packages/formidable';
import { ChannelData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ChannelsDeploymentProps } from '~/datas/props';

const ChannelsDeployment: FC<ChannelsDeploymentProps> = function ({
  formName,
  params,
}) {
  const [workflowRuns, setWorkflowRuns] = useState<any[]>();
  const { docId, espaceId } = params as any;

  useEffect(() => {
    const channelData = new ChannelData({ espaceId });
    const unsub = channelData.watchWorkflowRuns(docId, setWorkflowRuns, {
      limit: 3,
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, []);

  return (
    <Box>
      <p className="text-light-800 text-center text-sm">
        Le traitement qui est éxécuté pour déploiement le site sur les serveurs
        dure environ 5 minutes. Il est donc recommandé de réaliser toutes les
        mises à jour sur le site en même temps et ensuite de déployer
        l&apos;intégralité des modifications en une seule fois.
      </p>
      {workflowRuns && workflowRuns.length > 0 && (
        <>
          <h2 className="mt-6 text-light-800 italic">
            Les dernières déploiements :
          </h2>
          <ul>
            {workflowRuns.map(workflowRun => {
              const duree = dayjs(workflowRun.updated_at).diff(
                dayjs(workflowRun.created_at),
                'second',
              );

              const minute = Math.floor(duree / 60);
              const seconde = duree % 60;

              return (
                <li key={workflowRun.id} className="flex justify-between">
                  <span>
                    {dayjs(workflowRun.created_at).format('D MMM à HH:mm')}
                  </span>
                  {'completed' === workflowRun.status && (
                    <span className="text-success-500">{`${minute}'m ${seconde}s`}</span>
                  )}
                  {'queued' === workflowRun.status && (
                    <span className="text-warning-500">
                      En cours de déploiement
                    </span>
                  )}
                  {!['queued', 'completed'].includes(workflowRun.status) && (
                    <span>{workflowRun.status}</span>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </Box>
  );
};

export default ChannelsDeployment;
