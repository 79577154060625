import React, { FC } from 'react';

import { DataField, DataFieldProps } from '../../../packages/formidable';

export type DataFieldCountryProps = Omit<DataFieldProps, 'componentType'>;
const DataFieldCountry: FC<DataFieldCountryProps> = ({ name, ...props }) => (
  <DataField
    {...props}
    componentType="select"
    name={name}
    options={[
      {
        label: 'Angleterre',
        value: 'GB',
      },
      {
        label: 'Belgique',
        value: 'BE',
      },
      {
        label: 'France',
        value: 'FR',
      },
      {
        label: 'Italie',
        value: 'IT',
      },
      {
        label: 'Pays-Bas',
        value: 'NL',
      },
      {
        label: 'Luxembourg',
        value: 'LU',
      },
    ]}
  />
);

export default DataFieldCountry;
