import { ReservationType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Reservation extends ModelEspace<ReservationType> {
  constructor(
    props: Omit<ModelEspaceProps<ReservationType>, 'collectionName'>,
  ) {
    super({
      ...props,
      collectionName: 'reservations',
      orderDirection: props.orderDirection || 'desc',
      orderField: props.orderField || 'createdAt',
    });
  }

  initialize(data?: Partial<ReservationType>): Partial<ReservationType> {
    return this.clean(data);
  }
}

export default Reservation;
