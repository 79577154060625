import { DocumentType, ProduitType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '~/components/Button';
import IconEdit from '~/icons/Edit';
import displayCurrency from '~/utils/displayCurrency';

const VariantsList: FC<{
  editOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  variants: DocumentType<ProduitType>[];
}> = ({ editOnClick, variants }) => {
  const { t } = useTranslation();

  return (
    <div>
      {variants
        ?.sort((a, b) => a.label.localeCompare(b.label))
        .map(variant => (
          <div key={variant.id} className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <strong>{variant.label}</strong>
            </div>

            <div className="flex items-center space-x-3">
              {variant.hasGlobalPricing && <span>Prix global</span>}
              {!variant.hasGlobalPricing && variant.price && (
                <span>
                  {variant.price &&
                    `${variant.price} ${displayCurrency(
                      variant.priceCurrency,
                    )}`}
                </span>
              )}

              {variant.hasInventory && (
                <span className="text-xs text-neutral-500">
                  {t('product.variants.qty-available', {
                    count: variant.qtyAvailable,
                  })}
                </span>
              )}
              <Button
                datas={{ 'data-id': variant.id }}
                iconLeft={IconEdit}
                onClick={editOnClick}
                size="sm"
                variant="link"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default VariantsList;
