import { DataConditionTestProps } from 'packages/formidable/components/Data/props';

const replaceTestParams = (
  test: DataConditionTestProps | DataConditionTestProps[],
  params: { [key: string]: any },
): DataConditionTestProps[] => {
  const newTest = !Array.isArray(test) ? [test] : test;

  return newTest.map(t => {
    let { field } = t;
    if (field) {
      Object.keys(params).forEach(paramKey => {
        const regExp = new RegExp(`{{${paramKey}}}`, 'gi');

        if (['beta', 'docId'].includes(paramKey)) {
          field = field.replace(regExp, params[paramKey] || false);
        } else {
          field = field.replace(regExp, params[paramKey]);
        }
      });
    }

    return {
      ...t,
      field,
    };
  });
};

export default replaceTestParams;
