import objectHash from 'object-hash';
import { DataProps, Group } from 'packages/formidable';
import React, { FC, SyntheticEvent, useState } from 'react';
import { Field } from 'redux-form';

import Button, { ButtonProps } from '~/components/Button';
import AttributesRender from '~/datas/Attributes/Render';
import IconClose from '~/icons/Close';
import IconPlus from '~/icons/Plus';

export interface DataAttributesProps extends Omit<DataProps, 'componentType'> {
  displayMaxWidth?: boolean;
  formName: string;
  name: string;
  type?: 'edit' | 'show';
}
const DataAttributes: FC<DataAttributesProps> = function ({
  actions,
  display,
  displayMaxWidth = false,
  label,
  name,
  formName,
  params,
  type = 'edit',
  ...props
}) {
  let attributes = params && params[formName];
  if (attributes && params.sectionName) {
    attributes = (params.sectionName as string)
      .split('.')
      .reduce((acc, segment) => {
        if (undefined === acc) {
          return undefined;
        }

        const [newSegment, i] = segment.split('[');
        let tmpAcc = acc[newSegment];
        if (i) {
          tmpAcc = tmpAcc ? tmpAcc[i.slice(0, -1)] : undefined;
        }

        return tmpAcc;
      }, attributes as { [key: string]: any });
  }
  if (attributes && name) {
    attributes = attributes[name];
  }

  const [openForm, setOpenForm] = useState<boolean>(false);

  const handleOpenFormOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenForm(o => !o);
  };

  const handleCloseFormOnClick = (
    event?: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event?.preventDefault();
    setOpenForm(false);
  };

  return (
    <Group
      customInfos={
        <>
          {actions &&
            actions.length > 0 &&
            actions.map((action: ButtonProps) => (
              <Button
                key={objectHash({ action })}
                {...action}
                variant="sub-action"
              />
            ))}
          <Button
            iconLeft={openForm ? IconClose : IconPlus}
            onClick={openForm ? handleCloseFormOnClick : handleOpenFormOnClick}
            variant="sub-action"
          />
        </>
      }
      display={display}
      title={label ?? name}
      {...props}
    >
      <div>
        <Field
          component={AttributesRender}
          name={name}
          {...props}
          formName={formName}
          openForm={openForm}
          params={params}
        />
      </div>
    </Group>
  );
};

export default DataAttributes;
