import classnames from 'classnames';
import { Data } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import Button from '~/components/Button';
import { DataAttributeDisplayProps } from '~/datas/props';
import IconDelete from '~/icons/Delete';
import IconPlus from '~/icons/Plus';

const DataAttributeDisplayRender: FC<
  WrappedFieldArrayProps & DataAttributeDisplayProps
> = function ({
  attributeType,
  className,
  fields,
  formName,
  label,
  name,
  ...props
}) {
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fields.push('');
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const index = event.currentTarget.getAttribute('data-index');

    if (index !== null) {
      fields.remove(parseInt(index, 10));
    }
  };

  return (
    <dl
      className={classnames(
        'grid grid-cols-4 rounded bg-light-500 px-3 py-1.5 text-light-800',
        className,
      )}
    >
      <dt className="flex items-start justify-between pr-6">
        <span>{label ?? name}</span>
        <Button
          iconLeft={IconPlus}
          onClick={handleAddOnClick}
          size="sm"
          style={{
            paddingTop: '4px !important',
          }}
          variant="sub-action"
        />
      </dt>
      <dd className="col-span-3">
        {fields.map((field, index) => (
          <div key={field} className="flex space-x-3">
            <Data
              componentType={attributeType}
              formName={name}
              labelShow={false}
              name={field}
              {...props}
              fieldProps={{
                className:
                  'p-0 border-t-0 border-l-0 border-r-0 rounded-none border-b border-light-600 leading-[21px] mb-[4px] bg-none bg-[transparent]',
              }}
              templateProps={{
                className: 'shadow-none',
              }}
            />
            <Button
              datas={{
                'data-index': index,
              }}
              iconLeft={IconDelete}
              onClick={handleRemoveOnClick}
              size="sm"
              variant="sub-action"
            />
          </div>
        ))}
      </dd>
    </dl>
  );
};

export default DataAttributeDisplayRender;
