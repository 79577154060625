import { Group } from 'packages/formidable';
import React, { FC, PropsWithChildren, ReactNode, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import {
  ButtonColorType,
  ButtonSizeType,
  ButtonVariantType,
} from '~/components/Button/props';
import IconPlus from '~/icons/Plus';

const HOCGroup: FC<
  PropsWithChildren<{
    addColor?: ButtonColorType;
    addIcon?: FC;
    addOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
    addSize?: ButtonSizeType;
    addVariant?: ButtonVariantType;
    bodyProps?: { [key: string]: any };
    customInfos?: ReactNode;
    description?: string;
    display?: 'inside' | 'group' | 'content' | string;
    formName?: string;
    label?: string;
    params?: { [key: string]: any };
    title?: string;
  }>
> = function ({
  addColor,
  addIcon = IconPlus,
  addOnClick,
  addSize,
  addVariant = 'link',
  bodyProps,
  children,
  customInfos,
  description,
  display,
  label,
  title,
  ...props
}) {
  return (
    <Group
      bodyProps={bodyProps}
      customInfos={
        <>
          {addOnClick && (
            <Button
              iconLeft={addIcon}
              onClick={addOnClick}
              variant="sub-action"
            />
          )}
          {customInfos}
        </>
      }
      description={description}
      display={display as any}
      title={label ?? title}
      {...props}
    >
      {children}
    </Group>
  );
};

export default HOCGroup;
