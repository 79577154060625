import { DataFieldSelectProps } from 'packages/formidable/components/Data/props';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { WrappedFieldProps } from 'redux-form';

import SelectSC from '~/components/Select';

const FieldSelect: FC<WrappedFieldProps & DataFieldSelectProps> = ({
  disabled,
  fieldProps,
  formName,
  hasEmpty = true,
  id,
  innerElement,
  input,
  options,
  placeholder,
  meta: { error, touched },
  multiple = false,
  required,
}) => {
  const { t } = useTranslation();

  if (!formName) {
    return <div>select : erreur de paramètre : formName obligatoire</div>;
  }

  if (!options) {
    return <div>select : erreur de paramètre : options obligatoire</div>;
  }

  return (
    <>
      <SelectSC
        multiple={multiple}
        {...input}
        {...fieldProps}
        disabled={disabled}
        id={id}
        required={required}
        status={touched && error ? 'error' : undefined}
      >
        <option
          aria-label={placeholder}
          disabled={Boolean(placeholder) && !hasEmpty}
          hidden={!hasEmpty}
          value=""
        >
          {placeholder ? t(placeholder) : placeholder}
        </option>
        {options.map(({ disabled: d, label, options: o, value }) => {
          if (o && o.length > 0) {
            return (
              <optgroup key={`${label}_${o.length}`} label={label}>
                {o.map(({ disabled: od, label: ol, value: ov }) => (
                  <option key={ov} disabled={od} value={ov}>
                    {t ? t(ol) : ol}
                  </option>
                ))}
              </optgroup>
            );
          }

          return (
            <option key={value} disabled={d} value={value}>
              {t ? t(label) : label}
            </option>
          );
        })}
      </SelectSC>
      {innerElement}
    </>
  );
};

export default FieldSelect;
