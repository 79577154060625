import { DocumentType, FeatureType } from '@innedit/innedit-type';
import classnames from 'classnames';
import { FeatureData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { Field, FormSection } from 'redux-form';

import MultiSelectCreate from '~/datas/Features/MultiSelect/Create';

interface FeaturesMultiSelectSubProps {
  espaceId: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: keyof FeatureType;
  name: string;
  parent: string;
}
const FeaturesMultiSelectSub: FC<FeaturesMultiSelectSubProps> = ({
  espaceId,
  orderDirection,
  orderField,
  name,
  parent,
}) => {
  const [options, setOptions] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    const feature = new FeatureData({
      espaceId,
      orderDirection,
      orderField,
    });

    const unsub: () => void = feature.watch(
      newDocs => {
        setOptions(newDocs);
      },
      {
        wheres: {
          parent,
          hidden: false,
        },
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, orderDirection, orderField, parent]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <FormSection className="ml-3" name={name}>
      {options &&
        options.map((option, index) => (
          <React.Fragment key={`${name}.${option.id}`}>
            <div
              className={classnames(
                'relative -mt-[1px] flex flex-col space-y-1 border-b border-t pl-2 pt-1',
                {
                  'bg-light-100': 0 === index % 2,
                  'bg-light-400': 1 === index % 2,
                },
              )}
            >
              <label
                className="flex flex-row content-center items-center space-x-2"
                htmlFor={`${name}.${option.id}`}
              >
                <Field
                  component="input"
                  id={`${name}.${option.id}`}
                  name={option.id}
                  type="checkbox"
                />
                <span className="truncate pr-[25px]">{option.label}</span>
              </label>
              <MultiSelectCreate espaceId={espaceId} parent={option.id} />
            </div>
            <FeaturesMultiSelectSub
              espaceId={espaceId}
              name={`${option.id}`}
              orderDirection={orderDirection}
              orderField={orderField}
              parent={option.id}
            />
          </React.Fragment>
        ))}
    </FormSection>
  );
};

export default FeaturesMultiSelectSub;
