import { ArticleType } from '@innedit/innedit-type';
import dayjs from 'dayjs';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Article extends ModelEspace<ArticleType> {
  constructor(props: Omit<ModelEspaceProps<ArticleType>, 'collectionName'>) {
    super({
      addButtonLabel: 'Créer un article',
      canDoSearch: true,
      collectionName: 'articles',
      orderDirection: props.orderDirection ?? 'desc',
      orderField: props.orderField ?? 'publishedAt',
      queryBy: 'label, content',
      ...props,
    });
  }

  public clean(
    values: Partial<ArticleType>,
    validate?: boolean,
  ): Partial<ArticleType> {
    const publishedAt = values?.publishedAt || dayjs().format('YYYY-MM-DD');

    return super.clean({
      ...values,
      publishedAt,
    });
  }
}

export default Article;
