import styled from '@emotion/styled';
import { rem, rgba } from 'polished';

import control from '~/styles/control';
import font from '~/styles/font';
import { ColorProp, getColorStyle } from '~/utils/getControlStyle';

export const FieldMessage = styled.div<{ color?: ColorProp }>`
  transform: translateY(${rem(4)});
  font-size: ${font.size.xs[0]};
  ${props => props.color && getColorStyle(props.color)};
`;

export const FormMessage = styled.div<{ color: ColorProp }>`
  text-align: center;
  width: 100%;
  ${props => getColorStyle(props.color)};
`;

export const FieldTemplate = styled.div<{ type?: string }>`
  display: flex;
  align-items: center;
  border-radius: ${control.radius.base};

  &:not([type='checkbox'], [type='radio']):focus-within {
    outline: none;
    box-shadow: 0 0 ${rem(1)} ${rem(3)}
      ${rgba(control.border.color.hover, 0.125)};
  }
`;
