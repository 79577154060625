import classnames from 'classnames';
import { customAlphabet } from 'nanoid';
import { Data, DataFieldProps } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import Button from '~/components/Button';

export interface DataCodeProps extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
}

const DataCode: FC<DataCodeProps> = function ({
  disabled,
  fieldProps,
  formName,
  name,
  ...others
}) {
  const dispatch = useDispatch();

  const handleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const token = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 12)();

    dispatch(change(formName, name, token));
  };

  return (
    <div>
      <Data
        componentType="input"
        disabled={disabled}
        formName={formName}
        {...others}
        fieldProps={{
          ...fieldProps,
          className: classnames(fieldProps?.className, {
            'rounded-r-none': !disabled,
          }),
        }}
        innerElement={
          !disabled && (
            <Button
              className="rounded-l-none border-l-0 h-[42px]"
              color="neutral"
              onClick={handleOnClick}
              size="md"
              variant="outline"
            >
              Générer
            </Button>
          )
        }
        name={name}
      />
    </div>
  );
};

export default DataCode;
