import { DocumentType, ThoughtType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import Text from './Text';

interface DataThoughtsCrudItemProps {
  onChange: () => void;
  reset?: boolean;
  submitSucceeded?: boolean;
  value: DocumentType<ThoughtType>;
}

const DataThoughtsCrudItem: FC<DataThoughtsCrudItemProps> = function ({
  onChange,
  reset,
  submitSucceeded,
  value,
}) {
  if (!value.text) {
    // S'il n'y a pas de text c'est que c'est probablement un parent, donc on n'affiche rien
    return null;
  }

  return (
    <div className="flex flex-col">
      <Text
        onChange={onChange}
        reset={reset}
        submitSucceeded={submitSucceeded}
        value={value}
      />

      {/* <div className="flex justify-end px-3 py-1"> */}
      {/*  <div className="flex space-x-2"> */}
      {/*    <Button */}
      {/*      iconLeft={IconDelete} */}
      {/*      onClick={handleDeleteOnClick} */}
      {/*      size="xs" */}
      {/*      variant="link" */}
      {/*    /> */}
      {/*  </div> */}
      {/* </div> */}
    </div>
  );
};

export default DataThoughtsCrudItem;
