import React, { FC, SyntheticEvent, useState } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import { DataProduitsDimensionsProps } from '~/datas/props';

import HOCGroup from '../../../components/Group/HOC';
import Item from './Item';
import Modal from './Modal';

const Render: FC<WrappedFieldArrayProps & DataProduitsDimensionsProps> = ({
  display,
  fields,
  formName,
  name,
}) => {
  const [index, setIndex] = useState<number | undefined>(undefined);
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const nbFields = fields.length;
    fields.push({
      label: '',
      value: '',
    });

    setIndex(nbFields);
  };

  const handleEdit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = event.currentTarget.getAttribute('data-index');

    if (i) {
      setIndex(parseInt(i, 10));
    }
  };

  const handleCloseOnClick = (event?: SyntheticEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }

    setIndex(undefined);
  };

  const handleRemove = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = event.currentTarget.getAttribute('data-index');
    if (i) {
      fields.remove(parseInt(i, 10));
    }
  };

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      display={display}
      title="Dimensions spécifiques"
    >
      {undefined !== index && (
        <Modal
          closeOnClick={handleCloseOnClick}
          deleteOnClick={handleRemove}
          field={fields.get(index)}
          formName={formName}
          index={index}
          name={name}
        />
      )}
      {0 === fields.length && <p className="m-0">Aucun dimension spécifique</p>}
      {fields.map((field, idx, f) => (
        <Item
          key={field}
          data={f.get(idx)}
          editOnClick={handleEdit}
          index={idx}
          name={field}
        />
      ))}
    </HOCGroup>
  );
};

export default Render;
