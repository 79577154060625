import objectHash from 'object-hash';
import React, { FC, SyntheticEvent } from 'react';

interface RadioProps {
  checked?: boolean;
  label: string;
  name: string;
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  value: string;
}

const Radio: FC<RadioProps> = ({ checked, label, name, onChange, value }) => {
  const id = objectHash({ label, value });

  return (
    <label className="flex space-x-3 items-center" htmlFor={id}>
      <input
        checked={checked}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <span>{label}</span>
    </label>
  );
};

export default Radio;
