export const IS_MOBILE = 'IS_MOBILE';
export const IS_WEBAPP = 'IS_WEBAPP';

export const USER_LOAD_REQUEST = 'USER_LOAD_REQUEST';
export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS';
export const USER_LOAD_ERROR = 'USER_LOAD_ERROR';
export const USER_IS_LOGOUT = 'USER_IS_LOGOUT';
export const USER_UNSUBSCRIBE = 'USER_UNSUBSCRIBE';

export const ESPACE_SET_CHANNELS = 'ESPACE_SET_CHANNELS';
export const ESPACE_SET_COLLECTIONS = 'ESPACE_SET_COLLECTIONS';

export const ESPACE_LOAD_REQUEST = 'ESPACE_LOAD_REQUEST';
export const ESPACE_LOAD_SUCCESS = 'ESPACE_LOAD_SUCCESS';
export const ESPACE_LOAD_ERROR = 'ESPACE_LOAD_ERROR';

export const HIDE_MENU = 'HIDE_MENU';
export const TOGGLE_MENU = 'TOGGLE_MENU';

export const USER_REMOVE = 'USER_REMOVE';

export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';

export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';

export const SET_DOCUMENT_ID = 'SET_DOCUMENT_ID';
export const SET_COLLECTION_NAME = 'SET_COLLECTION_NAME';
