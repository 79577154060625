import { DataSection } from 'packages/formidable/components/Data';
import React, { FC, SyntheticEvent, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import { DataVariantsProps } from '~/datas/props';
import IconPlus from '~/icons/Plus';

const DataVariantsRender: FC<
  WrappedFieldProps & Omit<DataVariantsProps, 'name'>
> = function ({ display, input, meta, label, ...props }) {
  const [variantValue, setVariantValue] = useState<string>();

  const options = [
    { label: 'primary', value: 'primary' },
    { label: 'secondary', value: 'secondary' },
    { label: 'tertiary', value: 'tertiary' },
    { label: 'quaternary', value: 'quaternary' },
    { label: 'light', value: 'light' },
    { label: 'dark', value: 'dark' },
    { label: 'neutral', value: 'neutral' },
    { label: 'success', value: 'success' },
    { label: 'info', value: 'info' },
    { label: 'warning', value: 'warning' },
    { label: 'danger', value: 'danger' },
  ];

  const { value } = input;

  if ('object' === typeof value) {
    Object.keys(value).forEach(key => {
      const index = options.findIndex(
        option => option?.value && option.value === key,
      );
      if (index >= 0) {
        delete options[index];
      }
    });
  }

  const handleChangeVariantValue = (
    event: SyntheticEvent<HTMLSelectElement>,
  ) => {
    event.preventDefault();
    const { value: newVariantValue } = event.currentTarget;
    setVariantValue(newVariantValue);
  };

  const handleAddVariant = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (variantValue) {
      const newValue = {
        ...value,
        [variantValue]: {
          button: {},
          color: '#ffffff',
        },
      };

      input.onChange(newValue);
    }
  };

  return (
    <HOCGroup
      customInfos={
        options.length > 0 && (
          <div className="flex items-center">
            <select
              className="h-[24px] border rounded-l border-r-0 px-2 outline-none"
              onChange={handleChangeVariantValue}
            >
              <option aria-label="Veuillez selectionner un variant" />
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Button
              className="rounded-l-none"
              iconLeft={IconPlus}
              onClick={handleAddVariant}
              variant="sub-action"
            />
          </div>
        )
      }
      display={display}
      title={label}
    >
      <DataSection
        datas={Object.keys(value).map((key, index) => ({
          className: 'border-t-0 pt-0',
          componentType: 'group',
          datas: [
            {
              componentType: 'section',
              datas: [
                {
                  componentType: 'color',
                  name: 'color',
                },
                {
                  className: 'mt-3',
                  cols: 3,
                  componentType: 'grid',
                  datas: [
                    {
                      className:
                        Object.keys(value).length === index + 1
                          ? 'border-none pt-0 pb-2'
                          : 'border-t-0 pt-0 border-b pb-2 mb-2',
                      componentType: 'attributes',
                      display: 'inside',
                      name: 'button',
                    },
                    {
                      className:
                        Object.keys(value).length === index + 1
                          ? 'border-none pt-0 pb-2'
                          : 'border-t-0 pt-0 border-b pb-2 mb-2',
                      componentType: 'attributes',
                      display: 'inside',
                      name: 'box',
                    },
                    {
                      className:
                        Object.keys(value).length === index + 1
                          ? 'border-none pt-0 pb-2'
                          : 'border-t-0 pt-0 border-b  pb-2 mb-2',
                      componentType: 'attributes',
                      display: 'inside',
                      name: 'form',
                    },
                  ],
                },
              ],
              name: key,
            },
          ],
          display: 'inside',
          title: key,
        }))}
        name={input.name}
        {...props}
      />
    </HOCGroup>
  );
};

export default DataVariantsRender;
