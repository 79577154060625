import { CollectionType, DocumentType } from '@innedit/innedit-type';
import {
  CollectionData,
  DocumentData,
  GenericData,
  ModelData,
} from 'packages/innedit';
import React, { useEffect, useState, VFC } from 'react';
import { toast } from 'react-toastify';

import { DataField, DataFieldProps } from '../../../packages/formidable';

export interface DataDocumentsDataListProps
  extends Omit<DataFieldProps, 'componentType'> {
  collectionId: string;
  espaceId: string;
}

const DataDocumentsDataList: VFC<DataDocumentsDataListProps> = ({
  collectionId,
  espaceId,
  name,
  ...props
}) => {
  const [collection, setCollection] = useState<DocumentType<CollectionType>>();

  const [model, setModel] = useState<ModelData<any>>();
  const [options, setOptions] = useState<DocumentType<any>[]>();

  useEffect(() => {
    if ('collections/' === collectionId.slice(0, 'collections/'.length)) {
      const id = collectionId.slice('collections/'.length);
      if (id) {
        const collectionData = new CollectionData();
        collectionData
          .findById(id)
          .then(document => {
            if (document) {
              const newModel = new DocumentData({
                ...document,
                parentCollectionName: 'collections',
                parentId: id,
              });

              setCollection(document);
              setModel(newModel);
            }

            return true;
          })
          .catch(toast.error);
      }
    } else {
      const genericData = new GenericData({
        espaceId,
        collectionName: collectionId,
      });

      setModel(genericData);
    }
  }, [collectionId]);

  useEffect(() => {
    let isMounted = true;
    if (model) {
      model
        .find()
        .then(documents => {
          if (isMounted) {
            setOptions(documents);
          }

          return false;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [model]);

  if (!options) {
    return null;
  }

  return (
    <div>
      <DataField
        {...props}
        componentType="input"
        list={`options_${collectionId}`}
        name={name}
      />
      <datalist id={`options_${collectionId}`}>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {collection &&
            collection.labelFields &&
            collection.labelFields.length > 0
              ? option[collection.labelFields[0]]
              : option.label}
          </option>
        ))}
      </datalist>
    </div>
  );
};

export default DataDocumentsDataList;
