import dayjs from 'dayjs';
import compact from 'lodash/compact';
import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '~/components/Button';
import { calcEnd } from '~/components/Slots/Item';
import IconEdit from '~/icons/Edit';

import ItemEditing from './ItemEditing';

export interface DataProductSlotsItemProps {
  data?: any;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}

const DataProductSlotsItem: FC<DataProductSlotsItemProps> = function ({
  data,
  deleteOnClick,
  index,
  name,
}) {
  const [isEditing, setIsEditing] = useState<boolean>(!data.startDate);

  const handleToggleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsEditing(p => !p);
  };

  const { duration, startDate, startTime } = data as {
    bookableFrom?: string;
    bookableUpTo?: string;
    duration: string;
    startDate: string;
    startTime: string;
  };

  if (isEditing) {
    return (
      <ItemEditing
        closeOnClick={handleToggleOnClick}
        deleteOnClick={deleteOnClick}
        index={index}
        name={name}
      />
    );
  }

  const info = (
    <div className="flex-1 flex justify-between">
      {startTime && (
        <span>
          {compact([startTime, calcEnd(startTime, duration)]).join(' - ')}
        </span>
      )}

      <Button
        color="neutral"
        iconLeft={IconEdit}
        onClick={handleToggleOnClick}
        size="sm"
        variant="link"
      />
    </div>
  );

  return (
    <div className="flex flex-col flex-1">
      <div className="text-left flex space-x-3 items-center">
        <strong>
          {startDate ? dayjs(startDate).format('dddd D MMM YYYY') : '<date>'}
        </strong>
      </div>
      {info}
    </div>
  );

  // return (
  //   <div className="flex flex-col min-h-12 justify-between items-start border p-1.5 rounded bg-light-300 space-y-2">
  //     <div className="flex justify-between w-full">
  //       <span className="font-normal">{startDate}</span>
  //       <Button
  //         color="neutral"
  //         iconLeft={IconEdit}
  //         onClick={handleToggleOnClick}
  //         size="xs"
  //         variant="outline"
  //       />
  //     </div>
  //   </div>
  // );
};

export default DataProductSlotsItem;
