import dayjs from 'dayjs';
import FirebaseFirestore, {
  getDocs,
  limit,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import { firestore } from 'packages/innedit/config/firebase';

import client from '../../config/typesense';
import Model, { ModelProps } from '../Model';

export interface ModelDocumentProps extends ModelProps<any> {
  parentCollectionName: string;
  parentId: string;
}

class Document extends Model<any> {
  public parentCollectionName: string;
  public parentId: string;

  constructor(props: Omit<ModelDocumentProps, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'documents',
      ...props,
    });

    this.parentCollectionName = props.parentCollectionName;
    this.parentId = props.parentId;
  }

  public async resetIndex(): Promise<void> {
    const filterBys = [
      `parentCollectionName:=${this.parentCollectionName}`,
      `parentId:=${this.parentId}`,
    ];
    if (this.espaceId) {
      filterBys.push(`espaceId:=${this.espaceId}`);
    }
    // On supprime tous les articles de cet espace de l'index et on ajout les documents existants
    await client
      .collections(this.collectionName)
      .documents()
      .delete({
        filter_by: filterBys.join(' && '),
      });
    const batch = writeBatch(firestore);

    const constaints: FirebaseFirestore.QueryConstraint[] = [
      where('deleted', '==', false),
      where('parentCollectionName', '==', this.parentCollectionName),
      where('parentId', '==', this.parentId),
    ];

    if (this.espaceId) {
      constaints.push(where('espaceId', '==', this.espaceId));
    }

    // TODO traiter tous les documents - la limite de 500 est liée au batch
    constaints.push(limit(500));
    const q = query(this.getCollectionRef(), ...constaints);

    const querySnapshot = await getDocs(q);
    if (querySnapshot.size > 0) {
      const date = dayjs().toISOString();
      querySnapshot.docs.forEach(doc => {
        batch.update(doc.ref, {
          updatedAt: date,
        } as any);
      });

      await batch.commit();
    }
  }
}

export default Document;
