import styled from '@emotion/styled';
import {
  DocumentType,
  ExtractType,
  ThoughtType,
  UserType,
} from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import {
  ExtractData,
  Facia,
  TaskData,
  ThoughtData,
  UserData,
} from 'packages/innedit';
import { rem, rgba } from 'polished';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import slug from 'slug';

import Button from '~/components/Button';
import Media from '~/components/Media';
import IconCheck from '~/icons/Check';
import IconCommentCheck from '~/icons/CommentCheck';
import IconDelete from '~/icons/Delete';
import IconDownload from '~/icons/Download';
import IconListCheck from '~/icons/Tasks';
import control from '~/styles/control';
import { colors } from '~/styles/theme';

const Box = styled.div`
  &.has-textarea:focus-within {
    border-color: ${colors.dark['200']};
    // box-shadow: 0 0 ${rem(1)} ${rem(3)}
    //   ${rgba(control.border.color.hover, 0.125)};
    background: ${control.bg.color.focus};
  }
`;
const Textarea = styled.textarea`
  resize: none;
  outline: none;
  background: transparent;
  width: 100%;
  line-height: 24px;
  display: flex;
`.withComponent(TextareaAutosize);

interface CommentsItemProps {
  value: DocumentType<ThoughtType>;
}

const CommentsItem: FC<CommentsItemProps> = function ({ value }) {
  const [text, setText] = useState<string | undefined>(value.text);
  const [extracts, setExtracts] = useState<DocumentType<ExtractType>[]>();
  const [user, setUser] = useState<DocumentType<UserType>>();

  const txt = text?.replace(/https?:\/\/[\n\S]+/g, '');
  const urls = text?.match(/https?:\/\/[\n\S]+/g);

  const { createdByUser } = value;

  const textChanged = value.text && text ? text?.localeCompare(value.text) : 0;

  useEffect(() => {
    let isMounted = true;
    const extractModel = new ExtractData({
      parentCollectionName: 'comments',
      parentId: value.id,
    });

    extractModel.watch(docs => {
      if (isMounted) {
        setExtracts(docs);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [value.id]);

  useEffect(() => {
    // On recherche l'utilisateur
    const userData = new UserData();

    if (createdByUser) {
      userData
        .findByUid(createdByUser)
        .then(newUser => setUser(newUser))
        .catch(error => toast.error(error.message));
    }
  }, [createdByUser]);

  const commentData = new ThoughtData({
    espaceId: value.espaceId,
    parentCollectionName: value.parentCollectionName,
    parentField: 'comments',
    parentId: value.parentId,
  });

  const handleResolveOnClick = () => {
    toast.promise(
      commentData.update(value.id, {
        isResolved: true,
      }),
      {
        error: 'Problème lors de la mise à jour du commentaire',
        pending: 'Action en cours',
        success: 'Commentaire résolu',
      },
    );
  };

  const handleCreateTaskOnClick = () => {
    const taskData = new TaskData({
      espaceId: value.espaceId,
    });

    toast.promise(
      taskData.create({
        label: value.text,
      }),
      {
        error: 'Problème lors de la création de la tâche',
        pending: 'En cours de création',
        success: 'Création de la tâche réussie',
      },
    );
  };

  const handleOnChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  const handleExtractOnClick = () => {
    if (urls && urls.length > 0) {
      const promises = urls.map(url =>
        Facia.call({
          kind: 'extract',
          params: {
            espaceId: value.espaceId,
            parentCollectionName: 'comments',
            parentField: 'extracts',
            parentId: value.id,
          },
          q: url,
        }),
      );

      toast.promise(Promise.all(promises), {
        error: 'Erreur lors du téléchargement',
        pending: 'En attendant de téléchargement',
        success: 'Téléchargement réussi',
      });
    }
  };

  const handleDeleteOnClick = async () => {
    // TODO Ajouter une validation
    await commentData.delete(value.id);
  };

  const handleSubmitOnClick = async () => {
    await commentData.update(value.id, {
      text,
    });
  };

  const shouldBeDownloaded =
    urls && urls.length > 0 && (!extracts || 0 === extracts.length);

  return (
    <div>
      {user && <span className="">{`${user.firstName} ${user.lastName}`}</span>}
      <Box
        className={classnames(
          'rounded bg-transparent focus-within:bg-neutral-50',
          {
            'has-textarea': txt,
          },
        )}
      >
        {txt && (
          <div className="border-l-2 px-3 py-1">
            <Textarea
              className="w-full bg-transparent leading-6 outline-none"
              onChange={handleOnChange}
              value={text}
            />
          </div>
        )}

        {shouldBeDownloaded && urls && urls.length > 0 && (
          <ul className="flex flex-col items-center py-1 text-center text-[12px]">
            {urls.map(u => {
              const [, , domain] = u.split('/');

              return <li key={slug(u)}>{domain}</li>;
            })}
          </ul>
        )}

        {extracts && extracts.length > 0 && (
          <div>
            {extracts.map(extract => {
              const [, , domain] = extract.href.split('/');

              return (
                <div key={extract.id} className="flex flex-col space-y-1">
                  <a
                    className="mx-2 mt-1 items-center text-center text-[12px] text-[#5f6368]"
                    href={extract.href}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {domain}
                  </a>
                  {extract.thumbnails && extract.thumbnails.length > 0 && (
                    <div>
                      {extract.thumbnails.map(media => (
                        <Media key={media.id} media={media} />
                      ))}
                    </div>
                  )}
                  <h2 className="mx-2 mt-2 text-center text-[14px]">
                    {extract.label}
                  </h2>
                  <p className="mx-2 mt-1 text-[12px]">{extract.description}</p>
                </div>
              );
            })}
          </div>
        )}
      </Box>
      <div className="flex justify-between py-1">
        <div className="flex space-x-1 text-[10px] text-light-800">
          <span>{dayjs(value.createdAt).format('DD/MM/YYYY à HH:mm')}</span>
          {value.createdAt !== value.updatedAt && <span>édité</span>}
        </div>

        <div className="flex space-x-2">
          {shouldBeDownloaded && (
            <Button
              iconLeft={IconDownload}
              onClick={handleExtractOnClick}
              size="xs"
              title={"Extraire l'URL"}
              variant="link"
            />
          )}

          <Button
            iconLeft={IconCommentCheck}
            onClick={handleResolveOnClick}
            size="xs"
            title="Marquer comme résolu"
            variant="link"
          />
          <Button
            iconLeft={IconListCheck}
            onClick={handleCreateTaskOnClick}
            size="xs"
            title="Créer une tâche"
            variant="link"
          />
          {textChanged !== 0 && (
            <Button
              iconLeft={IconCheck}
              onClick={handleSubmitOnClick}
              size="xs"
              title="Enregistrer les modifications"
              variant="link"
            />
          )}

          <Button
            iconLeft={IconDelete}
            onClick={handleDeleteOnClick}
            size="xs"
            title="Supprimer le commentaire"
            variant="link"
          />
        </div>
      </div>
    </div>
  );
};

export default CommentsItem;
