import { ContentType, DocumentType, ModelType } from '@innedit/innedit-type';
import { mergeWith } from 'lodash';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

export interface ModelEspaceContentProps<T extends ModelType>
  extends ModelEspaceProps<T> {
  parentCollectionName: string;
  parentField?: string;
  parentId: string;
}

class Content extends ModelEspace<ContentType> {
  public parentField: string;

  constructor(
    props: Omit<ModelEspaceContentProps<ContentType>, 'collectionName'>,
  ) {
    super({
      ...props,
      collectionName: 'contents',
      orderDirection: props.orderDirection || 'asc',
      orderField: props.orderField || 'order',
    });

    this.parentField = props.parentField || 'contents';

    // on ajout les conditions au wheres
    mergeWith(this.wheres, {
      parentField: this.parentField,
    });
  }

  public async create(
    data: Partial<ContentType>,
    id?: string,
  ): Promise<DocumentType<ContentType>> {
    return super.create({
      ...data,
      parentCollectionName: this.parentCollectionName,
      parentField: this.parentField,
      parentId: this.parentId,
    });
  }

  public initialize(data?: Partial<ContentType>): Partial<ContentType> {
    return super.initialize({
      kind: 'standard',
      medias: [],
      order: 0,
      parentCollectionName: this.parentCollectionName,
      parentField: this.parentField,
      parentId: this.parentId,
      ...data,
    });
  }

  public clean(
    values?: Partial<ContentType>,
    validate?: boolean,
  ): Partial<ContentType> {
    const newValues = {
      ...values,
      nbDocsPerPage: values?.nbDocsPerPage ?? 30,
    };

    return super.clean(newValues, validate);
  }
}

export default Content;
