import { BannerType } from '@innedit/innedit-type';

import ModelChannel, { ModelChannelProps } from '../../../Model/Channel';

class BannerData extends ModelChannel<BannerType> {
  constructor(props: Omit<ModelChannelProps<BannerType>, 'collectionName'>) {
    super({
      addButtonLabel: 'Créer une bannière',
      canDoSearch: false,
      collectionName: 'banners',
      labelFields: ['text'],
      orderDirection: 'desc',
      orderField: 'createdAt',
      queryBy: 'label',
      ...props,
    });

    this.channelId = props.channelId;
  }
}

export default BannerData;
