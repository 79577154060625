import { DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import IconDelete from '~/icons/Delete';

interface ItemEditingProps {
  closeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}
const ItemEditing: FC<ItemEditingProps> = function ({
  closeOnClick,
  deleteOnClick,
  index,
  name,
}) {
  return (
    <div className="flex flex-col border p-1.5 rounded bg-light-300">
      <div>
        <DataSection
          className="flex-1"
          datas={[
            {
              componentType: 'input',
              label: 'Date',
              name: 'startDate',
              required: true,
              templateProps: {
                className: 'flex space-x-1',
              },
              type: 'date',
              wrapperProps: {
                className: 'mb-3',
              },
            },
            {
              cols: 2,
              componentType: 'grid',
              datas: [
                {
                  componentType: 'input',
                  label: 'Heure',
                  name: 'startTime',
                  type: 'time',
                },
                {
                  componentType: 'input',
                  label: 'Durée',
                  name: 'duration',
                  type: 'time',
                },
              ],
              gap: '0.375rem',
            },
            {
              componentType: 'input',
              label: 'Réservable à partir de',
              name: 'bookableFrom',
              type: 'datetime-local',
            },
            {
              componentType: 'input',
              label: "Réservable jusqu'à",
              name: 'bookableUpTo',
              type: 'datetime-local',
            },
          ]}
          name={name}
        />
      </div>
      <div className="flex justify-around space-x-3 mt-3">
        <Button
          className="w-full"
          color="danger"
          datas={{
            'data-index': index,
          }}
          iconLeft={IconDelete}
          onClick={deleteOnClick}
          size="xs"
          variant="link"
        />
        <Button
          className="w-full"
          color="neutral"
          onClick={closeOnClick}
          size="xs"
          variant="outline"
        >
          Terminer
        </Button>
      </div>
    </div>
  );
};

export default ItemEditing;
