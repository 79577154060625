import { DataArray, DataFieldProps } from 'packages/formidable';
import React, { FC } from 'react';

export interface DataAttributeProps
  extends Omit<DataFieldProps, 'componentType'> {
  attributeType: string;
}
const DataAttribute: FC<DataAttributeProps> = function ({
  attributeType,
  name,
  formName,
  label,
  params,
  ...props
}) {
  return (
    <DataArray
      datas={[
        {
          componentType: attributeType,
          labelShow: false,
          ...props,
        },
      ]}
      formName={formName}
      label={label ?? name}
      name={name}
      params={params}
    />
  );
};

export default DataAttribute;
