import {
  DocumentType,
  ProduitType,
  SubscriptionProductType,
} from '@innedit/innedit-type';
import { Box } from 'packages/formidable';
import { ProduitData, SearchResponse } from 'packages/innedit';
import React, {
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { arrayPush } from 'redux-form';

import Button from '~/components/Button';
import Input from '~/components/Input/index';
import Popup from '~/components/Popup';
import DataSubscriptionProductHit from '~/datas/Subscription/Products/Hit';
import IconClose from '~/icons/Close';
import IconSearch from '~/icons/Search';

interface DataSubscriptionProductsSearchProps {
  espaceId: string;
  formName: string;
  name: string;
}

const DataSubscriptionProductsSearch: FC<DataSubscriptionProductsSearchProps> =
  function ({ espaceId, formName, name }) {
    const ref = useRef<HTMLDivElement>(null);
    const [q, setQ] = useState<string>('');
    const [result, setResult] =
      useState<SearchResponse<DocumentType<ProduitType>>>();
    const dispatch = useDispatch();

    const search = async () => {
      if (q) {
        const produitData = new ProduitData({ espaceId });

        const r = await produitData.search(q);
        setResult(r);
      }
    };

    const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      setQ(value);

      if ('' === value) {
        setResult(undefined);
      }
    };

    const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if ('Enter' === e.key) {
        e.preventDefault();

        search();
      }
    };

    const handleCloseOnClick = () => {
      setResult(undefined);
    };

    const handleResetOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setQ('');
      setResult(undefined);
    };

    const handleSearchOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      search();
    };

    const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const id = event.currentTarget.getAttribute('data-id');
      if (null !== id) {
        dispatch(
          arrayPush(formName, name, {
            id,
            priceId: '',
            quantity: 1,
          } as SubscriptionProductType),
        );
        setResult(undefined);
        setQ('');
      }
    };

    return (
      <Box>
        <div>
          <div ref={ref} className="flex">
            <div className="flex-1 relative">
              <Input
                className="rounded-r-none pr-[36px]"
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                value={q}
              />
              {q && (
                <Button
                  className="absolute top-[13px] right-[13px]"
                  color="tertiary"
                  iconLeft={IconClose}
                  onClick={handleResetOnClick}
                  variant="link"
                />
              )}
            </div>

            <Button
              className="rounded-l-none"
              color="neutral"
              iconLeft={IconSearch}
              onClick={handleSearchOnClick}
            />
          </div>
          {result && (
            <Popup
              isOpen
              onClose={handleCloseOnClick}
              width={ref.current?.offsetWidth}
            >
              <div className="flex flex-col space-y-2 flex-1">
                {result.hits?.map(hit => (
                  <DataSubscriptionProductHit
                    key={`product_${hit.document.id}`}
                    espaceId={espaceId}
                    hit={hit}
                    onClick={handleAddOnClick}
                  />
                ))}
                {q && 0 === result.found && (
                  <div className="flex justify-between">
                    <span>Aucun produit</span>
                    <Button
                      color="neutral"
                      onClick={handleResetOnClick}
                      variant="link"
                    >
                      Effacer
                    </Button>
                  </div>
                )}
              </div>
            </Popup>
          )}
        </div>
      </Box>
    );
  };

export default DataSubscriptionProductsSearch;
