export const alignContent = {
  patterns: {
    '*': '{{value}}',
  },
  properties: 'alignContent',
};

export const alignItems = {
  patterns: {
    '*': '{{value}}',
  },
  properties: 'alignItems',
};

export const alignSelf = {
  patterns: {
    '*': '{{value}}',
  },
  properties: 'alignSelf',
};
