import { DataProps } from 'packages/formidable';
import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import DataProductSlotsRender from './Render';

export interface DataProductSlotsProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  docId: string;
}

const DataProductSlots: FC<DataProductSlotsProps> = ({ name, ...others }) => (
  <FieldArray component={DataProductSlotsRender} name={name} props={others} />
);

// const [slots, setSlots] = useState<DocumentType<ProduitType>[]>();
//
// useEffect(() => {
//   let isMounted = true;
//
//   const productData = new ProduitData({
//     espaceId,
//   });
//
//   const unsub = productData.watch(
//     docs => {
//       if (isMounted) {
//         setSlots(docs);
//       }
//     },
//     {
//       wheres: {
//         kind: 'slot',
//         parent: docId,
//       },
//     },
//   );
//
//   return () => {
//     isMounted = false;
//     if (unsub) {
//       unsub();
//     }
//   };
// }, [docId]);
//
// const handleAddOnClick = async (event: SyntheticEvent<HTMLButtonElement>) => {
//   event.preventDefault();
//
//   const productData = new ProduitData({
//     espaceId,
//   });
//
//   const newDoc: { [key: string]: any } = productData.initialize({
//     hasGlobalPricing: true,
//     kind: 'slot',
//     parent: docId,
//   });
//
//   const slot = await productData.create(newDoc);
//
//   return navigate(`/espaces/${espaceId}/produits/${slot.id}/slot/`);
// };
//
// return (
//   <HOCGroup
//     customInfos={
//       <Button
//         iconColor={colors.light['800']}
//         iconRight={IconCalendarPlus}
//         onClick={handleAddOnClick}
//         variant="link"
//       />
//     }
//     description={description}
//     display={display}
//     title={label}
//   >
//     <div className="flex flex-col space-y-6">
//       {!slots && <p>Chargement en cours</p>}
//       {slots && 0 === slots.length && <p>Aucun créneau</p>}
//       {slots && slots.length > 0 && (
//         <div className="flex flex-col">
//           <Slots datas={slots} />
//         </div>
//       )}
//     </div>
//   </HOCGroup>
// );
export default DataProductSlots;
