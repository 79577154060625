import { MemoType } from '@innedit/innedit-type';
import dayjs from 'dayjs';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class MemoData extends ModelEspace<MemoType> {
  constructor(props: Omit<ModelEspaceProps<MemoType>, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'memos',
      orderDirection: props.orderDirection ?? 'desc',
      orderField: props.orderField ?? 'publishedAt',
      queryBy: 'label, description, hyperlink.label',
      tabs: [
        {
          itemMode: 'grid',
          label: 'Tous les mémos',
          orderDirection: 'desc',
          orderField: 'publishedAt',
          pathname: `/espaces/${props.espaceId}/memos`,
        },
        {
          label: 'En vedette',
          orderDirection: 'desc',
          orderField: 'publishedAt',
          pathname: `/espaces/${props.espaceId}/memos/vedette`,
          wheres: {
            isFeatured: true,
          },
        },
      ],
      ...props,
    });
  }

  public clean(
    values: Partial<MemoType>,
    validate?: boolean,
  ): Partial<MemoType> {
    const publishedAt = values?.publishedAt || dayjs().format('YYYY-MM-DD');

    return super.clean({
      ...values,
      publishedAt,
      isFeatured: Boolean(values.isFeatured),
    });
  }
}

export default MemoData;
