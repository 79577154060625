import { FeatureType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import Model, { ModelProps } from '../../Model';

dayjs.extend(advancedFormat);

class Feature extends Model<FeatureType> {
  constructor(props: Omit<ModelProps<FeatureType>, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'features',
      ...props,
    });
  }
}

export default Feature;
