import styled from '@emotion/styled';

import { screens } from '~/styles/theme';

export default styled.h1`
  color: #34313f;
  font-size: 17px;
  line-height: 42px;
  font-weight: 400;
  height: 42px;

  @media (min-width: ${screens.md}) {
    font-size: 24px;
    font-weight: 400;
  }

  span:after {
    content: ' ';
  }
`;
