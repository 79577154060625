import { DataProps, DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import { ItemEL } from '~/datas/Contents/Crud/Item';
import IconDelete from '~/icons/Delete';
import { spacing } from '~/styles/theme';

export interface DataDeliveryRulesItemProps {
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}

const DataDeliveryRulesItem: FC<DataDeliveryRulesItemProps> = ({
  deleteOnClick,
  index,
  name,
}) => (
  <ItemEL className="item-list flex flex-row min-h-12 border p-1 rounded bg-light-300 items-center justify-between">
    <DataSection
      className="flex-1"
      datas={[
        {
          cols: [9],
          componentType: 'grid',
          datas: [
            {
              componentType: 'input',
              labelShow: false,
              name: 'weight',
              placeholder: 'en kg',
              type: 'number',
              wrapperProps: {
                col: 2,
              },
            },
            {
              componentType: 'input',
              labelShow: false,
              name: 'coefVolume',
              placeholder: 'en m3',
              type: 'number',
              wrapperProps: {
                col: 2,
              },
            },
            {
              componentType: 'input',
              labelShow: false,
              name: 'coefWeight',
              placeholder: 'en kg',
              type: 'number',
              wrapperProps: {
                col: 2,
              },
            },
            {
              componentType: 'input',
              labelShow: false,
              name: 'pec',
              placeholder: 'en €',
              type: 'number',
              wrapperProps: {
                col: 2,
              },
            },
            {
              componentType: 'input',
              labelShow: false,
              name: 'express',
              type: 'checkbox',
              wrapperProps: {
                className: 'items-center justify-center',
              },
            },
          ],
          gap: spacing[1],
        } as DataProps,
      ]}
      name={name}
    />
    <div className="ml-1 flex justify-between text-neutral-700">
      <div className="flex space-x-3 text-xs" />
      <div className="actions justify-center items-center">
        {deleteOnClick && (
          <Button
            color="neutral"
            datas={{
              'data-index': index,
            }}
            iconLeft={IconDelete}
            onClick={deleteOnClick}
            size="xs"
            variant="outline"
          />
        )}
      </div>
    </div>
  </ItemEL>
);

export default DataDeliveryRulesItem;
