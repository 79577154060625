import { CartType } from '@innedit/innedit-type';

// import FirebaseFirestore, {
//   limit,
//   onSnapshot,
//   query,
//   startAfter,
//   where,
// } from 'firebase/firestore';
// import { mergeWith } from 'lodash';
//
// import { updateConstraints, WatchOptionsProp } from '../../functions';
import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Cart extends ModelEspace<CartType> {
  constructor(props: Omit<ModelEspaceProps<CartType>, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'carts',
      orderDirection: 'desc',
      orderField: 'createdAt',
      queryBy: 'status',
      tabs: [
        {
          itemMode: 'grid',
          label: 'Tous les paniers',
          pathname: `/espaces/${props.espaceId}/orders/carts`,
        },
      ],
      ...props,
    });
  }
}

export default Cart;
