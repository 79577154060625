import { ChannelType, DocumentType } from '@innedit/innedit-type';
import { ChannelData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DataChannelsCrudProps } from '~/datas/props';

import HOCGroup from '../../../components/Group/HOC';
import Item from './Item';

export interface FieldProps {
  label: string;
  name: string;
  type: string;
}

const DataChannelsCrud: FC<DataChannelsCrudProps> = ({
  customFields,
  display,
  nothingLabel,
  params,
  title,
}) => {
  const [docs, setDocs] = useState<DocumentType<ChannelType>[]>();

  useEffect(() => {
    let isMounted = true;
    let unsub: () => void | undefined;

    if (params?.docId) {
      const channelData = new ChannelData({
        espaceId: params.docId,
      });

      unsub = channelData.watch(newDocs => {
        if (isMounted) {
          setDocs(newDocs);
        }
      });
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [params]);

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (params?.docId) {
      const model = new ChannelData({
        espaceId: params.docId,
      });

      const data: { [key: string]: any } = {
        label: '',
      };

      if (customFields) {
        (Array.isArray(customFields) ? customFields : [customFields]).forEach(
          field => {
            data[field.name as string] = '';
          },
        );
      }

      const newDoc: { [key: string]: any } = model.initialize(data);

      model.create(newDoc).catch(error => {
        throw new Error(`Features List : handleAdd() : ${error.message}`);
      });
    } else {
      toast.error("L'espace n'existe pas");
    }
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const index = event.currentTarget.getAttribute('data-index');
    if (docs && index && params?.docId) {
      const channelData = new ChannelData({
        espaceId: params.docId,
      });

      channelData.delete(docs[parseInt(index, 10)].id);
    }
  };

  if (!params?.docId) {
    return null;
  }

  return (
    <HOCGroup addOnClick={handleAddOnClick} display={display} title={title}>
      {!docs && <p className="m-0">Chargement en cours</p>}
      {docs && 0 === docs.length ? (
        <p className="m-0">{nothingLabel || 'Aucun élément'}</p>
      ) : (
        <div className="mb-6">
          {docs?.map((doc, index) => (
            <Item
              key={doc.id || index}
              doc={doc}
              espaceId={params.docId}
              index={index}
              removeOnClick={handleRemoveOnClick}
            />
          ))}
        </div>
      )}
    </HOCGroup>
  );
};

export default DataChannelsCrud;
