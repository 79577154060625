import { ProfessionalType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Professional extends ModelEspace<ProfessionalType> {
  constructor(
    props: Omit<ModelEspaceProps<ProfessionalType>, 'collectionName'>,
  ) {
    super({
      addButtonLabel: 'Créer un professionnel',
      canDoSearch: false,
      collectionName: 'professionals',
      queryBy: [
        'firstName',
        'lastName',
        'email',
        'phone',
        'address',
        'zip',
        'city',
        'country',
      ].join(', '),
      ...props,
    });
  }
}

export default Professional;
