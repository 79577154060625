import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import { DataAssociationsProps } from '~/datas/props';

import HOCGroup from '../../components/Group/HOC';
import DataAssociation from './Item';

const DataAssociationsRender: FC<
  WrappedFieldArrayProps & Omit<DataAssociationsProps, 'name'>
> = ({ display, espaceId, fields, title }) => {
  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  if (0 === fields.length) {
    return null;
  }

  return (
    <HOCGroup display={display} title={title}>
      {fields.length > 0 && (
        <div className="flex flex-col space-y-2">
          {fields.map((field, index) => (
            <DataAssociation
              key={field}
              data={fields.get(index)}
              deleteOnClick={handleDeleteOnClick}
              espaceId={espaceId}
              index={index}
            />
          ))}
        </div>
      )}
      {0 === fields.length && <p>Aucun lien</p>}
    </HOCGroup>
  );
};

export default DataAssociationsRender;
