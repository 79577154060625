import { DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import ButtonGroup from '~/components/Button/Group';
import { ItemEL } from '~/datas/Contents/Crud/Item';
import IconArrowDown from '~/icons/ArrowDown';
import IconArrowUp from '~/icons/ArrowUp';
import IconDelete from '~/icons/Delete';

export interface DataMenuItemProps {
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  downOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  index: number;
  name: string;
  params?: { [key: string]: any };
  upOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}
const DataMenuItem: FC<DataMenuItemProps> = ({
  deleteOnClick,
  downOnClick,
  formName,
  index,
  name,
  params,
  upOnClick,
}) => {
  const completName = params?.sectionName
    ? `${params.sectionName}.${name}`
    : name;

  return (
    <ItemEL className="item-list flex min-h-12 flex-row items-center justify-between rounded border bg-light-300 p-1">
      <DataSection
        datas={[
          {
            componentType: 'flex',
            datas: [
              {
                componentType: 'select',
                labelShow: false,
                name: 'type',
                options: [
                  {
                    label: 'Lien',
                    value: 'link',
                  },
                  {
                    label: 'Page',
                    value: 'page',
                  },
                ],
                wrapperProps: {
                  className: 'has-attributes',
                  flex: '1 1 30%',
                },
              },
              {
                componentType: 'condition',
                datas: [
                  {
                    componentType: 'input',
                    fieldProps: {
                      className: 'rounded-none',
                    },
                    labelShow: false,
                    name: 'label',
                    placeholder: 'Libellé',
                  },
                  {
                    componentType: 'input',
                    fieldProps: {
                      className: 'border-l-0 rounded-l-none',
                    },
                    labelShow: false,
                    name: 'href',
                    placeholder: 'Lien',
                  },
                ],
                flex: '1 1 100%',
                flexDirection: 'row',
                test: {
                  field: `${completName}.type`,
                  operator: '==',
                  value: 'link',
                },
                type: 'flex',
              },
              {
                componentType: 'condition',
                datas: [
                  {
                    componentType: 'pages/select',
                    fieldProps: {
                      className: 'rounded-l-none',
                    },
                    labelShow: false,
                    name: 'pageId',
                  },
                ],
                test: {
                  field: `${completName}.type`,
                  operator: '==',
                  value: 'page',
                },
              },
            ],
            flexDirection: 'row',
          },
        ]}
        formName={formName}
        name={name}
        params={params}
      />
      <div className="ml-1 flex justify-between text-neutral-700">
        <div className="flex space-x-3 text-xs" />
        <div className="actions items-center justify-center">
          <ButtonGroup>
            <Button
              color="neutral"
              datas={{
                'data-index': index,
              }}
              iconLeft={IconArrowUp}
              onClick={upOnClick}
              size="xs"
              variant="outline"
            />
            <Button
              color="neutral"
              datas={{
                'data-index': index,
              }}
              iconLeft={IconArrowDown}
              onClick={downOnClick}
              size="xs"
              variant="outline"
            />
            {deleteOnClick && (
              <Button
                color="neutral"
                datas={{
                  'data-index': index,
                }}
                iconLeft={IconDelete}
                onClick={deleteOnClick}
                size="xs"
                variant="outline"
              />
            )}
          </ButtonGroup>
        </div>
      </div>
    </ItemEL>
  );
};

export default DataMenuItem;
