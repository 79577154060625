import { DataWithChildren } from 'packages/formidable';
import React, { FC } from 'react';

const Editable: FC<{ formName: string; params?: Record<string, any> }> =
  function ({ formName, params }) {
    const datas = [
      {
        componentType: 'textarea',
        label: 'Nom',
        name: 'contactName',
        wrapperProps: {
          col: ['full'],
        },
      },
      {
        componentType: 'input',
        label: 'Adresse',
        name: 'contactAddress',
        wrapperProps: {
          col: ['full'],
        },
      },
      {
        componentType: 'input',
        label: 'Code postal',
        name: 'contactZip',
        wrapperProps: {
          col: ['full'],
        },
      },
      {
        componentType: 'input',
        label: 'Ville',
        name: 'contactCity',
        wrapperProps: {
          col: ['full'],
        },
      },
      {
        componentType: 'field/country',
        label: 'Pays',
        name: 'contactCountry',
        wrapperProps: {
          col: ['full'],
        },
      },
      {
        componentType: 'field/phone',
        countryField: 'contactCountry',
        label: 'Téléphone',
        name: 'contactPhone',
        wrapperProps: {
          col: ['full'],
        },
      },
      {
        componentType: 'input',
        label: 'Adresse e-mail',
        name: 'contactEmail',
        wrapperProps: {
          col: ['full'],
        },
      },
    ];

    return (
      <DataWithChildren
        cols={['none', 6]}
        componentType="grid"
        datas={datas}
        formName={formName}
        params={params}
      />
    );
  };

export default Editable;
