import { DocumentType, PaymentType } from '@innedit/innedit-type';
import compact from 'lodash/compact';
import { Group } from 'packages/formidable';
import React, { FC } from 'react';

import ItemStripe from '~/datas/Paiement/Manager/Stripe';
import ItemTransfer from '~/datas/Paiement/Manager/Transfer';

interface PaymentItemProps {
  data: DocumentType<PaymentType>;
  index: number;
}
const PaymentItem: FC<PaymentItemProps> = function ({ data: payment, index }) {
  const title = compact([
    `Paiement n°${index + 1}`,
    payment.type,
    payment.paymentMethodType,
    payment.status,
  ]).join(' - ');

  return (
    <Group
      className={index > 1 ? 'mt-6' : ''}
      display={0 === index ? 'ghost' : 'inside'}
      title={title}
    >
      <dl className="grid grid-cols-3">
        {payment.amount > 0 && (
          <>
            <dt className="col-span-2">Montant du paiement</dt>
            <dd className="text-right">{payment.amount} €</dd>
          </>
        )}
        {payment.amountCapturable > 0 && (
          <>
            <dt className="col-span-2">Montant à valider</dt>
            <dd className="text-right">{payment.amountCapturable} €</dd>
          </>
        )}
        {payment.amountReceived > 0 && (
          <>
            <dt className="col-span-2">Montant reçu</dt>
            <dd className="text-right">{payment.amountReceived} €</dd>
          </>
        )}
        {payment.amountRefunded > 0 && (
          <>
            <dt className="col-span-2">Montant remboursé</dt>
            <dd className="text-right">{payment.amountRefunded} €</dd>
          </>
        )}
        {payment.processingFees != undefined && payment.processingFees > 0 && (
          <>
            <dt className="col-span-2">Frais de traitement du paiement</dt>
            <dd className="text-right">
              <span className="mr-3 text-[10px]">
                {Math.round((payment.processingFees / payment.amount) * 10000) /
                  100}
                %
              </span>
              <span>{payment.processingFees} €</span>
            </dd>
          </>
        )}
        {payment.transactionFees !== undefined && payment.transactionFees > 0 && (
          <>
            <dt className="col-span-2">Frais de gestion</dt>
            <dd className="text-right">{payment.transactionFees} €</dd>
          </>
        )}
      </dl>
      {'transfer' === payment.type && <ItemTransfer payment={payment} />}
      {'stripe' === payment.type && <ItemStripe payment={payment} />}
    </Group>
  );
};

export default PaymentItem;
