import { ModelType } from '@innedit/innedit-type';

import cms, { ReducerCMSProps } from './cms';

export interface ReducersProps<T extends ModelType = any> {
  cms: ReducerCMSProps;
  form: {
    [name: string]: {
      anyTouched?: boolean;
      fields: Record<string, any>;
      initial: Partial<T>;
      submitSucceeded?: boolean;
      submitting?: boolean;
      values: Partial<T>;
    };
  };
}

const reducers = {
  cms,
};

export default reducers;
