import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataSchemasFieldsProps } from '~/datas/props';

import DataSchemasFieldsRender from './Render';

const DataSchemasFields: FC<DataSchemasFieldsProps> = ({ name, ...others }) => (
  <FieldArray component={DataSchemasFieldsRender} name={name} props={others} />
);

export default DataSchemasFields;
