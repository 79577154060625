import { QuestionType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Question extends ModelEspace<QuestionType> {
  constructor(props: Omit<ModelEspaceProps<QuestionType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'questions',
      orderDirection: props.orderDirection || 'desc',
      orderField: props.orderField || 'createdAt',
      queryBy: 'label',
    });
  }
}

export default Question;
