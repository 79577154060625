import { DataFieldArrayProps } from 'packages/formidable/components/Data/props';
import React, { VoidFunctionComponent } from 'react';
import { FieldArray } from 'redux-form';

import DataArrayRender from './Render';

const DataArray: VoidFunctionComponent<DataFieldArrayProps> = ({
  mode,
  name,
  ...props
}) => {
  if (mode && 'creation' === mode) {
    return <div>array : {name}</div>;
  }

  return <FieldArray component={DataArrayRender} name={name} props={props} />;
};

export default DataArray;
