import { DataSection } from 'packages/formidable';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DataPathnamesProps } from '~/datas/props';
import { ReducersProps } from '~/reducers';

const DataPathnames: FC<DataPathnamesProps> = ({ formName, name }) => {
  const { t } = useTranslation();

  const { values } = useSelector(
    (state: ReducersProps<any>) => state.form[formName],
  );

  const pathnames = values && values[name];

  if (undefined === pathnames || 0 === Object.keys(pathnames).length) {
    return null;
  }

  return (
    <DataSection
      datas={Object.keys(pathnames).map(key => ({
        componentType: 'input',
        label: t(`pathnames.${key}.label`),
        name: key,
      }))}
      name={name}
    />
  );
};

export default DataPathnames;
