import { OrderType, PaymentItemType, PaymentType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { DataProps } from 'packages/formidable';
import { GenericData, OrderData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import Modal from '~/components/View/Modal';
import PaymentItem from '~/datas/Paiement/Manager/PaymentItem';
import useOrderPayments from '~/utils/useOrderPayments';

export interface DataPaiementManagerProps
  extends Omit<DataProps, 'componentType'> {
  docId: string;
  espaceId: string;
  formName: string;
}

const DataPaiementManager: FC<DataPaiementManagerProps> = ({
  display,
  docId,
  espaceId,
  formName,
  params,
}) => {
  const [type, setType] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const [open, setOpen] = useState<boolean>(false);
  const { payments, paymentAmountCapturable, paymentAmountReceived } =
    useOrderPayments(docId);

  const item: Partial<OrderType> = params && params[formName];
  let deliveryAmount = 0;
  let produitsAmount = 0;
  if (item) {
    if ('store' === item.deliveryMethod) {
      deliveryAmount = 0;
    } else if (undefined !== item.deliveryAmount && item.deliveryAmount > 0) {
      deliveryAmount = item.deliveryAmount;
    } else if (
      undefined !== item.deliveryEstimation &&
      item.deliveryEstimation >= 0
    ) {
      deliveryAmount = item.deliveryEstimation;
    }

    produitsAmount = item.products
      ? OrderData.calculateProduitsAmount(item.products)
      : 0;
  }

  const totalAmount = produitsAmount + (deliveryAmount ?? 0);
  const orderTotalAmount = totalAmount ?? 0;

  const leftToPayAmount =
    orderTotalAmount -
    (paymentAmountCapturable ?? 0) -
    (paymentAmountReceived ?? 0);

  useEffect(() => {
    setAmount(Math.abs(leftToPayAmount));
  }, [leftToPayAmount]);

  const createStripePayment = () => {
    fetch(
      `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/espaces/${espaceId}/payment_intents/upsert`,
      {
        body: JSON.stringify({ orderId: docId }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      },
    )
      .then(res => res.json())
      .then(({ code, error, message }) => {
        if (error) {
          console.error(code, message);
        }

        return !error;
      })
      .catch(() => {
        throw new Error('Problème avec stripe');
      });
  };

  const handleCreatePaymentOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (type) {
      if ('stripe' === type) {
        createStripePayment();
      } else if (amount !== undefined && amount > 0) {
        const genericData = new GenericData<PaymentType>({
          espaceId,
          collectionName: 'payments',
        });
        const today = dayjs();

        let items: PaymentItemType[] = [];
        if (item !== undefined && item.products) {
          if (payments && payments.length > 0) {
            item.products.forEach(product => {
              // On vérifie que le produit n'est pas déjà payé
              const result = payments.some(
                paiment =>
                  paiment &&
                  paiment.items &&
                  paiment.items.some(i =>
                    i.id ? i.id === product.id : i.label === product.label,
                  ),
              );
              if (!result) {
                items.push(product);
              }
            });
          } else {
            items = [...item.products];
          }
        }

        if (paymentAmountReceived > 0) {
          // il y a déjà un paiement
          items = [
            {
              id: deliveryAmount === amount ? 'carrier' : 'delta',
              isPhysical: false,
              label: deliveryAmount === amount ? 'Livraison' : 'Delta',
              price: amount,
              quantity: 1,
            },
          ];
        } else if ('carrier' === item.deliveryMethod && deliveryAmount > 0) {
          items.push({
            id: 'carrier',
            isPhysical: false,
            label: 'Livraison',
            price: deliveryAmount,
            quantity: 1,
          });
        }
        const newPayment: PaymentType = {
          amount,
          espaceId,
          items,
          amountCapturable: 0,
          amountReceived: amount,
          amountRefunded: 0,
          captureMethod: 'manual',
          createdAt: today.toISOString(),
          datetime: today.valueOf(),
          deleted: false,
          hidden: false,
          orderId: docId,
          parent: '',
          status: 'succeeded',
          type: type as 'cash' | 'transfer',
          updatedAt: today.toISOString(),
        };

        await genericData.create(newPayment);
        const orderData = new OrderData({
          espaceId,
        });
        await orderData.update(docId, {
          paidAt: today.toISOString(),
          status: 'paid',
          updatedAt: today.toISOString(),
        });
      }

      setOpen(false);
    }
  };

  const handleOpenOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
  };
  const handleCloseOnClick = () => {
    setOpen(false);
  };

  const handleTypeOnClick = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value) {
      setType(value);
    }
  };

  const handleAmountOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value) {
      setAmount(Number(value));
    }
  };

  return (
    <>
      {open && (
        <Modal closeOnClick={handleCloseOnClick} title="Mode de paiement">
          <div>
            <p className="mb-3">
              Veuillez choisir le mode de paiement que vous souhaitez créer ?
            </p>
            <label className="flex items-center space-x-2" htmlFor="type--cash">
              <input
                checked={'cash' === type}
                id="type--cash"
                name="type"
                onChange={handleTypeOnClick}
                type="radio"
                value="cash"
              />
              <span>Cash</span>
            </label>
            <label
              className="flex items-center space-x-2"
              htmlFor="type--transfer"
            >
              <input
                checked={'transfer' === type}
                id="type--transfer"
                name="type"
                onChange={handleTypeOnClick}
                type="radio"
                value="transfer"
              />
              <span>Virement</span>
            </label>
            <label
              className="flex items-center space-x-2"
              htmlFor="type--stripe"
            >
              <input
                checked={'stripe' === type}
                id="type--stripe"
                name="type"
                onChange={handleTypeOnClick}
                type="radio"
                value="stripe"
              />
              <span>Stripe</span>
            </label>
            {undefined !== type && ['cash', 'transfer'].includes(type) && (
              <>
                <p className="my-3">
                  Veuillez valider le paiement à enregistrer ?
                </p>
                <input
                  className="rounded border px-1.5 py-0.5"
                  name="amount"
                  onChange={handleAmountOnChange}
                  type="number"
                  value={amount}
                />
              </>
            )}
          </div>
          <div className="mt-6 flex justify-end">
            <Button onClick={handleCreatePaymentOnClick}>
              Créer le paiement
            </Button>
          </div>
        </Modal>
      )}
      <HOCGroup
        addOnClick={leftToPayAmount !== 0 ? handleOpenOnClick : undefined}
        display={display}
        title="Paiements"
      >
        {(!payments || 0 === payments.length) && <p>Aucun paiement</p>}
        {payments?.map((payment, index) => (
          <PaymentItem key={payment.id} data={payment} index={index} />
        ))}
      </HOCGroup>
    </>
  );
};

export default DataPaiementManager;
