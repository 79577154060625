import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconBoxArchivedSlash: FC<IconType> = ({ className, color, onClick }) => (
  <svg
    className={className}
    height="100%"
    onClick={onClick}
    version="1.1"
    viewBox="0 0 640 512"
    width="100%"
  >
    <path
      d="M96,173.353L490.184,479.191C486.869,479.723 483.466,480 480,480L160,480C124.7,480 96,451.3 96,416L96,173.353ZM157.093,160L544,160L544,416C544,429.22 539.975,441.514 533.084,451.723L280.824,256L400,256C408.8,256 416,248.8 416,240C416,231.2 408.8,224 400,224L240,224C239.862,224 239.724,224.002 239.587,224.005L157.093,160ZM64,87.772L64,64C64,46.3 78.3,32 96,32L544,32C561.7,32 576,46.3 576,64L576,96C576,113.7 561.7,128 544,128L115.849,128L64,87.772Z"
      fill={color || 'currentColor'}
    />
    <g transform="matrix(1.47979,1.14813,-0.384345,0.495368,23.4089,-154.694)">
      <rect
        fill={color || 'currentColor'}
        height="76.557"
        width="412.61"
        x="53.231"
        y="189.298"
      />
    </g>
  </svg>
);

export default IconBoxArchivedSlash;
