import { DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import IconDelete from '~/icons/Delete';

interface ItemEditingProps {
  closeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}
const ItemEditing: FC<ItemEditingProps> = function ({
  closeOnClick,
  deleteOnClick,
  index,
  name,
}) {
  return (
    <div className="flex flex-col border p-1.5 rounded bg-light-300">
      <div>
        <DataSection
          className="flex-1"
          datas={[
            {
              componentType: 'input',
              labelShow: false,
              name: 'label',
              placeholder: "Libellé de l'option",
              templateProps: {
                className: 'flex space-x-1',
              },
              wrapperProps: {
                className: 'mb-3',
              },
            },
            {
              addButton: true,
              bodyProps: {
                className: 'flex flex-1 items-center relative',
              },
              className: 'flex flex-col space-y-2',
              componentType: 'array',
              datas: [
                {
                  componentType: 'input',
                  labelShow: false,
                  placeholder: 'Valeur',
                },
              ],
              groupProps: {
                className: 'mb-3',
                display: 'ghost',
                title: "Valeurs de l'option",
              },
              name: 'values',
              removeButton: true,
            },
          ]}
          name={name}
        />
      </div>
      <div className="flex space-x-3">
        <Button
          className="w-full"
          color="danger"
          datas={{
            'data-index': index,
          }}
          iconLeft={IconDelete}
          onClick={deleteOnClick}
          size="xs"
          variant="link"
        />
        <Button
          className="w-full"
          color="neutral"
          onClick={closeOnClick}
          size="xs"
          variant="outline"
        >
          Terminer
        </Button>
      </div>
    </div>
  );
};

export default ItemEditing;
