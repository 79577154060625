import { Dispatch } from 'packages/formidable';
import { ChannelData } from 'packages/innedit';

import { ESPACE_SET_CHANNELS } from '~/constants/actionTypes2';

function findByEspaceId(espaceId: string): any {
  return (dispatch: Dispatch): any => {
    const channelData = new ChannelData({
      espaceId,
    });

    channelData
      .find({
        wheres: {
          hidden: false,
          parent: '',
        },
      })
      .then(channels =>
        dispatch({
          channels,
          type: ESPACE_SET_CHANNELS,
        }),
      )
      .catch(error => {
        console.error(error.message);
      });
  };
}

export default findByEspaceId;
