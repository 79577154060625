import { ProfileType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Profile extends ModelEspace<ProfileType> {
  constructor(props: Omit<ModelEspaceProps<ProfileType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'profiles',
      orderDirection: props.orderDirection || 'asc',
      orderField: props.orderField || 'label',
      queryBy:
        'label, email, phone, zip, city, address, country, description, taxId, vatId, urls',
    });
  }
}

export default Profile;
