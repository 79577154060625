import dayjs from 'dayjs';

export const isDate = (value: string): string | undefined =>
  value && !dayjs(value).isValid() ? 'validator.date.error' : undefined;

export const isEmail = (value: string): string | undefined =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'validator.email.error'
    : undefined;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isRequired = (value: any): undefined | string =>
  undefined !== value && value !== '' ? undefined : 'validator.required.error';

export const isTime = (value: string): string | undefined =>
  value && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(value)
    ? 'validator.time.error'
    : undefined;
