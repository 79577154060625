import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import { DataSubscriptionProductsProps } from '~/datas/props';

import Item from './Item';

const DataSubscriptionProductsRender: FC<
  WrappedFieldArrayProps & DataSubscriptionProductsProps
> = ({ fields, espaceId, formName, params }) => {
  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const index = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (undefined !== index && !Number.isNaN(index)) {
      fields.remove(index);
    }
  };

  return (
    <div className="flex flex-col space-y-1">
      {fields.length > 0 ? (
        fields.map((field, idx, products) => (
          <Item
            key={field}
            doc={products.get(idx)}
            espaceId={espaceId}
            formName={formName}
            index={idx}
            name={field}
            params={params}
            removeOnClick={handleRemoveOnClick}
          />
        ))
      ) : (
        <div style={{ margin: '1.5rem' }}>Aucun produit</div>
      )}
    </div>
  );
};

export default DataSubscriptionProductsRender;
