import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataFeaturesSelectSubProps } from '~/datas/props';

import Render from './Render';

const DataFeaturesSelectSub: FC<DataFeaturesSelectSubProps> = props => {
  const { name } = props;

  return <FieldArray component={Render} name={name} props={props} />;
};

export default DataFeaturesSelectSub;
