import { DataProps } from 'packages/formidable';
import { client, getTypesenseFilters, ProduitData } from 'packages/innedit';
import React, {
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';

import Button from '~/components/Button';
import Input from '~/components/Input';
import Popup from '~/components/Popup';
import DataIdentificationPage from '~/datas/Associations/Page';
import IconClose from '~/icons/Close';
import IconSearch from '~/icons/Search';

export type DataProductInputProps = Omit<DataProps, 'componentType'>;

const DataProductInput: FC<DataProductInputProps> = function (props) {
  const ref = useRef<HTMLDivElement>(null);
  const [q, setQ] = useState<string>();
  const [results, setResults] = useState<SearchResponse<any>[]>();

  const { espaceId } = props;

  const search = async () => {
    if (q) {
      // On recherche dans les différentes collections si un document correspond
      const filter = getTypesenseFilters({
        espaceId,
      });

      const searchRequests: { searches: any[] } = {
        searches: [],
      };

      const productData = new ProduitData({ espaceId });
      searchRequests.searches.push({
        q,
        collection: 'produits',
        query_by: productData.queryBy as string,
      });

      const commonSearchParams = {
        q,
        filter_by: filter,
      };

      const result = await client.multiSearch.perform(
        searchRequests,
        commonSearchParams,
      );

      setResults(result.results as SearchResponse<any>[]);
    }
  };

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setQ(value);

    if ('' === value) {
      setResults(undefined);
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if ('Enter' === e.key) {
      e.preventDefault();

      search();
    }
  };

  const handleCloseOnClick = () => {
    setResults(undefined);
  };

  const handleResetOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setQ('');
    setResults(undefined);
  };

  const handleSearchOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    search();
  };

  const handleAssociationOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    // const collection = event.currentTarget.getAttribute('data-collection');
    // const id = event.currentTarget.getAttribute('data-id');
    // const label = event.currentTarget.getAttribute('data-label');
    // if (null !== collection && null !== id) {
    //   // TODO ajouter le document dans la liste des documents associés
    //   dispatch(
    //     arrayPush(formName, 'associations', {
    //       collection,
    //       id,
    //       label,
    //     }),
    //   );
    //   setResults(undefined);
    //   setQ('');
    // }
  };

  const nbResults = results?.reduce((acc, result) => acc + result.found, 0);

  return (
    <div className="col-span-full md:col-span-6">
      <div ref={ref} className="flex">
        <div className="relative flex-1">
          <Input
            className=" rounded-r-none pr-[36px]"
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            value={q}
          />
          {q && (
            <Button
              className="absolute right-[13px] top-[13px] w-[16px]"
              color="tertiary"
              iconLeft={IconClose}
              onClick={handleResetOnClick}
              variant="link"
            />
          )}
        </div>

        <Button
          className="rounded-l-none"
          color="neutral"
          iconLeft={IconSearch}
          onClick={handleSearchOnClick}
        />
      </div>

      {results && results.length > 0 && (
        <Popup
          isOpen
          onClose={handleCloseOnClick}
          width={ref.current?.offsetWidth}
        >
          <div className="flex flex-1 flex-col space-y-2">
            {results.map((result, ri) => {
              if (0 === result.found) {
                return null;
              }

              return (
                <div key={`result_${String(ri)}`}>
                  {result &&
                    result.hits?.map((hit, index) => (
                      <DataIdentificationPage
                        key={`page_${hit.document.id}`}
                        espaceId={espaceId}
                        hit={hit}
                        onClick={handleAssociationOnClick}
                      />
                    ))}
                </div>
              );
            })}
            {q && 0 === nbResults && (
              <div className="flex justify-between">
                <span>Aucun resultat</span>
                <Button
                  color="neutral"
                  onClick={handleResetOnClick}
                  variant="link"
                >
                  Effacer
                </Button>
              </div>
            )}
          </div>
        </Popup>
      )}
    </div>
  );
};

export default DataProductInput;
