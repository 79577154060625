import { PaymentType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Paiement extends ModelEspace<PaymentType> {
  constructor(props: Omit<ModelEspaceProps<PaymentType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'payments',
      labelFields: ['amount'],
    });
  }
}

export default Paiement;
