import { Dispatch } from '../../packages/formidable';
import findChannelsByEspaceId from '../channels/findByEspaceId';
import findCollectionByEspaceId from '../collection/findByEspaceId';
import findByEspaceId from './findById';

function load(id: string): any {
  return (dispatch: Dispatch): void => {
    dispatch(findByEspaceId(id));
    dispatch(findChannelsByEspaceId(id));
    dispatch(findCollectionByEspaceId(id));
  };
}

export default load;
