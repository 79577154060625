import { GroupType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Group extends ModelEspace<GroupType> {
  constructor(props: Omit<ModelEspaceProps<GroupType>, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'groups',
      orderDirection: 'asc',
      orderField: 'label',
      ...props,
    });
  }

  public initialize(data?: Partial<GroupType>): Partial<GroupType> {
    return super.initialize({
      nbChildren: 0,
      ...data,
    });
  }
}

export default Group;
