import { PriceType } from '@innedit/innedit-type';
import { TFunction } from 'react-i18next';

import displayCurrency from '~/utils/displayCurrency';

const getLabel = (price: PriceType, t: TFunction): string => {
  let label = '';

  switch (price.scheme) {
    case 'per_unit': {
      if (price.amount) {
        label += `${price.amount}`;
      } else {
        label += `Montant non renseigné`;
      }

      label += ` ${displayCurrency(price.currency ?? 'EUR')}`;
      break;
    }

    default: {
      if (price.tiers) {
        const [tier] = price.tiers.sort(
          (a: any, b: any) => b.amount - a.amount,
        );
        label += `À partir de ${tier.amount} ${displayCurrency(
          price.currency,
        )}`;
      }
    }
  }

  if (price.isRecurring) {
    label += ` / ${t(`intervals.${price.interval}`, {
      count: price.intervalCount,
    })}`;
  }

  if ('volume' === price.scheme) {
    label += ' (au volume)';
  }

  if ('graduated' === price.scheme) {
    label += ' (progressif)';
  }

  return label;
};

export default getLabel;
