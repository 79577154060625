import {
  DocumentType,
  ThoughtKindType,
  ThoughtType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import uniq from 'lodash/uniq';
import { ThoughtData } from 'packages/innedit';

const createThought = async ({
  espaceId,
  kind,
  parentCollectionName,
  parentField,
  parentId,
  text,
}: {
  espaceId?: string;
  kind?: ThoughtKindType;
  parentCollectionName?: string;
  parentField?: string;
  parentId?: string;
  text: string;
}): Promise<void> => {
  const date = dayjs().format('YYYY-MM-DD');
  const thoughtData = new ThoughtData({
    espaceId,
    parentCollectionName,
    parentField,
    parentId,
    orderDirection: 'desc',
  });

  // const texts = text.split('\n');
  let parent: undefined | DocumentType<ThoughtType>;

  // if (texts.length > 1) {
  //   // il faut créer un parent
  //   parent = await thoughtData.create(
  //     thoughtData.initialize({
  //       date,
  //     }),
  //   );
  // }

  const promises: Promise<DocumentType<ThoughtType>>[] = [];
  // texts.forEach(tmpText => {
  // const tmpText = compact(texts)
  //   .map(t => t.trim())
  //   .join('\n');
  const tmpText = text;
  if (tmpText && tmpText.trim()) {
    const txt = tmpText?.replace(/https?:\/\/[\n\S]+/g, '');

    if (txt) {
      promises.push(
        thoughtData.create(
          thoughtData.initialize({
            date,
            kind,
            parent: parent?.id ?? '',
            text: txt.trim(),
          }),
        ),
      );
    }

    const urls = tmpText.match(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    if (urls && urls.length > 0) {
      uniq(urls).forEach(url => {
        promises.push(
          thoughtData.create(
            thoughtData.initialize({
              date,
              kind,
              parent: parent?.id ?? '',
              text: url,
            }),
          ),
        );
      });
    }
  }
  // });

  await Promise.all(promises);
};

export default createThought;
