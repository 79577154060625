import { DocumentType, PaymentType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import objectHash from 'object-hash';
import { GenericData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '~/components/Button';
import Input from '~/components/Input';

interface ItemTransferSucceededProps {
  payment: DocumentType<PaymentType>;
}

const ItemTransferSucceeded: FC<ItemTransferSucceededProps> = ({ payment }) => {
  const [selectedItem, setSelectedItem] = useState<number>();
  const [amount, setAmount] = useState<number>(0);
  const [maxAmount, setMaxAmount] = useState<number>(0);

  useEffect(() => {
    setSelectedItem(undefined);
    setMaxAmount(0);
    setAmount(0);
  }, [payment]);

  const handleOnChangeItem = (event: SyntheticEvent<HTMLInputElement>) => {
    if (undefined !== event.currentTarget.getAttribute('data-index')) {
      const index = parseInt(
        event.currentTarget.getAttribute('data-index') as string,
        10,
      );

      let newAmount = 0;

      if (payment.items) {
        newAmount =
          payment.items[index].quantity * payment.items[index].price -
          (payment.items[index].refundedAmount || 0);
      }

      setAmount(newAmount);
      setMaxAmount(newAmount);
      setSelectedItem(index);
    }
  };

  const handleOnChangeAmount = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const a = Number(event.currentTarget.value);
    setAmount(Math.min(a, maxAmount));
  };

  const handleRefundOnSubmit = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const genericData = new GenericData<PaymentType>({
      collectionName: 'payments',
      espaceId: payment.espaceId,
    });
    const today = dayjs();
    if (payment && payment.items && undefined !== selectedItem) {
      const items = [...payment.items];
      const item = items[selectedItem];
      item.refundedAmount =
        undefined !== item.refundedAmount
          ? item.refundedAmount + amount
          : amount;
      item.refundedAt = today.toISOString();

      const amountRefunded = (payment.amountRefunded ?? 0) + amount;
      await genericData.update(payment.id, {
        amountRefunded,
        items,
        status:
          payment.amount === amountRefunded ? 'refunded' : 'partial_refunded',
        updatedAt: today.toISOString(),
      });
    }
  };

  return (
    <>
      <div className="mt-6 flex flex-col space-y-3">
        {payment.items?.map((item, index) => {
          const solde = item.quantity * item.price - (item.refundedAmount || 0);

          return (
            <div
              key={objectHash({ index, item })}
              className={classnames('flex flex-col', {
                'line-through': Boolean(item.canceledAt),
              })}
            >
              <div className="flex items-center space-x-3">
                <input
                  checked={selectedItem === index}
                  data-index={index}
                  disabled={
                    Boolean(item.canceledAt) ||
                    (undefined !== item.refundedAmount &&
                      item.refundedAmount > 0 &&
                      0 === solde)
                  }
                  onChange={handleOnChangeItem}
                  type="radio"
                />

                {item.thumbnail && (
                  <img alt={item.label} src={item.thumbnail} width={30} />
                )}
                <span className="overflow-hidden truncate text-ellipsis">
                  {item.label}
                </span>
                {undefined !== item.refundedAmount &&
                  item.refundedAmount > 0 &&
                  0 === solde && <span>Remboursé</span>}
                {undefined !== item.refundedAmount &&
                  item.refundedAmount > 0 &&
                  solde > 0 && (
                    <span className="whitespace-nowrap">{solde} €</span>
                  )}
                {solde > 0 && (
                  <span
                    className={classnames('whitespace-nowrap', {
                      'line-through':
                        undefined !== item.refundedAmount &&
                        item.refundedAmount > 0,
                    })}
                  >
                    {item.quantity > 1
                      ? `${item.quantity} x ${item.price} € = ${
                          item.quantity * item.price
                        } €`
                      : `${item.quantity * item.price} €`}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {undefined !== selectedItem && (
        <>
          {payment.amountReceived - payment.amountRefunded > 0 && (
            <div className="mt-6 flex flex-col">
              <h2>Remboursement</h2>
              <div className="flex">
                <Input
                  className="h-8 w-1/2 rounded-none rounded-l border-r-0"
                  max={maxAmount}
                  name="amount"
                  onChange={handleOnChangeAmount}
                  type="number"
                  value={amount}
                />
                <Button
                  className="w-1/2 rounded-none rounded-r"
                  color="neutral"
                  onClick={handleRefundOnSubmit}
                  size="sm"
                  text="Rembourser"
                  variant="groove"
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ItemTransferSucceeded;
