import { DocumentType, PaymentType } from '@innedit/innedit-type';
import classnames from 'classnames';
import objectHash from 'object-hash';
import React, { FC, SyntheticEvent } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import Professionals from '~/datas/Paiement/Manager/Professionals';

interface ItemRequiresPaymentMethodProps {
  payment: DocumentType<PaymentType>;
}

const ItemRequiresPaymentMethod: FC<ItemRequiresPaymentMethodProps> =
  function ({ payment }) {
    const handleCancelOnSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const url = `espaces/${payment.espaceId}/payment_intents/cancel?paiement=${payment.id}`;

      fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`)
        .then(r => r.json())
        .catch(toast.error);
    };

    return (
      <>
        <div className="mt-6 flex flex-col space-y-3">
          {payment.items?.map((item, index) => {
            const solde =
              item.quantity * item.price - (item.refundedAmount || 0);

            return (
              <div
                key={objectHash({ index, item })}
                className={classnames('flex flex-col', {
                  'line-through': Boolean(item.canceledAt),
                })}
              >
                <div className="flex items-center space-x-3">
                  {item.thumbnail && (
                    <img alt={item.label} src={item.thumbnail} width={30} />
                  )}
                  <span className="overflow-hidden truncate text-ellipsis">
                    {item.label}
                  </span>
                  {undefined !== item.refundedAmount &&
                    item.refundedAmount > 0 &&
                    0 === solde && <span>Remboursé</span>}
                  {undefined !== item.refundedAmount &&
                    item.refundedAmount > 0 &&
                    solde > 0 && (
                      <span className="whitespace-nowrap">{solde} €</span>
                    )}
                  {solde > 0 && (
                    <span
                      className={classnames('whitespace-nowrap', {
                        'line-through':
                          undefined !== item.refundedAmount &&
                          item.refundedAmount > 0,
                      })}
                    >
                      {item.quantity > 1
                        ? `${item.quantity} x ${item.price} € = ${
                            item.quantity * item.price
                          } €`
                        : `${item.quantity * item.price} €`}
                    </span>
                  )}
                </div>

                <Professionals
                  espaceId={payment.espaceId}
                  index={index}
                  item={item}
                  paymentId={payment.id}
                />
              </div>
            );
          })}
        </div>
        <div className="mt-6 flex justify-center space-x-3">
          <Button
            color="danger"
            onClick={handleCancelOnSubmit}
            size="sm"
            variant="outline"
          >
            Annuler le paiement
          </Button>
        </div>
      </>
    );
  };

export default ItemRequiresPaymentMethod;
