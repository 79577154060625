import { DocumentType, PriceType } from '@innedit/innedit-type';
import { DataProps, Group } from 'packages/formidable';
import { PriceData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import Modal from '~/components/View/Modal';
import Form from '~/containers/Espace/Form';
import IconPlus from '~/icons/Plus';
import price from '~/params/produit/price/index.json';

import PricesDefault from './Default';
import PricesList from './List';

export interface DataPricesCrudProps extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  display?: 'inside' | 'group';
  docCollectionName: string;
  docId: string;
  editPathname: string;
  formName: string;
  title?: string;
}

const DataPricesCrud: FC<DataPricesCrudProps> = ({
  espaceId,
  display,
  docCollectionName,
  docId,
  editPathname,
  formName,
  title,
}) => {
  const [priceId, setPriceId] = useState<string>();
  const [docs, setDocs] = useState<DocumentType<PriceType>[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    let unsub: any;

    if (docCollectionName && docId) {
      setIsLoading(true);
      const featureData = new PriceData({
        espaceId,
        orderDirection: 'desc',
        orderField: 'datetime',
        parentCollectionName: docCollectionName,
        parentId: docId,
      });

      unsub = featureData.watch(newDocs => {
        if (isMounted) {
          setDocs(newDocs);
          setIsLoading(false);
        }
      }, {});
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, docCollectionName, docId]);

  const handleAddOnClick = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const model = new PriceData({
      espaceId,
      params: price,
      parentCollectionName: docCollectionName,
      parentId: docId,
    });

    const newDoc: Partial<PriceType> = model.initialize();

    const doc = await model.create(newDoc);

    if (doc) {
      setPriceId(doc.id);
    }
  };

  const handleRemoveOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const model = new PriceData({
      espaceId,
      params: price,
      parentCollectionName: docCollectionName,
      parentId: docId,
    });

    const id = event.currentTarget.getAttribute('data-id');
    if (docs && id) {
      const doc = docs.find(d => d.id === id);
      if (doc) {
        await model.delete(doc.id);
      }
    }
  };

  const handleEditOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const id = event.currentTarget.getAttribute('data-id');

    if (id) {
      setPriceId(id);
    }
  };

  const handleCloseOnClick = () => {
    setPriceId(undefined);
  };

  // Il faut vérifier la compatibilité entre les différents prix.
  // Il ne peut pas y avoir plusieurs prix avec le même tarif / type.

  return (
    <>
      {priceId && (
        <Modal closeOnClick={handleCloseOnClick} title="Prix">
          <div className="grid grid-cols-3 gap-6">
            <div>
              <div className="sticky top-0">
                <Group
                  bodyProps={{
                    className: 'pr-1',
                  }}
                >
                  {docs && docs.length > 0 && (
                    <PricesList
                      docs={docs}
                      editOnClick={handleEditOnClick}
                      removeOnClick={handleRemoveOnClick}
                    />
                  )}
                </Group>
              </div>
            </div>
            <div className="col-span-2">
              <Form<PriceType>
                displayClose={false}
                displayComments={false}
                displayHeader={false}
                displayView={false}
                docId={priceId}
                footerProps={{
                  className: 'flex justify-end',
                }}
                hideSubmitButton
                model={
                  new PriceData({
                    espaceId,
                    params: price,
                    parentCollectionName: 'produits',
                    parentId: docId,
                  })
                }
                parentCollectionName="produits"
                parentId={docId}
                type="update"
              />
            </div>
          </div>
        </Modal>
      )}

      <HOCGroup
        customInfos={
          docId &&
          docs &&
          docs.length > 0 && (
            <Button
              iconLeft={IconPlus}
              onClick={handleAddOnClick}
              variant="sub-action"
            />
          )
        }
        display={display}
        title={docs && docs.length > 0 ? title || 'Prix' : ''}
      >
        {isLoading && <p>Chargement en cours</p>}
        <PricesDefault formName={formName} size={docs?.length ?? 0} />
        {docs && docs.length > 0 && (
          <PricesList
            docs={docs}
            editOnClick={handleEditOnClick}
            removeOnClick={handleRemoveOnClick}
          />
        )}
      </HOCGroup>
    </>
  );
};

export default DataPricesCrud;
