import { CollectionType, DocumentType } from '@innedit/innedit-type';
import { CollectionData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { DataField, DataFieldProps } from '../../packages/formidable';

export interface DataCollectionsSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  options?: { label: string; value: string }[];
}

const DataCollectionsSelect: FC<DataCollectionsSelectProps> = ({
  options,
  name,
  params,
  ...others
}) => {
  const [collectionPublics, setCollectionPublics] =
    useState<DocumentType<CollectionType>[]>();
  const [collectionPrivates, setCollectionPrivates] =
    useState<DocumentType<CollectionType>[]>();
  useEffect(() => {
    let isMounted = true;
    const collectionData = new CollectionData();

    collectionData
      .find({
        wheres: {
          hidden: false,
          visibility: 'public',
        },
      })
      .then(newDocs => {
        if (isMounted) {
          setCollectionPublics(newDocs);
        }

        return isMounted;
      })
      .catch(console.error);

    if (params.espaceId) {
      collectionData
        .find({
          wheres: {
            espaceId: params.espaceId,
            hidden: false,
            visibility: 'private',
          },
        })
        .then(newDocs => {
          if (isMounted) {
            setCollectionPrivates(newDocs);
          }

          return isMounted;
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  if (!params.espaceId) {
    return null;
  }

  let newOptions: any[] = [];
  if (options && 0 < options.length) {
    newOptions = [...options];
  }

  if (collectionPublics && 0 < collectionPublics.length) {
    newOptions.push({
      label: 'Collaborative',
      options: collectionPublics.map(collection => ({
        label: `${collection.label}`,
        value: `collections/${collection.id}`,
      })),
    });
  }

  if (collectionPrivates && 0 < collectionPrivates.length) {
    newOptions.push({
      label: 'Privée',
      options: collectionPrivates.map(collection => ({
        label: `${collection.label}`,
        value: `collections/${collection.id}`,
      })),
    });
  }

  if (0 === newOptions.length) {
    return null;
  }

  return (
    <DataField
      {...others}
      componentType="select"
      name={name}
      options={newOptions}
      params={params}
    />
  );
};

export default DataCollectionsSelect;
