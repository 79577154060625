import { ContactType, DocumentType } from '@innedit/innedit-type';
import { Link } from 'gatsby';
import React, { FC, SyntheticEvent } from 'react';
import type { SearchResponseHit } from 'typesense/lib/Typesense/Documents';

import Button from '../../components/Button';

const DataIdentificationContact: FC<{
  espaceId: string;
  hit: SearchResponseHit<DocumentType<ContactType>>;
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({ espaceId, hit, onClick }) => (
  <div className="flex items-center justify-between">
    <Link to={`/espaces/${espaceId}/contacts/${hit.document.id}/update`}>
      {hit.document.label}
    </Link>{' '}
    <Button
      color="neutral"
      datas={{
        'data-collection': 'contacts',
        'data-id': hit.document.id,
        'data-label': hit.document.label,
      }}
      onClick={onClick}
      size="xs"
      variant="ghost"
    >
      Associer
    </Button>
  </div>
);

export default DataIdentificationContact;
