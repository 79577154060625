import styled from '@emotion/styled';
import { DocumentType, ThoughtType } from '@innedit/innedit-type';
import { ThoughtData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import Actions from '~/components/Actions';
import { ActionProps } from '~/components/Actions/props';
import Button from '~/components/Button';
import Popup from '~/components/Popup';
import IconDelete from '~/icons/Delete';
import IconGripDotsVertical from '~/icons/GripDotsVertical';

const Textarea = styled.textarea`
  resize: none;
`.withComponent(TextareaAutosize);

const Text: FC<{
  onChange: () => void;
  reset?: boolean;
  submitSucceeded?: boolean;
  value: DocumentType<ThoughtType>;
}> = function ({ onChange, reset, submitSucceeded, value }) {
  const ref = useRef<HTMLDivElement>(null);

  const [textChanged, setTextChanged] = useState<boolean>(false);
  const [text, setText] = useState<string | undefined>(value.text);

  const [editing, setEditing] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  const txt = text?.replace(/https?:\/\/[\n\S]+/g, '');

  const thoughtData = new ThoughtData({
    espaceId: value.espaceId,
    parentCollectionName: value.parentCollectionName,
    parentId: value.parentId,
  });

  const handleSubmitOnClick = async () => {
    await thoughtData.update(value.id, {
      ...value,
      text,
    });
  };

  const handleDeleteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    // TODO Ajouter une validation
    await thoughtData.delete(value.id);
  };

  useEffect(() => {
    const handleMouseOver = (event: any) => {
      const isHover = Boolean(ref.current?.contains(event.target));
      setHover(isHover);

      if (!isHover && textChanged) {
        // il faut enregistrer les modifications
        thoughtData.update(value.id, {
          text,
        });
        setEditing(false);
      }
    };

    const handleClick = (event: any) => {
      if (txt) {
        setEditing(Boolean(ref.current?.contains(event.target)));
      }
    };

    document.addEventListener('click', handleClick);
    document.addEventListener('mouseover', handleMouseOver);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, [txt]);

  useEffect(() => {
    const tmp =
      value.text && text
        ? 0 !== text?.localeCompare(value.text)
        : value.text !== text;
    setTextChanged(tmp);

    if (tmp) {
      onChange();
    }
  }, [text, value.text]);

  useEffect(() => {
    if (submitSucceeded && textChanged) {
      handleSubmitOnClick();
    }
  }, [submitSucceeded, textChanged]);

  useEffect(() => {
    if (reset && textChanged) {
      setText(value.text);
    }
  }, [reset, textChanged]);

  const handleOnChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  const handleOpenActions = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
    setFocus(true);
  };

  const handleCloseOnClick = () => {
    setOpen(false);
    setFocus(false);
  };

  const buttons: ActionProps[] = [
    {
      icon: IconDelete,
      label: 'Supprimer',
      onClick: handleDeleteOnClick,
    },
  ];

  return (
    <div ref={ref} className="relative ml-3 pr-6" data-id={value.id}>
      {/* <div className="px-3 py-1 outline-offset-4"> */}
      <Textarea
        className="flex w-full bg-transparent leading-6 outline-none focus:outline-none"
        onChange={handleOnChange}
        value={text}
      />

      {(isOpen || editing || focus || hover) && (
        <div className="absolute right-1.5 top-0 flex select-none items-center justify-between">
          {(editing || focus || hover) && (
            <Button
              className="min-h-[26px] px-1 py-0"
              color="neutral"
              iconClassName="h-[20px] text-neutral-300"
              iconLeft={IconGripDotsVertical}
              onClick={handleOpenActions}
              size="md"
              variant="ghost"
            />
          )}
          <Popup
            className="right-[34px] top-0 mt-0 rounded bg-dark-500 px-3 py-1.5 shadow-none"
            isOpen={isOpen}
            onClose={handleCloseOnClick}
            width="auto"
          >
            <Actions
              className="flex flex-col"
              items={buttons}
              variant="popup"
            />
          </Popup>
        </div>
      )}
    </div>
  );
};

export default Text;
