import styled from '@emotion/styled';

import checkboxChecked from '~/images/checkbox-checked.svg';
import radio from '~/images/radio.svg';
import radioChecked from '~/images/radio-checked.svg';
import spacing from '~/styles/spacing';
import { ControlSC } from '~/utils/getControlStyle';

const InputControl = styled(ControlSC)`
  &[type='checkbox'] {
    width: auto;
  }

  &[type='radio'] {
    width: auto;
    margin-bottom: 0;
    // margin-right: ${spacing[4]};

    display: inline;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  &[type='color'] {
    height: 42px;
  }
  &[type='datetime-local'] {
    line-height: 38px;
  }

  &[type='radio'] + label:before {
    appearance: none;
    background: 0 0;
    background-size: 18px 18px !important;
    box-sizing: border-box;
    content: '';
    display: inline-block;
    height: 18px !important;
    margin-right: 10px;
    outline: 0;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 18px !important;
    z-index: 1;
  }

  &[type='radio'] + label:before {
    background-color: #afb4be;
    background-image: url(${radio});
    background-repeat: no-repeat;
    background-position: 0 0;
    border-radius: 100%;
    transition: background 0.1s ease;
  }

  &[type='radio']:checked + label:before {
    background-image: url(${radioChecked});
    background-color: #4b71fc;
    transition: background 0.2s ease;
  }

  &[type='checkbox']:not(.reverse) + label {
    margin-left: 10px;
  }
  // &[type='checkbox']:not(.reverse) + label:before {
  //   appearance: none;
  //   background: 0 0;
  //   background-size: 18px 18px !important;
  //   box-sizing: border-box;
  //   content: '';
  //   display: inline-block;
  //   height: 18px !important;
  //   margin-right: 10px;
  //   outline: 0;
  //   position: relative;
  //   top: -1px;
  //   vertical-align: middle;
  //   width: 18px !important;
  //   z-index: 1;
  // }

  // &[type='checkbox']:not(.reverse) + label:before {
  //   background: #fff !important;
  //   border: 1px solid #afb4be !important;
  //   border-radius: 2px !important;
  //   transition: all 0.1s ease;
  // }

  // &[type='checkbox']:not(.reverse):checked + label:before {
  //   background-color: #4b71fc !important;
  //   background-image: url(${checkboxChecked}) !important;
  //   background-repeat: no-repeat !important;
  //   background-position: -4px -4px !important;
  //   border-color: #4b71fc !important;
  //   transition: all 0.2s ease;
  // }

  &[type='checkbox'].reverse + label {
    margin-right: 10px;
  }

  // &[type='checkbox'].reverse + label:after {
  //   appearance: none;
  //   background: 0 0;
  //   background-size: 18px 18px !important;
  //   box-sizing: border-box;
  //   content: '';
  //   display: inline-block;
  //   height: 18px !important;
  //   margin-left: 10px;
  //   outline: 0;
  //   position: relative;
  //   top: -1px;
  //   vertical-align: middle;
  //   width: 18px !important;
  //   z-index: 1;
  // }

  // &[type='checkbox'].reverse + label:after {
  //   background: #fff !important;
  //   border: 1px solid #afb4be !important;
  //   border-radius: 2px !important;
  //   transition: all 0.1s ease;
  // }

  // &[type='checkbox'].reverse:checked + label:after {
  //   background-color: #4b71fc !important;
  //   background-image: url(${checkboxChecked}) !important;
  //   background-repeat: no-repeat !important;
  //   background-position: -4px -4px !important;
  //   border-color: #4b71fc !important;
  //   transition: all 0.2s ease;
  // }

  .has-attributes &,
  .has-button & {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Input: any = InputControl.withComponent('input');

export default Input;
