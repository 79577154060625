import classnames from 'classnames';
import { DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { arrayPush, FieldArray } from 'redux-form';

import Button from '~/components/Button';
import ButtonGroup from '~/components/Button/Group';
import DataEmailSectionColumnsRender from '~/datas/Email/Sections/Columns/Render';
import IconDelete from '~/icons/Delete';
import IconPlus from '~/icons/Plus';
import IconSort from '~/icons/Sort';

interface DataEmailSectionsItemProps {
  collectionName: string;
  deleteOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  docId: string;
  espaceId: string;
  formName: string;
  index: number;
  name: string;
}

const DataEmailSectionsItem: FC<DataEmailSectionsItemProps> = function ({
  collectionName,
  deleteOnClick,
  docId,
  espaceId,
  formName,
  index,
  name,
}) {
  const dispatch = useDispatch();

  const handleAddColumnOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(arrayPush(formName, `${name}.columns`, { label: '' }));
  };

  return (
    <div
      className={classnames('-mx-6 px-6', {
        'border-t pt-6 mt-6': index !== 0,
      })}
    >
      <div className="flex justify-center mb-6">
        <ButtonGroup>
          <Button
            color="neutral"
            iconLeft={IconPlus}
            onClick={handleAddColumnOnClick}
            size="xs"
            title="Ajouter une colonne"
            variant="outline"
          />
          <Button
            color="neutral"
            iconLeft={IconSort}
            onClick={deleteOnClick}
            size="xs"
            title={"Changer l'ordre des sections"}
            variant="outline"
          />
          <Button
            color="neutral"
            datas={{
              'data-index': index,
            }}
            iconLeft={IconDelete}
            onClick={deleteOnClick}
            size="xs"
            title="Supprimer la section"
            variant="outline"
          />
        </ButtonGroup>
      </div>

      <DataSection
        cols={[1, 2]}
        datas={[
          {
            cols: [1, 2],
            componentType: 'grid',
            datas: [
              {
                componentType: 'input',
                label: "Couleur d'arrière plan",
                name: 'backgroundColor',
                type: 'color',
              },
              {
                componentType: 'input',
                label: 'Marges (en px)',
                name: 'padding',
              },
            ],
          },
        ]}
        name={name}
      />
      <FieldArray
        component={DataEmailSectionColumnsRender}
        name={`${name}.columns`}
        props={{ collectionName, docId, espaceId, formName }}
      />
    </div>
  );
};

export default DataEmailSectionsItem;
