import { CollectionType } from '@innedit/innedit-type';
import { DataField, DataFieldProps } from 'packages/formidable';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { ReducersProps } from '~/reducers';

export interface DataSchemasFieldsSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
}

const DataSchemasFieldsSelect: FC<DataSchemasFieldsSelectProps> = ({
  formName,
  name,
  ...props
}) => {
  const item = useSelector(
    (state: ReducersProps<CollectionType>) => state.form[formName].values,
  );

  if (!item.fields || 0 === item.fields.length) {
    return null;
  }

  return (
    <DataField
      {...props}
      componentType="select"
      formName={formName}
      name={name}
      options={[
        {
          label: 'Date de création',
          value: 'createdAt',
        },
        ...item.fields.map(field => ({
          label: field.label,
          value: field.name,
        })),
      ]}
    />
  );
};

export default DataSchemasFieldsSelect;
