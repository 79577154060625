import React, { FC } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import { DataFeaturesSelectSubProps } from '~/datas/props';

import Item from './Item';

const Render: FC<WrappedFieldArrayProps & DataFeaturesSelectSubProps> = ({
  fields,
  ...props
}) => (
  // useEffect(() => {
  //   if (0 === fields.length) {
  //     fields.push('');
  //   }
  // }, [fields]);

  <>
    <Item {...props} fields={fields} index={0} name={`${fields.name}[0]`} />
    {fields.length > 0 &&
      fields.getAll().map((field, index) => {
        if (!field) {
          return null;
        }

        return (
          <React.Fragment key={`${fields.name}[${index + 1}]`}>
            <Item
              index={index + 1}
              {...props}
              fields={fields}
              label={`Sous ${props.label} ${index + 1}`}
              name={`${fields.name}[${index + 1}]`}
              parentId={field}
            />
          </React.Fragment>
        );
      })}
  </>
);
export default Render;
