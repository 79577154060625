import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataOptionsProps } from '~/datas/props';

import DataOptionsRenderRender from './Render';

const DataOptions: FC<DataOptionsProps> = ({ name, ...others }) => (
  <FieldArray component={DataOptionsRenderRender} name={name} props={others} />
);

export default DataOptions;
