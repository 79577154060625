import { PathnameType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Pathname extends ModelEspace<PathnameType> {
  constructor(props: Omit<ModelEspaceProps<PathnameType>, 'collectionName'>) {
    super({
      ...props,
      canDoSearch: true,
      collectionName: 'pathnames',
      orderDirection: props.orderDirection || 'asc',
      orderField: props.orderField || 'value',
      queryBy: 'value',
    });
  }
}

export default Pathname;
