import classnames from 'classnames';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { toggleMenu } from '~/actions/actions';
import Button from '~/components/Button';
import { ButtonVariantType } from '~/components/Button/props';
import IconChevronLeft from '~/icons/ChevronLeft';
import IconWindowMenu from '~/icons/WindowMenu';

const ToggleMenu: FC<{ iconLeft?: FC; variant?: ButtonVariantType }> =
  function ({ iconLeft, variant }) {
    const dispatch = useDispatch();

    const handleOnClick = () => {
      dispatch(toggleMenu());
    };

    return (
      <>
        <Button
          className={classnames('flex md:hidden')}
          iconLeft={iconLeft ?? IconChevronLeft}
          onClick={handleOnClick}
          variant={variant ?? 'action'}
        />
        <Button
          className={classnames('hidden')}
          iconClassName={classnames('p-[5px] w-[26px] h-[26px] rounded', {
            'bg-neutral-100': false,
          })}
          iconLeft={iconLeft ?? IconWindowMenu}
          onClick={handleOnClick}
          variant={variant ?? 'action'}
        />
      </>
    );
  };

export default ToggleMenu;
