import { DataFieldProps } from 'packages/formidable';
import React, { FC } from 'react';

const DataField: FC<Omit<DataFieldProps, 'componentType'>> = function (props) {
  const { name } = props;

  return <div>field {name}</div>;
};

export default DataField;
