/* eslint-disable max-len */
import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconGripHorizontal: FC<IconType> = ({
  className,
  color,
  onClick,
  size = 16,
}) => (
  <svg
    className={className}
    height={size}
    onClick={onClick}
    viewBox="0 0 448 512"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M96 288H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm160 0h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm160 0h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM96 96H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm160 0h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm160 0h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconGripHorizontal;
