import { DocumentType, PageType } from '@innedit/innedit-type';
import { PageData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { DataField, DataFieldSelectProps } from '../../packages/formidable';

export interface DataPagesSelectProps extends DataFieldSelectProps {
  channelId: string;
  className?: string;
  espaceId: string;
  formName: string;
  id?: string;
  label?: string;
}
const DataPagesSelect: FC<
  Omit<DataPagesSelectProps, 'options' | 'componentType'>
> = ({
  channelId,
  className,
  espaceId,
  formName,
  name,
  id,
  label,
  labelShow,
  ...props
}) => {
  const [options, setOptions] = useState<DocumentType<PageType>[]>([]);

  useEffect(() => {
    let isMounted = true;
    const pageData = new PageData({ channelId, espaceId });
    pageData
      .find()
      .then(docs => {
        if (isMounted) {
          setOptions(docs);
        }

        return isMounted;
      })
      .catch(e => {
        throw new Error(`loadPages : Page.search : ${e.message}`);
      });

    return () => {
      isMounted = false;
    };
  }, [channelId, espaceId, id]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <DataField
      {...props}
      className={className}
      componentType="select"
      formName={formName}
      label={label}
      labelShow={labelShow}
      name={name}
      options={options.map(item => ({
        label: item.label,
        value: item.id,
      }))}
    />
  );
};

export default DataPagesSelect;
