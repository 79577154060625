import { DocumentType } from '@innedit/innedit-type';
import { DataWithChildren, DataWithChildrenProps } from 'packages/formidable';
import { GenericData, MediaData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { arrayPush } from 'redux-form';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import Medias from '~/components/Medias';
import IconFileImport from '~/icons/FileImport';

export interface DataMediasProps
  extends Omit<DataWithChildrenProps, 'componentType'> {
  accept?: string;
  espaceId: string;
  label?: string;
  listClassName?: string;
  name: string;
  parentCollectionName: string;
  parentId: string;
}

const DataMedias: FC<DataMediasProps> = ({
  accept,
  datas,
  display,
  espaceId,
  formName,
  label,
  listClassName,
  name,
  params,
  parentCollectionName,
  parentId,
}) => {
  const dispatch = useDispatch();
  const [urls, setUrls] = useState<DocumentType<{ url: string }>[]>();

  useEffect(() => {
    const genericData = new GenericData({
      parentCollectionName,
      parentId,
      collectionName: 'urls',
      orderField: 'url' as any,
    });
    const unsub = genericData.watch(docs => {
      setUrls(docs as any[]);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [parentCollectionName, parentId]);

  const handleOnChange = (values: string[]) => {
    // il y a eu un changement, donc on signale le changement dans le formulaire
    values.forEach(value => {
      dispatch(arrayPush(formName, name, value));
    });
  };

  const handleImportPhotoOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const index = event.currentTarget.getAttribute('data-index');
    if (index != null && urls && urls[parseInt(index, 10)]) {
      const mediaData = new MediaData({
        espaceId,
        parentCollectionName,
        parentId,
        parentField: 'medias',
      });

      const idx = parseInt(index, 10);

      const genericData = new GenericData({
        parentCollectionName,
        parentId,
        collectionName: 'urls',
        orderField: 'url' as any,
      });

      try {
        await toast.promise(
          Promise.all([
            mediaData.extractFromUrl(urls[idx].url),
            genericData.delete(urls[idx].id),
          ]),
          {
            error: "Problème lors de l'importation",
            pending: 'Importation de la photo en cours',
            success: 'Importation de la photo réussie',
          },
        );
      } catch (e) {
        toast.error((e as Error).message);
      }
    }
  };

  const handleImportPhotosOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (urls && urls.length > 0) {
      const mediaData = new MediaData({
        espaceId,
        parentCollectionName,
        parentId,
        parentField: 'medias',
      });

      const promises: Promise<any>[] = urls.map(url =>
        mediaData.extractFromUrl(url.url),
      );

      const genericData = new GenericData({
        parentCollectionName,
        parentId,
        collectionName: 'urls',
        orderField: 'url' as any,
      });
      promises.push(...urls.map(url => genericData.delete(url.id)));
      try {
        await toast.promise(Promise.all(promises), {
          error: "Problème lors de l'importation",
          pending: 'Importation des photos en cours',
          success: 'Importation des photos réussie',
        });
      } catch (e) {
        toast.error((e as Error).message);
      }
    }
  };

  return (
    <>
      {urls && urls.length > 0 && (
        <HOCGroup
          addIcon={IconFileImport}
          addOnClick={handleImportPhotosOnClick}
          title="Photos"
        >
          <ul className="grid grid-cols-4 flex-wrap justify-center gap-3">
            {urls.map(({ url }, index) => (
              <li key={url} className="relative border">
                <figure className="aspect-ratio aspect-ratio--square">
                  <img alt={url} className="aspect-ratio__content" src={url} />
                </figure>
                <Button
                  className="absolute right-1.5 top-1.5 z-10"
                  color="tertiary"
                  datas={{
                    'data-index': index,
                  }}
                  iconClassName="w-[12px] h-[12px]"
                  iconLeft={IconFileImport}
                  onClick={handleImportPhotoOnClick}
                  size="xs"
                  square
                  variant="solid"
                />
              </li>
            ))}
          </ul>
        </HOCGroup>
      )}
      <Medias
        accept={accept}
        display={display}
        espaceId={espaceId}
        formName={formName}
        listClassName={listClassName}
        onChange={handleOnChange}
        params={params}
        parentCollectionName={parentCollectionName}
        parentField={name}
        parentId={parentId}
        title={label ?? 'Médias'}
      >
        {datas && (
          <DataWithChildren
            componentType="box"
            datas={datas}
            formName={formName}
            params={params}
          />
        )}
      </Medias>
    </>
  );
};

export default DataMedias;
