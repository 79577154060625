import { DocumentType, UserType } from '@innedit/innedit-type';
import { UserData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { DataField, DataFieldSelectProps } from '../../packages/formidable';

export interface DataUsersSelectProps extends DataFieldSelectProps {
  className?: string;
  espaceId: string;
  formName: string;
  id?: string;
  label?: string;
}

const DataUsersSelect: FC<
  Omit<DataUsersSelectProps, 'options' | 'componentType'>
> = ({
  className,
  espaceId,
  formName,
  name,
  id,
  label,
  labelShow,
  ...props
}) => {
  const [users, setUsers] = useState<DocumentType<UserType>[]>([]);

  useEffect(() => {
    let isMounted = true;
    const userData = new UserData();
    userData
      .find()
      .then(docs => {
        if (isMounted) {
          setUsers(docs);
        }

        return isMounted;
      })
      .catch(e => {
        throw new Error(`loadPages : Page.search : ${e.message}`);
      });

    return () => {
      isMounted = false;
    };
  }, [espaceId, id]);

  if (!users || 0 === users.length) {
    return null;
  }

  return (
    <DataField
      {...props}
      className={className}
      componentType="select"
      formName={formName}
      label={label}
      labelShow={labelShow}
      name={name}
      options={users.map(user => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }))}
    />
  );
};

export default DataUsersSelect;
