import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import { DataOptionsProps } from '~/datas/props';

import HOCGroup from '../../components/Group/HOC';
import DataOption from './Item';

const DataOptionsRender: FC<
  WrappedFieldArrayProps & Omit<DataOptionsProps, 'name'>
> = ({ display, fields, title }) => {
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fields.push({
      label: '',
      value: '',
    });
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  return (
    <HOCGroup addOnClick={handleAddOnClick} display={display} title={title}>
      {fields.length > 0 && (
        <div className="flex flex-col space-y-2">
          {fields.map((field, index) => (
            <DataOption
              key={field}
              deleteOnClick={handleDeleteOnClick}
              index={index}
              name={field}
            />
          ))}
        </div>
      )}
      {0 === fields.length && <p>Aucune option</p>}
    </HOCGroup>
  );
};

export default DataOptionsRender;
