import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataHyperLinksProps } from '~/datas/props';

import DataHyperlinksRender from './Render';

const DataHyperlinks: FC<DataHyperLinksProps> = ({ name, ...others }) => (
  <FieldArray component={DataHyperlinksRender} name={name} props={others} />
);

export default DataHyperlinks;
