import { httpsCallable } from 'firebase/functions';

import { functions } from '../config/firebase';

class Google {
  static call({
    kind,
    limit,
    params,
    q,
    start,
  }: {
    kind: string;
    limit?: number;
    params?: Record<string, string>;
    q: string;
    start?: number;
  }): Promise<any> {
    const google = httpsCallable(functions, 'google');

    return google({ kind, limit, params, q, start }).then(
      result => result.data,
    );
  }
}

export default Google;
