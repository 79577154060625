import { DocumentType, PaymentType } from '@innedit/innedit-type';
import { GenericData } from 'packages/innedit';
import { useEffect, useState } from 'react';

const useOrderPayments = (
  orderId: string,
): {
  paymentAmount: number;
  paymentAmountCapturable: number;
  paymentAmountReceived: number;
  paymentAmountRefunded: number;
  payments?: DocumentType<PaymentType>[];
} => {
  const [payments, setPayments] = useState<DocumentType<PaymentType>[]>();
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentAmountCapturable, setPaymentAmountCapturable] =
    useState<number>(0);
  const [paymentAmountRefunded, setPaymentAmountRefunded] = useState<number>(0);
  const [paymentAmountReceived, setPaymentAmountReceived] = useState<number>(0);

  useEffect(() => {
    let unsub: () => void | undefined;
    if (orderId) {
      // On recherche tous les paiements associés à ce bon de commande
      const genericData = new GenericData<PaymentType>({
        collectionName: 'payments',
      });
      unsub = genericData.watch(
        docs => {
          let tmpPaymentAmount = 0;
          let tmpPaymentAmountCapturable = 0;
          let tmpPaymentAmountRefunded = 0;
          const tmpPaymentAmountReceived =
            docs.length > 0
              ? docs.reduce((acc, doc) => {
                  if (doc.status !== 'canceled') {
                    tmpPaymentAmount += doc.amount ?? 0;
                    tmpPaymentAmountRefunded += doc.amountRefunded ?? 0;
                    tmpPaymentAmountCapturable += doc.amountCapturable ?? 0;
                  }

                  return acc + (doc.amountReceived ?? 0);
                }, 0)
              : 0;

          setPaymentAmount(tmpPaymentAmount);
          setPaymentAmountCapturable(tmpPaymentAmountCapturable);
          setPaymentAmountRefunded(tmpPaymentAmountRefunded);
          setPaymentAmountReceived(tmpPaymentAmountReceived);

          setPayments(docs);
        },
        {
          orderDirection: 'asc',
          orderField: 'createdAt',
          wheres: {
            orderId,
          },
        },
      );
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [orderId]);

  return {
    paymentAmount,
    paymentAmountCapturable,
    paymentAmountReceived,
    paymentAmountRefunded,
    payments,
  };
};

export default useOrderPayments;
