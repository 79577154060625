import { NotificationType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import React, { FC, ReactNode, SyntheticEvent } from 'react';

import Icon from '~/components/Icon';
import IconChartTreeMap from '~/icons/ChartTreeMap';
import IconClipboardList from '~/icons/ClipboardList';
import IconComments from '~/icons/Comments';
import IconLink from '~/icons/Link';
import IconNote from '~/icons/Note';
import IconListCheck from '~/icons/Tasks';

const Notification: FC<{
  className?: string;
  component?: ReactNode;
  onClick?: (event: SyntheticEvent<HTMLElement>) => void;
  value: NotificationType;
}> = function ({
  className,
  component,
  onClick,
  value: { date, description, kind, image, label, to, subLabel },
}) {
  const icons: Record<string, FC> = {
    checklists: IconClipboardList,
    extracts: IconLink,
    inquiries: IconComments,
    notes: IconNote,
    produits: IconChartTreeMap,
    tasks: IconListCheck,
  };

  const handleOnClick = (event: SyntheticEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
    if (to) {
      navigate(to);
    }
  };

  return (
    <div
      className={classnames(
        'select-none',
        className,
        'rounded-[22px] bg-light-300 py-1.5 pl-4 pr-3 drop-shadow-notification',
      )}
    >
      <div
        className="flex cursor-pointer items-center space-x-3"
        onClick={handleOnClick}
        role="presentation"
      >
        {icons[kind] && (
          <div className="flex h-[24px] w-[24px] min-w-[24px] items-start justify-center">
            <Icon
              className="h-[24px] w-[24px]"
              label={kind}
              value={icons[kind]}
            />
          </div>
        )}
        <div className="flex w-full flex-col justify-between overflow-hidden py-1.5">
          <div className="flex flex-row items-center justify-between space-x-3">
            <h2 className="truncate text-[17px] font-[400] md:text-[15px]">
              {label}
            </h2>
            {date && (
              <span className="whitespace-nowrap text-[15px] text-light-800 md:text-[14px]">
                {dayjs(date).format('D MMM')}
              </span>
            )}
          </div>
          <div className="flex justify-between space-x-3">
            <div>
              {subLabel && (
                <h3 className="truncate text-[15px] font-normal md:text-[14px]">
                  {subLabel}
                </h3>
              )}
              <p className="max-h-[40px] overflow-hidden text-ellipsis text-[15px] leading-[20px] text-light-800 md:max-h-[20px] md:text-[14px]">
                {description}
              </p>
            </div>
            {image && (
              <div className="flex h-[32px] w-[32px] min-w-[32px] items-center justify-center">
                <div>
                  <img alt={label} height={32} src={image} width={32} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {component && (
        <div className="-mb-1.5 -ml-4 -mr-3 rounded-b-[22px] bg-light-500 p-3">
          {component}
        </div>
      )}
    </div>
  );
};

export default Notification;
