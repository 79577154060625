import {
  DocumentType,
  ReservationType,
  StockType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { DataField, DataProps, Group } from 'packages/formidable';
import { ReservationData, StockData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import ButtonGroup from '~/components/Button/Group';
import { FieldTemplate } from '~/components/Field';
import HOCGroup from '~/components/Group/HOC';
import Input from '~/components/Input';
import Modal from '~/components/View/Modal';
import Form from '~/containers/Espace/Form';
import IconChevron from '~/icons/Chevron';
import IconMinus from '~/icons/Minus';
import IconPlus from '~/icons/Plus';
import reservation from '~/params/reservation.json';

export interface FieldProps {
  label: string;
  name: string;
  type: string;
}

export interface DataProduitsInventaireProps
  extends Omit<DataProps, 'componentType'> {
  adjustLabel?: string;
  espaceId: string;
  display?: 'inside' | 'group';
  docCollectionName: string;
  docId: string;
  title?: string;
}

const DataProduitsInventaire: FC<DataProduitsInventaireProps> = ({
  adjustLabel,
  espaceId,
  display,
  docCollectionName,
  docId,
  title,
}) => {
  const [reservationId, setReservationId] = useState<string>();
  const [adjustedQty, setAdjustedQty] = useState<number>(0);
  const [openStocks, setOpenStocks] = useState<boolean>(false);
  const [stocks, setStocks] = useState<DocumentType<StockType>[]>();
  const [reservations, setReservations] =
    useState<DocumentType<ReservationType>[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    let unsub: () => void;
    if (docId) {
      setIsLoading(true);
      const stockData = new StockData({
        espaceId,
        parentCollectionName: docCollectionName,
        parentId: docId,
      });
      unsub = stockData.watch(newDocs => {
        if (isMounted) {
          setStocks(newDocs);
          setIsLoading(false);
        }

        return true;
      });

      const reservationData = new ReservationData({
        espaceId,
      });

      reservationData.watch(
        newDocs => {
          if (isMounted) {
            setReservations(newDocs);
          }
        },
        {
          wheres: {
            productId: docId,
          },
        },
      );
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, docCollectionName, docId]);

  const handleAdjustOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const stockData = new StockData({
      espaceId,
      parentCollectionName: docCollectionName,
      parentId: docId,
    });

    setAdjustedQty(0);

    const data = stockData.initialize({
      quantity: adjustedQty,
    });

    await toast.promise(stockData.create(data), {
      error: "Problème lors de l'enregistrement de l'ajustement",
      pending: "Enregistrement de l'ajustement en cours",
      success: 'Ajustement de la quantité réussi',
    });

    setAdjustedQty(0);
  };

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setAdjustedQty(oldQty => oldQty + 1);
  };

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setAdjustedQty(parseInt(String(event.currentTarget.value), 10));
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setAdjustedQty(oldQty => oldQty - 1);
  };

  const handleStocksOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setOpenStocks(true);
  };

  const handleCloseOnClick = () => {
    setOpenStocks(false);
  };

  const handleReservationOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const id = event.currentTarget.getAttribute('data-id');
    if (id) {
      setReservationId(id);
    }
  };

  const handleReservationCloseOnClick = () => {
    setReservationId(undefined);
  };

  const quantity =
    stocks && stocks.length
      ? stocks.reduce((acc, doc) => acc + doc.quantity, 0)
      : 0;

  return (
    <>
      {reservationId && (
        <Modal closeOnClick={handleReservationCloseOnClick} title="Réservation">
          <Form
            displayArchive={false}
            displayHeader={false}
            docId={reservationId}
            model={
              new ReservationData({
                espaceId,
                params: reservation,
              })
            }
            type="update"
          />
        </Modal>
      )}
      {openStocks && stocks && (
        <Modal closeOnClick={handleCloseOnClick} title="Historique">
          <ul>
            {stocks.map(stock => (
              <li key={stock.id} className="flex justify-between">
                <span>{stock.quantity}</span>

                <span>{dayjs(stock.createdAt).format('D MMM HH:mm')}</span>
              </li>
            ))}
          </ul>
        </Modal>
      )}
      <HOCGroup
        customInfos={
          docId && (
            <Button
              className="absolute right-0 top-0 min-h-0 whitespace-nowrap text-[13px] leading-[21px]"
              onClick={handleStocksOnClick}
              variant="sub-action"
            >
              Voir l&apos;historique
            </Button>
          )
        }
        display={display}
        title={title || 'Quantité'}
      >
        {isLoading && <p className="m-0">Chargement en cours</p>}
        {!docId && (
          <DataField
            componentType="input"
            labelShow={false}
            name="qtyAvailable"
            placeholder="Quantité disponible"
            type="number"
          />
        )}
        <>
          <div className="relative flex flex-1 space-x-3">
            <FieldTemplate className="flex-1">{quantity}</FieldTemplate>
            <FieldTemplate>
              <ButtonGroup className="flex-1">
                <Input
                  className="flex-1"
                  onChange={handleOnChange}
                  type="number"
                  value={adjustedQty}
                />
                <Button
                  color="neutral"
                  iconClassName="h-[16px] w-[16px]"
                  iconLeft={IconPlus}
                  onClick={handleAddOnClick}
                  variant="field"
                />
                <Button
                  color="neutral"
                  iconClassName="h-[16px] w-[16px]"
                  iconLeft={IconMinus}
                  onClick={handleRemoveOnClick}
                  variant="field"
                />
              </ButtonGroup>
            </FieldTemplate>
          </div>

          {adjustedQty !== 0 && (
            <div className="flex flex-col">
              <div className="mt-3 flex content-center items-center justify-center space-x-3">
                <span>{quantity}</span> <IconChevron size={10} />
                <span>{quantity + adjustedQty}</span>
              </div>
              <Button
                className="mt-3"
                color="secondary"
                onClick={handleAdjustOnClick}
                text={adjustLabel || 'Ajuster la quantité'}
                variant="groove"
              />
            </div>
          )}
        </>
        {undefined !== reservations && reservations.length > 0 && (
          <Group className="mt-3" display="inside" title="Réservations">
            <ul>
              {reservations.map(r => (
                <li key={r.id} className="flex justify-between">
                  <Button
                    datas={{
                      'data-id': r.id,
                    }}
                    onClick={handleReservationOnClick}
                    variant="link"
                  >
                    {r.quantity}
                  </Button>
                  <span>{dayjs(r.createdAt).format('DD/MM/YY à HH:mm')}</span>
                </li>
              ))}
            </ul>
          </Group>
        )}
      </HOCGroup>
    </>
  );
};

export default DataProduitsInventaire;
