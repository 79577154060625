import React, { VoidFunctionComponent } from 'react';
import { FieldArray } from 'redux-form';

import { DataInquiryMessagesProps } from '~/datas/props';

import MessagesRender from './Render';

const DataInquiryMessages: VoidFunctionComponent<
  DataInquiryMessagesProps
> = props => (
  <FieldArray component={MessagesRender} name={props.name} props={props} />
);

export default DataInquiryMessages;
