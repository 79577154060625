import range from 'lodash/range';
import { ModelData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';

import Button from '~/components/Button';
import IconStar from '~/icons/Star';
import { colors } from '~/styles/theme';

const Rating: FC<{
  base?: number;
  docId: string;
  model: ModelData<any>;
  value?: number;
}> = function ({ base = 100, docId, model, value: defaultValue = 0 }) {
  const [value, setValue] = useState<number>(defaultValue);
  const [overValue, setOverValue] = useState<number>(0);
  const ref0 = useRef<HTMLButtonElement>(null);
  const ref1 = useRef<HTMLButtonElement>(null);
  const ref2 = useRef<HTMLButtonElement>(null);
  const ref3 = useRef<HTMLButtonElement>(null);
  const ref4 = useRef<HTMLButtonElement>(null);

  const refs = [ref0, ref1, ref2, ref3, ref4];

  useEffect(() => {
    setValue(defaultValue);
    const handleMouveEnter = (event: any) => {
      const newValue = event.currentTarget.getAttribute('data-value');
      if (newValue !== null) {
        setOverValue(parseInt(newValue, 10));
      }
    };
    const handleMouveLeave = () => {
      setOverValue(0);
    };

    const current0 = ref0.current;
    const current1 = ref1.current;
    const current2 = ref2.current;
    const current3 = ref3.current;
    const current4 = ref4.current;

    if (current0) {
      current0.addEventListener('mouseenter', handleMouveEnter);
      current0.addEventListener('mouseleave', handleMouveLeave);
    }
    if (current1) {
      current1.addEventListener('mouseenter', handleMouveEnter);
      current1.addEventListener('mouseleave', handleMouveLeave);
    }
    if (current2) {
      current2.addEventListener('mouseenter', handleMouveEnter);
      current2.addEventListener('mouseleave', handleMouveLeave);
    }
    if (current3) {
      current3.addEventListener('mouseenter', handleMouveEnter);
      current3.addEventListener('mouseleave', handleMouveLeave);
    }
    if (current4) {
      current4.addEventListener('mouseenter', handleMouveEnter);
      current4.addEventListener('mouseleave', handleMouveLeave);
    }

    return () => {
      if (current0) {
        current0.removeEventListener('mouseenter', handleMouveEnter);
        current0.removeEventListener('mouseleave', handleMouveLeave);
      }
      if (current1) {
        current1.removeEventListener('mouseenter', handleMouveEnter);
        current1.removeEventListener('mouseleave', handleMouveLeave);
      }
      if (current2) {
        current2.removeEventListener('mouseenter', handleMouveEnter);
        current2.removeEventListener('mouseleave', handleMouveLeave);
      }
      if (current3) {
        current3.removeEventListener('mouseenter', handleMouveEnter);
        current3.removeEventListener('mouseleave', handleMouveLeave);
      }
      if (current4) {
        current4.removeEventListener('mouseenter', handleMouveEnter);
        current4.removeEventListener('mouseleave', handleMouveLeave);
      }
    };
  }, [model.parentCollectionName, defaultValue, docId]);

  const handleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const newValue = event.currentTarget.getAttribute('data-value');
    if (newValue !== null) {
      setValue(parseInt(newValue, 10));
      model.update(docId, {
        rating: parseInt(newValue, 10),
      });
    }
  };

  return (
    <div>
      <input type="hidden" value={value} />
      <div className="flex">
        {range(5).map((item, index) => {
          const tmp = (base / 5) * (item + 1);

          return (
            <Button
              key={item}
              className="mb-[5px] mt-[4px]"
              datas={{
                'data-value': tmp,
              }}
              iconBgColor={
                overValue >= tmp || value >= tmp
                  ? colors.dark['300']
                  : 'transparent'
              }
              iconClassName="h-[16px] w-[16px]"
              iconColor={colors.dark['300']}
              iconLeft={IconStar}
              innerRef={refs[index]}
              onClick={handleOnClick}
              variant="link"
            />
          );
        })}
      </div>
    </div>
  );
};

export default Rating;
