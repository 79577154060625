import classnames from 'classnames';
import { DataProps, DataSection } from 'packages/formidable';
import { getContrast } from 'polished';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import { ItemEL } from '~/datas/Contents/Crud/Item';
import IconDelete from '~/icons/Delete';
import control from '~/styles/control';

export interface DataColorsItemProps {
  data: {
    value?: string;
    bgColor?: string;
    borderColor?: string;
    color?: string;
    textColor?: string;
  };
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  espaceId: string;
  formName: string;
  index: number;
  name: string;
  params?: Record<string, any>;
}

const DataColorsItem: FC<DataColorsItemProps> = ({
  data,
  deleteOnClick,
  formName,
  espaceId,
  index,
  name,
  params,
}) => {
  let contrast = 1;
  try {
    contrast = getContrast(
      data.bgColor ?? data.color ?? '#ffffff',
      data.textColor ?? '#000000',
    );
  } catch (e) {
    console.error((e as Error).message);
  }

  return (
    <ItemEL className="item-list flex min-h-12 flex-row items-center justify-between rounded bg-light-300">
      <DataSection
        className="flex-1"
        datas={[
          {
            cols: 5,
            componentType: 'grid',
            datas: [
              {
                componentType: 'color/select',
                fieldProps: {
                  className: 'rounded-r-none',
                },
                labelShow: false,
                name: 'value',
                required: true,
                type: 'text',
                wrapperProps: {
                  className: 'flex-1 m-0',
                },
              },
              {
                componentType: 'color',
                fieldProps: {
                  className: 'rounded-none',
                },
                labelShow: false,
                name: 'color',
                required: true,
                templateProps: {
                  className: 'rounded-none',
                },
              },
              {
                componentType: 'color',
                fieldProps: {
                  className: 'rounded-none',
                },
                labelShow: false,
                name: 'textColor',
                required: true,
              },
              {
                componentType: 'color',
                fieldProps: {
                  className: 'rounded-none',
                },
                labelShow: false,
                name: 'bgColor',
              },
              {
                componentType: 'color',
                fieldProps: {
                  className: 'rounded-none',
                },
                labelShow: false,
                name: 'borderColor',
              },
            ],
            gap: 0,
          } as DataProps,
        ]}
        formName={formName}
        name={name}
        params={{ ...params, espaceId }}
      />
      <div className="flex justify-between text-neutral-700">
        <div
          className="border"
          style={{
            borderColor: control.border.color.base,
          }}
        >
          <div
            className={classnames(
              'flex h-[40px] w-[40px] items-center justify-center border-2',
            )}
            style={{
              backgroundColor: data.bgColor ?? data.color,
              borderColor: data.borderColor ?? data.bgColor ?? data.color,
              color: data.textColor,
            }}
          >
            {Math.round(contrast * 10) / 10}
          </div>
        </div>
        <div className="actions items-center justify-center">
          {deleteOnClick && (
            <Button
              className="h-[42px] rounded-l-none"
              color="neutral"
              datas={{
                'data-index': index,
              }}
              iconLeft={IconDelete}
              onClick={deleteOnClick}
              size="md"
              variant="outline"
            />
          )}
        </div>
      </div>
    </ItemEL>
  );
};

export default DataColorsItem;
