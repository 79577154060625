import styled from '@emotion/styled';
import { DocumentType, ThoughtType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { ThoughtData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import CommentsItem from '~/components/Comments/Item';
import IconArrowUp from '~/icons/ArrowUp';
import IconCommentCheck from '~/icons/CommentCheck';

const Textarea = styled.textarea`
  resize: none;
  outline: none;
  min-height: 24px;
  line-height: 24px;
  display: flex;
`.withComponent(TextareaAutosize);

const Comments: FC<{
  className?: string;
  collectionName: string;
  docId: string;
  espaceId: string;
}> = function ({ className, collectionName, docId, espaceId }) {
  const [showResolved, setShowResolved] = useState<boolean>(false);
  const [comments, setComments] = useState<DocumentType<ThoughtType>[]>();
  const [text, setText] = useState<string>('');

  useEffect(() => {
    let isMounted = true;
    const thoughtData = new ThoughtData({
      espaceId,
      parentCollectionName: collectionName,
      parentField: 'comments',
      parentId: docId,
      wheres: {
        isResolved: showResolved,
      },
    });

    const unsub = thoughtData.watch(docs => {
      if (isMounted) {
        setComments(docs);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [collectionName, docId, showResolved]);

  const handleChangeResolveOnClick = () => {
    setShowResolved(r => !r);
  };

  const handleOnChange = (event: SyntheticEvent<any>) => {
    const { value } = event.currentTarget;

    setText(value);
  };

  const handleOnCreate = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const thoughtData = new ThoughtData({
      espaceId,
      parentCollectionName: collectionName,
      parentField: 'comments',
      parentId: docId,
    });

    try {
      setText('');
      await toast.promise(
        thoughtData.create({
          text,
          date: dayjs().format('YYYY-MM-DD'),
          kind: 'comment',
        }),
        {
          error: "Une erreur c'est produit lors de la création",
          pending: "En cours de création d'un commentaire",
          success: 'La création du commentaire a réussi',
        },
      );
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <div className={className}>
      {comments && comments.length > 0 && (
        <div className="mb-3">
          {comments.map(comment => (
            <CommentsItem key={comment.id} value={comment} />
          ))}
        </div>
      )}

      <div className="flex items-end rounded-[4px] bg-neutral-50 pl-2">
        <Button
          className={classnames(
            'm-[4px] h-[26px] w-[26px] rounded-full border-none bg-transparent ',
            {
              'text-light-900': !showResolved,
              'text-secondary-600': showResolved,
            },
          )}
          iconLeft={IconCommentCheck}
          onClick={handleChangeResolveOnClick}
          size="xs"
          square
        />
        <Textarea
          className="my-[4px] flex-1 rounded-r-none bg-transparent pl-[8px]"
          maxRows={text ? undefined : 1}
          onChange={handleOnChange}
          placeholder="Saisir un commentaire..."
          value={text}
        />
        <div>
          <Button
            className={classnames(
              'm-[4px] h-[26px] w-[26px] rounded-full border-none',
              {
                'bg-neutral-100  text-dark-200': !text,
                'bg-secondary-600 text-light-50': text,
              },
            )}
            iconClassName="h-[14px] w-[14px]"
            iconLeft={IconArrowUp}
            onClick={handleOnCreate}
            size="xs"
            square
            title="Créer le commentaire"
          />
        </div>
      </div>
    </div>
  );
};

export default Comments;
