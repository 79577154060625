import { DocumentType, ThoughtType } from '@innedit/innedit-type';

import Model, { ModelProps } from '../Model';

export interface ModelThoughtProps extends ModelProps<ThoughtType> {
  parentField?: string;
}

class Thought extends Model<ThoughtType> {
  constructor(props: Omit<ModelThoughtProps, 'collectionName'>) {
    super({
      ...props,
      canDoSearch: true,
      collectionName: 'thoughts',
      labelFields: ['createdAt'],
      orderDirection: props.orderDirection || 'asc',
      orderField: props.orderField || 'createdAt',
      queryBy: 'text',
    });
  }

  public async create(
    data: Partial<ThoughtType>,
    id?: string,
  ): Promise<DocumentType<ThoughtType>> {
    return super.create({
      ...data,
      parentCollectionName: this.parentCollectionName,
      parentField: this.parentField,
      parentId: this.parentId,
    });
  }

  public initialize(data?: Partial<ThoughtType>): Partial<ThoughtType> {
    return super.initialize({
      isResolved: false,
      kind: 'text',
      parentCollectionName: this.parentCollectionName,
      parentField: this.parentField,
      parentId: this.parentId,
      ...data,
    });
  }

  public clean(
    values: Partial<ThoughtType>,
    validate?: boolean,
  ): Partial<ThoughtType> {
    return super.clean({
      ...values,
      isResolved: values.isResolved ?? false,
      kind: values.kind || 'text',
    });
  }
}

export default Thought;
