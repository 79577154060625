import { DocumentType, ProduitType } from '@innedit/innedit-type';
import { Link } from 'gatsby';
import { SearchResponseHit } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';

interface DataSubscriptionProductHitProps {
  espaceId: string;
  hit: SearchResponseHit<DocumentType<ProduitType>>;
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}
const DataSubscriptionProductHit: FC<DataSubscriptionProductHitProps> =
  function ({ espaceId, hit, onClick }) {
    const { id, label } = hit.document;

    return (
      <div className="flex items-center justify-between">
        <Link to={`/espaces/${espaceId}/produits/${id}/update`}>{label}</Link>
        <Button
          color="neutral"
          datas={{
            'data-id': id,
          }}
          onClick={onClick}
          size="xs"
          variant="ghost"
        >
          Ajouter
        </Button>
      </div>
    );
  };

export default DataSubscriptionProductHit;
