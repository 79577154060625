import { LanguageType } from '@innedit/innedit-type';

import Model, { ModelProps } from '../Model';

class Language extends Model<LanguageType> {
  constructor(props: Omit<ModelProps<LanguageType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'languages',
      orderDirection: 'asc',
      orderField: 'key',
    });
  }
}

export default Language;
