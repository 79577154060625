import { ContactAttributesType } from '@innedit/innedit-type';
import { Link } from 'gatsby';
import compact from 'lodash/compact';
import React, { FC } from 'react';

const NotEditable: FC<{
  datas: Partial<ContactAttributesType>;
  espaceId: string;
}> = function ({ datas, espaceId }) {
  const { contactId } = datas;
  const description = compact([
    datas.contactPhone,
    datas.contactEmail,
    compact([datas.contactAddress, datas.contactZip, datas.contactCity]).join(
      ' ',
    ),
  ]).join('<br/>');

  return (
    <div>
      <h3>
        {contactId ? (
          <Link to={`/espaces/${espaceId}/contacts/${contactId}/update`}>
            {datas.contactName}
          </Link>
        ) : (
          datas.contactName
        )}
      </h3>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default NotEditable;
