import { DataProps } from 'packages/formidable';
import { MediaData } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import Media from '~/components/Media';

export interface DataMediaProps extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  formName: string;
}

const DataMedia: FC<DataMediaProps> = function ({
  espaceId,
  formName,
  label,
  params,
}) {
  const docId = params.docId as string;
  const dataForm = params[formName];

  const { type } = dataForm ?? {};

  const handleGenerateOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const video = document.querySelector('video');
    const canvas = document.createElement('canvas');

    if (video && canvas) {
      canvas.width = video?.videoWidth;
      canvas.height = video?.videoHeight;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          blob => {
            if (blob) {
              const mediaData = new MediaData({ espaceId });
              mediaData
                .uploadBlob(blob, 'poster', docId)
                .then(newMedia =>
                  mediaData.update(docId, {
                    thumbnail: newMedia.fullPath,
                  }),
                )
                .catch(console.error);
            }

            // const reader = new FileReader();
            // reader.readAsDataURL(blob as Blob);
            // reader.onloadend = function () {
            //   const base64data = reader.result;
            //   resolve(base64data as string);
            // };
          },
          'image/jpeg',
          1,
        );
      }
    }
  };

  return (
    <HOCGroup label={label}>
      <div className="flex items-center justify-center">
        {dataForm ? (
          <Media
            media={{
              ...dataForm,
              origin: String(process.env.GATSBY_IMAGES_DOMAIN_NAME),
              pathname: dataForm.fullPath,
            }}
            options={{
              height: 640,
              width: 640,
            }}
          />
        ) : null}
      </div>
      {type && type.startsWith('video/') && (
        <div className="mt-6 flex justify-center">
          <Button color="neutral" onClick={handleGenerateOnClick}>
            Générer une image
          </Button>
        </div>
      )}
    </HOCGroup>
  );
};

export default DataMedia;
