import { DataType, DocumentType } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import { Box, Data, DataProps } from 'packages/formidable';
import { GroupBody, GroupTitle } from 'packages/formidable/components/Group';
import { GenericData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Media from '~/components/Media';

export interface DataParentInfoProps extends Omit<DataProps, 'componentType'> {
  datas?: DataType[];
  espaceId: string;
  parentCollectionName: string;
  parentId: string;
}

const DataParentInfo: FC<DataParentInfoProps> = ({
  datas,
  title,
  titleProps,
  ...props
}) => {
  const { t } = useTranslation();
  const { espaceId, parentCollectionName, parentId } = props;
  const [parent, setParent] = useState<DocumentType<any>>();
  const [model, setModel] = useState<GenericData<any>>();
  useEffect(() => {
    let isMounted = true;

    const newModel = new GenericData<any>({
      espaceId,
      collectionName: parentCollectionName,
      labelFields: ['label'],
    });
    setModel(newModel);

    newModel.watchById(parentId, newDocument => {
      if (isMounted) {
        setParent(newDocument);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [parentCollectionName, parentId]);

  const hasMedias = parent?.medias && parent.medias.length > 0;

  return (
    <Box>
      {model && parent && (
        <>
          <GroupTitle {...titleProps}>
            {t(`parent.info.${parentCollectionName}.title`)}
          </GroupTitle>
          <GroupBody className="bg-light-500">
            <div className="flex items-center space-x-6 pb-3">
              {hasMedias && (
                <div>
                  <Media
                    className="rounded"
                    media={parent.medias[0]}
                    options={{
                      height: 65,
                      width: 65,
                    }}
                  />
                </div>
              )}

              <div>
                <h2 className="text-[15px]">
                  {model.labelFields.map(field => parent[field]).join(' - ')}
                </h2>
              </div>
            </div>
            {datas &&
              datas.length > 0 &&
              datas.map(data => (
                <Data key={objectHash(data)} {...props} {...data} />
              ))}
          </GroupBody>
        </>
      )}
    </Box>
  );
};

export default DataParentInfo;
