import { PriceType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

dayjs.extend(advancedFormat);

class Price extends ModelEspace<PriceType> {
  constructor(props: Omit<ModelEspaceProps<PriceType>, 'collectionName'>) {
    super({
      collectionName: 'prices',
      labelFields: ['type'],
      ...props,
    });
  }
}

export default Price;
