import { OrderProductType } from '@innedit/innedit-type';
import React, { SyntheticEvent, useState, VFC } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import OrderProductSearch from '~/datas/Orders/Products/Search';
import { DataOrdersProductsProps } from '~/datas/props';
import IconPlus from '~/icons/Plus';
import IconSearch from '~/icons/Search';

import Produit from './Item';

const DataOrdersProductsRender: VFC<
  WrappedFieldArrayProps & DataOrdersProductsProps
> = ({ display, espaceId, fields, formName, title, params }) => {
  const values = params && params[formName];
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const newItem: Partial<OrderProductType> = {
      quantity: 1,
    };
    fields.push(newItem);
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const index = event.currentTarget.getAttribute('data-index');

    if (undefined !== index && !Number.isNaN(Number(index))) {
      fields.remove(Number(index));
    }
  };

  const handleOpenOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenModal(true);
  };

  const handleCloseOnClick = (event?: SyntheticEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }

    setOpenModal(false);
  };

  const editable = 'paid' !== values?.status;

  return (
    <HOCGroup
      customInfos={
        <>
          <Button
            iconLeft={IconSearch}
            onClick={handleOpenOnClick}
            variant="sub-action"
          />
          {editable && (
            <Button
              iconLeft={IconPlus}
              onClick={handleAddOnClick}
              variant="sub-action"
            />
          )}
        </>
      }
      display={display}
      title={title}
    >
      {editable && openModal && (
        <OrderProductSearch
          closeOnClick={handleCloseOnClick}
          espaceId={espaceId}
          formName={formName}
        />
      )}
      {fields.length > 0 ? (
        <div>
          <div className="grid grid-cols-3 gap-3">
            <div className="col-span-2 flex items-center justify-start text-[11px]">
              Produit
            </div>
            <div className="col-span-1 flex items-center justify-end text-[11px]">
              Total
            </div>
            <div />
          </div>
          <div className="flex flex-col space-y-1">
            {fields.map((name, fieldIndex, allfields) => (
              <Produit
                key={name}
                deleteOnClick={handleDeleteOnClick}
                editable={editable}
                espaceId={espaceId}
                index={fieldIndex}
                name={name}
                produits={allfields}
              />
            ))}
          </div>
        </div>
      ) : (
        <p>Aucun produit</p>
      )}
    </HOCGroup>
  );
};

export default DataOrdersProductsRender;
