import { DocumentType } from '@innedit/innedit-type';
import { GenericData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { DataFeaturesSelectSubProps } from '~/datas/props';

import { DataField, FieldArrayFieldsProps } from '../../../packages/formidable';

const Item: FC<
  {
    fields: FieldArrayFieldsProps<string>;
    index: number;
  } & DataFeaturesSelectSubProps
> = ({
  espaceId,
  className,
  collectionName,
  fields,
  formName,
  index,
  label,
  name,
  orderDirection,
  orderField,
  parentId,
  wheres,
}) => {
  const [options, setOptions] = useState<DocumentType<any>[]>();

  const handleOnChange = () => {
    const len = fields.length;
    for (let i = index + 1; i < len; i += 1) {
      fields.pop();
    }
  };

  useEffect(() => {
    const feature = new GenericData({
      espaceId,
      collectionName,
    });

    const unsub: () => void = feature.watch(
      newDocs => {
        setOptions(newDocs);
      },
      {
        wheres: {
          ...wheres,
          parent: parentId || '',
        },
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, collectionName, orderDirection, orderField, parentId, wheres]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <DataField
      className={className}
      componentType="select"
      formName={formName}
      handleOnChange={handleOnChange}
      hasEmpty
      label={label}
      name={name}
      options={options?.map(item => ({
        label: item.get('label'),
        value: item.id,
      }))}
    />
  );
};

export default Item;
