import React, { FC } from 'react';
import { Field } from 'redux-form';

import { DataVariantsProps } from '~/datas/props';

import DataVariantsRender from './Render';

const DataVariants: FC<DataVariantsProps> = function ({ name, ...others }) {
  return (
    <div>
      <Field component={DataVariantsRender} name={name} {...others} />
    </div>
  );
};

export default DataVariants;
