import { InquiryType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Inquiry extends ModelEspace<InquiryType> {
  constructor(props: Omit<ModelEspaceProps<InquiryType>, 'collectionName'>) {
    super({
      canDoSearch: false,
      collectionName: 'inquiries',
      labelFields: ['contactName'],
      orderDirection: 'desc',
      orderField: 'updatedAt',
      tabs: [
        {
          label: 'Toutes les demandes',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/inquiries/`,
          wheres: {
            archived: false,
          },
        },
        {
          label: 'Archivées',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/inquiries/archived/`,
          wheres: {
            archived: true,
          },
        },
      ],
      ...props,
    });
  }

  public initialize(data?: Partial<InquiryType>): Partial<InquiryType> {
    return super.initialize({
      nbChildren: 0,
      ...data,
    });
  }
}

export default Inquiry;
