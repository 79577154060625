import React, { FC, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { change, WrappedFieldArrayProps } from 'redux-form';

import { DataHyperLinksProps } from '~/datas/props';

import HOCGroup from '../../components/Group/HOC';
import DataHyperLink from './Item';

const DataHyperlinksRender: FC<
  WrappedFieldArrayProps & Omit<DataHyperLinksProps, 'name'>
> = ({ display, fields, formName, title }) => {
  const dispatch = useDispatch();
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fields.push({
      href: '',
      label: '',
    });
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  const handlePasteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const field = event.currentTarget.getAttribute('data-field');
    if (null !== field) {
      const value = await navigator.clipboard.readText();
      dispatch(change(formName, `${field}.href`, value));
    }
  };

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      display={display}
      formName={formName}
      title={title}
    >
      {fields.length > 0 && (
        <div className="flex flex-col space-y-2">
          {fields.map((field, index) => (
            <DataHyperLink
              key={field}
              deleteOnClick={handleDeleteOnClick}
              index={index}
              name={field}
              pasteOnClick={handlePasteOnClick}
            />
          ))}
        </div>
      )}
      {0 === fields.length && <p>Aucun lien</p>}
    </HOCGroup>
  );
};

export default DataHyperlinksRender;
