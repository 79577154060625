import classnames from 'classnames';
import { DataField, DataFieldProps } from 'packages/formidable';
import { getContrast } from 'polished';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { ReducersProps } from '~/reducers';
import control from '~/styles/control';

export interface DataColorProps extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
}

const DataColor: FC<DataColorProps> = ({
  fieldProps,
  formName,
  name,
  params,
  ...props
}) => {
  const values = useSelector(
    (state: ReducersProps<any>) => state.form[formName].values,
  );

  let value;
  if (values) {
    let tmp = values;
    if (params?.sectionName) {
      const arr: string[] = params.sectionName.split('.');
      tmp = arr.reduce((acc, n) => {
        let newAcc = { ...acc };

        if (2 === n.split('[').length) {
          const [n2, i] = n.slice(0, n.length - 1).split('[');
          // C'est un tableau
          newAcc = newAcc[n2][i];
        } else {
          newAcc = acc[n];
        }

        return newAcc;
      }, values);
    }
    if (tmp) {
      value = tmp[name];
    }
  }

  let contrast = 1;

  try {
    contrast = getContrast(value ?? control.bg.color.base, '#000');
  } catch (e) {
    console.error((e as Error).message);
  }

  return (
    <div className="flex items-end">
      <DataField
        componentType="input"
        formName={formName}
        // innerElement={
        //   <div
        //     className="h-[6px] w-full border-b" // border rounded-r border-l-0
        //     style={{
        //       backgroundColor: value,
        //       borderColor: control.border.color.base,
        //     }}
        //   />
        // }
        name={name}
        params={params}
        {...props}
        fieldProps={{
          ...fieldProps,
          className: classnames(
            // 'rounded-r-none border-r-0',
            fieldProps?.className,
          ),
          style: {
            backgroundColor: value,
            color: contrast < 3 ? '#fff' : 'inherit',
          },
        }}
        templateProps={{
          className: 'flex flex-col',
        }}
        // wrapperProps={{
        //   className: 'mb-0', // classnames('', wrapperProps?.className),
        // }}
      />
    </div>
  );
};

export default DataColor;
