import styled from '@emotion/styled';
import { DocumentType, ThoughtType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { diff } from 'deep-object-diff';
import { DataFieldProps } from 'packages/formidable';
import { ThoughtData } from 'packages/innedit';
import { rem, rgba } from 'polished';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { change } from 'redux-form';

import Button from '~/components/Button';
import IconArrowUp from '~/icons/ArrowUp';
import { ReducersProps } from '~/reducers';
import control from '~/styles/control';
import spacing from '~/styles/spacing';
import { transition } from '~/styles/theme';
import createThought from '~/utils/createThought';

import Item from './Item';

const Box = styled.div`
  transition: background ${transition.timing.base} ${transition.easing.base};
  background: ${control.bg.color.base};
  border-color: ${control.border.color.base};
  &:hover {
    background: ${control.bg.color.focus};
  }

  &.has-textarea:focus-within {
    border-color: ${control.border.color.hover};
    box-shadow: 0 0 ${rem(1)} ${rem(3)}
      ${rgba(control.border.color.hover, 0.125)};
    background: ${control.bg.color.focus};
  }

  padding: ${spacing['1.5']} 0;

  > * + * {
    margin-top: ${spacing['1.5']};
  }
`;

const BoxTextarea = styled.div`
  transition: background-color ${transition.timing.base}
    ${transition.easing.base};
  background-color: ${control.bg.color.base};
  border-color: ${control.border.color.base};
  border-radius: ${control.radius.base};
  padding-left: ${spacing[4]};
  padding-right: ${spacing[2]};
  padding-bottom: 4px;
  padding-top: 4px;

  &:hover,
  &:focus-within {
    background-color: ${control.bg.color.focus};
    border-color: ${control.border.color.hover};
  }
`;
const Textarea = styled.textarea`
  resize: none;
  outline: none;
  min-height: 24px;
  line-height: 24px;

  &:focus {
    outline: none;
  }
`.withComponent(TextareaAutosize);

export interface DataThoughtsCrudProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  parentCollectionName: string;
  parentId: string;
}

const usePreviousFormValues = (value?: any): any | undefined => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const DataThoughtsCrud: FC<DataThoughtsCrudProps> = ({
  espaceId,
  formName,
  label,
  name,
  parentCollectionName,
  parentId,
}) => {
  const dispatch = useDispatch();
  // const ref = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState<string>('');
  const [thoughts, setThoughts] = useState<DocumentType<ThoughtType>[]>();

  const formValues = useSelector(
    (state: ReducersProps<any>) => state.form[formName],
  );

  const previousValues = usePreviousFormValues(formValues);
  const differences: any = diff(previousValues, formValues);
  const { submitSucceeded } = differences;
  const reset =
    previousValues &&
    previousValues.values &&
    previousValues.values[name] &&
    previousValues.values[name].localeCompare(formValues.values[name]) > 0;

  useEffect(() => {
    if (submitSucceeded && text && text !== '') {
      setText('');
      createThought({
        espaceId,
        parentCollectionName,
        parentId,
        text,
        parentField: name,
      });
    }
  }, [submitSucceeded, text]);

  useEffect(() => {
    if (reset && text) {
      setText('');
    }
  }, [reset, text]);

  useEffect(() => {
    let isMounted = true;
    const thoughtData = new ThoughtData({
      espaceId,
      parentCollectionName,
      parentId,
      orderDirection: 'asc',
      orderField: 'createdAt',
      parentField: name,
    });

    thoughtData.watch(docs => {
      if (isMounted) {
        setThoughts(docs);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [espaceId, name, parentCollectionName, parentId]);

  const handleOnChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;

    setText(value);
    dispatch(change(formName, name, dayjs().toISOString()));
  };

  const handleUpdateField = () => {
    dispatch(change(formName, name, dayjs().toISOString()));
  };

  const handleOnCreate = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setText('');

    return createThought({
      espaceId,
      parentCollectionName,
      parentId,
      text,
      kind: 'text',
      parentField: name,
    });
  };

  return (
    <div>
      <span>{label}</span>
      <div>
        {thoughts && thoughts.length > 0 && (
          <div className="mb-6 flex flex-col space-y-3">
            <Box className={classnames('has-textarea rounded border')}>
              {thoughts.map(thought => (
                <Item
                  key={thought.id}
                  onChange={handleUpdateField}
                  reset={reset}
                  submitSucceeded={submitSucceeded}
                  value={thought}
                />
              ))}
            </Box>
          </div>
        )}
      </div>
      <BoxTextarea className="flex items-end border">
        <Textarea
          className="m-1 flex-1 rounded-r-none bg-transparent"
          onChange={handleOnChange}
          placeholder="Nouveau contenu"
          value={text}
        />
        <div>
          <Button
            className="m-1 rounded-full"
            color="neutral"
            iconLeft={IconArrowUp}
            onClick={handleOnCreate}
            size="xs"
            square
          />
        </div>
      </BoxTextarea>
    </div>
  );
};

export default DataThoughtsCrud;
