import { Dispatch } from 'packages/formidable';
import { GenericData } from 'packages/innedit';

import { ESPACE_SET_COLLECTIONS } from '~/constants/actionTypes2';

function findByEspaceId(espaceId: string): any {
  return (dispatch: Dispatch): any => {
    const featureData = new GenericData({
      espaceId,
      collectionName: 'collections',
    });

    featureData
      .find({
        wheres: {
          hidden: false,
        },
      })
      .then(collections =>
        dispatch({
          collections,
          type: ESPACE_SET_COLLECTIONS,
        }),
      )
      .catch(error => {
        console.error(error.message);
      });
  };
}

export default findByEspaceId;
