import { ProduitType } from '@innedit/innedit-type';
import { DataSection } from 'packages/formidable';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

import { ReducersProps } from '~/reducers';

const PricesDefault: FC<{
  formName: string;
  size: number;
}> = ({ formName, size }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    cms: { espace },
    form,
  } = useSelector(
    (state: ReducersProps<ProduitType & { prices: string[] }>) => state,
  );

  const hasInitialPrices = Boolean(
    form && form[formName] && form[formName].values.prices,
  );

  useEffect(() => {
    if (hasInitialPrices && size > 0) {
      dispatch(change(formName, 'prices', undefined));
    }
  }, [hasInitialPrices, size]);

  const prices =
    espace &&
    espace.products &&
    espace.products.prices &&
    espace.products.prices.length > 0
      ? espace.products.prices
      : ['public'];

  if (size > 0) {
    return null;
  }

  return (
    <>
      {prices && prices.length > 0 && (
        <DataSection
          datas={
            [
              {
                cols: ['none', Math.max(2, prices.length)],
                componentType: 'grid',
                datas: prices.map(p => ({
                  componentType: 'input',
                  label: t(`espace.products.prices.${p}.label`),
                  name: p,
                  type: 'number',
                })),
              },
            ] as any[]
          }
          name="prices"
        />
      )}
    </>
  );
};

export default PricesDefault;
