import React, { FC, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';
import slug from 'slug';

import { DataField, DataFieldProps } from '../../../packages/formidable';

export interface DataSchemasDatasLabelProps
  extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
}

const DataSchemasDatasLabel: FC<DataSchemasDatasLabelProps> = ({
  formName,
  name,
  ...props
}) => {
  const dispatch = useDispatch();
  const handleOnChange = ({
    event,
    name: _name,
  }: {
    event?: SyntheticEvent<HTMLInputElement>;
    name?: string;
  }) => {
    const prefix = _name && _name.slice(0, -5);
    dispatch(
      change(formName, `${prefix}name`, slug(event?.currentTarget.value || '')),
    );
  };

  return (
    <>
      <Field component="input" disabled name="name" type="hidden" />
      <DataField
        {...props}
        componentType="input"
        handleOnChange={handleOnChange}
        name={name}
        required
      />
    </>
  );
};

export default DataSchemasDatasLabel;
