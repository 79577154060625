import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  DataFieldInputProps,
  DataFieldProps,
  DataFieldSelectProps,
} from 'packages/formidable/components/Data/props';
import FormidableContext from 'packages/formidable/FormidableContext';
import React, { useContext, VFC } from 'react';
import { Field as FieldForm } from 'redux-form';

import { spacing } from '~/styles/theme';

import { isDate, isEmail, isRequired, isTime } from '../../../utils/validators';
import DataFieldRender from './Render';
import DataFieldInput from './Render/Input';
import DataFieldWrapper from './Wrapper';

const InputGroupSC = styled.div<{
  orientation?: 'horizontal' | 'vertical';
}>`
  margin-bottom: ${spacing[8]};
  min-height: auto;
  display: flex;
  align-items: center;

  > div {
    margin-top: ${spacing[0.5]};
    margin-bottom: ${spacing[0.5]};
  }
  ${props => {
    if ('horizontal' === props.orientation) {
      return css`
        display: flex;
        flex-wrap: wrap;
        > * + * {
          margin-left: ${spacing[4]};
        }
      `;
    }

    return css``;
  }}
`;

const InputGroupItemSC = styled.div``;

const DataField: VFC<
  DataFieldSelectProps | DataFieldInputProps | DataFieldProps
> = ({
  className,
  fieldProps,
  mode,
  templateProps,
  wrapperProps,
  ...props
}) => {
  const { sc } = useContext(FormidableContext);

  if (mode && 'creation' === mode) {
    return (
      <div>
        {props.componentType} : {props.name}
      </div>
    );
  }

  const { componentType, id, name, required, params, type } = props;

  const parse = (value: string) => {
    let newValue: any;

    switch (type) {
      case 'number':
      case 'range': {
        newValue = value ? Number(value) : '';
        break;
      }
      default:
        newValue = value;
    }

    return newValue;
  };
  const newId =
    id ||
    `${params && params.sectionName ? `${params.sectionName}.` : ''}${
      params && params.name ? `${params.name}.` : ''
    }${name}`;

  const validate = (): any[] | undefined => {
    const newValidate = [];
    if (required) {
      newValidate.push(isRequired);
    }

    switch (type) {
      case 'date': {
        newValidate.push(isDate);
        break;
      }
      case 'email': {
        newValidate.push(isEmail);
        break;
      }
      case 'time': {
        newValidate.push(isTime);
        break;
      }
      default:
    }

    return newValidate.length > 0 ? newValidate : undefined;
  };

  if ('hidden' === type && 'input' === componentType) {
    return null;
  }

  if ('radio' === type && 'input' === componentType) {
    const { options, optionsProps } = props as DataFieldInputProps;
    if (!options || 0 === options.length) {
      return <div>input : erreur de paramètre : options obligatoire</div>;
    }

    return (
      <DataFieldWrapper
        {...props}
        id={newId}
        wrapperProps={{ ...wrapperProps }}
      >
        <InputGroupSC {...optionsProps} role="radiogroup">
          {options.map(option => (
            <InputGroupItemSC key={option.value} as={sc && sc.inputGroupItem}>
              <FieldForm
                fieldProps={fieldProps}
                templateProps={templateProps}
                {...props}
                className={className}
                component={DataFieldInput}
                description={option.label}
                descriptionParams={option.params}
                id={option.id || `${newId}_${option.value}`}
                validate={validate()}
                value={option.value}
              />
            </InputGroupItemSC>
          ))}
        </InputGroupSC>
      </DataFieldWrapper>
    );
  }

  return (
    <DataFieldWrapper {...props} id={newId} wrapperProps={wrapperProps}>
      <FieldForm
        {...props}
        as={sc && sc.fieldWrapper}
        className={className}
        component={DataFieldRender}
        fieldProps={fieldProps}
        id={newId}
        parse={parse}
        templateProps={templateProps}
        validate={validate()}
      />
    </DataFieldWrapper>
  );
};

export default DataField;
