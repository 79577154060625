import classnames from 'classnames';
import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import DataEmailSectionColumnsItem from './Item';

const DataEmailSectionColumnsRender: FC<
  WrappedFieldArrayProps & {
    collectionName: string;
    docId: string;
    espaceId: string;
    formName: string;
  }
> = function ({ collectionName, docId, espaceId, fields, formName }) {
  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  // const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
  //   event.preventDefault();
  //   fields.push({
  //     label: '',
  //   });
  // };

  return (
    <div className={classnames('grid gap-6', `grid-cols-${fields.length}`)}>
      {fields.length > 0 &&
        fields.map((field, index) => (
          <DataEmailSectionColumnsItem
            key={field}
            collectionName={collectionName}
            // data={fields.get(index)}
            deleteOnClick={handleDeleteOnClick}
            docId={docId}
            espaceId={espaceId}
            formName={formName}
            index={index}
            name={field}
          />
        ))}
      {0 === fields.length && <p>Aucune column</p>}
    </div>
  );
};
export default DataEmailSectionColumnsRender;
