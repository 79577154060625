import { OrderProductType } from '@innedit/innedit-type';
import React, { SyntheticEvent, VFC } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';
import { DataDeliveryRulesProps } from '~/datas/props';

import Item from './Item';

const DataDeliveryRulesRender: VFC<
  WrappedFieldArrayProps & DataDeliveryRulesProps
> = ({ display, fields, formName, params }) => {
  // const values = params && params[formName];

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const newItem: Partial<OrderProductType> = {
      quantity: 1,
    };
    fields.push(newItem);
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const index = event.currentTarget.getAttribute('data-index');

    if (undefined !== index && !Number.isNaN(Number(index))) {
      fields.remove(Number(index));
    }
  };

  // const editable = 'paid' === values?.status;

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      display={display}
      title="Règles de calcul"
    >
      {fields.length > 0 ? (
        <div className="">
          <div className="grid grid-cols-9 gap-3 pr-[45px]">
            <div className="flex text-[11px] items-center justify-center col-span-2">
              Poids
            </div>
            <div className="flex text-[11px] items-center justify-center col-span-2">
              Coef. Volume
            </div>
            <div className="flex text-[11px] items-center justify-center col-span-2">
              Coef. Poids
            </div>
            <div className="flex text-[11px] items-center justify-center col-span-2">
              Prise en charge
            </div>
            <div className="flex text-[11px] items-center justify-center">
              Express
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            {fields.map((name, fieldIndex, allfields) => (
              <Item
                key={name}
                deleteOnClick={handleDeleteOnClick}
                index={fieldIndex}
                name={name}
              />
            ))}
          </div>
        </div>
      ) : (
        <p>Aucune règle de calcul</p>
      )}
    </HOCGroup>
  );
};

export default DataDeliveryRulesRender;
