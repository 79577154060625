import { GoogleResultsType } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import { DataFieldProps } from 'packages/formidable';
import { Facia, Google } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { change } from 'redux-form';
import slug from 'slug';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import IconFileAlt from '~/icons/FileAlt';
import IconSearch from '~/icons/Search';

export interface DataGoogleResultsProps
  extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
  field: string;
}

const DataGoogleResults: FC<DataGoogleResultsProps> = ({
  formName,
  field,
  label: title,
  name,
  params,
}) => {
  const dispatch = useDispatch();

  const { values } = useSelector(
    (globalState: any) =>
      globalState.form[formName] && globalState.form[formName],
  );

  const handleOnclick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // setResult(undefined);
    const q = values && values[field];
    if (q) {
      Google.call({
        q,
        kind: 'results',
      })
        .then((data: GoogleResultsType) =>
          dispatch(change(formName, name, data)),
        )
        .catch(toast.error);
    }
  };

  const handleExtractOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const url = event.currentTarget.getAttribute('data-url');
    if (url) {
      toast.promise(
        Facia.call({
          kind: 'extract',
          params: {
            espaceId: params.espaceId,
            parentCollectionName: params.collectionName,
            parentField: 'extracts',
            parentId: params.docId,
          },
          q: url,
        }),
        {
          error: "Un problème est survenu lors de l'extraction",
          pending: "L'extraction du document est en cours",
          success: 'Le document a bien été extrait',
        },
      );
    }
  };

  const results: GoogleResultsType | undefined = values && values[name];

  return (
    <HOCGroup addIcon={IconSearch} addOnClick={handleOnclick} title={title}>
      {!results && <p>Aucun résultat</p>}
      {results && (
        <div className="flex flex-col space-y-3">
          {results.translations && (
            <div className="rounded p-3 bg-light-500 border flex flex-col">
              <h2 className="font-normal text-center text-[16px]">
                Traductions
              </h2>
              <div className="flex flex-col space-y-1">
                {results.translations.map(translation => (
                  <div
                    key={objectHash(translation)}
                    className="flex justify-between"
                  >
                    <span>{translation.text}</span>
                    <span>{translation.lang}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {results.extracts && results.extracts.length > 0 && (
            <div className="rounded p-3 bg-light-500 border">
              <h2 className="font-normal text-center text-[16px]">Extraits</h2>
              <div className="flex flex-col space-y-1">
                {results.extracts.map((extract, index) => (
                  <p key={objectHash({ extract, index })}>{extract}</p>
                ))}
              </div>
            </div>
          )}
          {results.graph && Object.keys(results.graph).length > 0 && (
            <div className="rounded p-3 bg-light-500 border">
              <h2 className="font-normal text-center text-[16px]">
                {results.graph.title}
              </h2>
              <h3 className="font-normal text-center">{results.graph.type}</h3>
              {results.graph.description && (
                <p className="">{results.graph.description}</p>
              )}
              {results.graph.datas && results.graph.datas.length > 0 && (
                <>
                  <h4 className="mt-3 mb-1 font-normal">
                    Informations associées
                  </h4>
                  <ul className="flex flex-col space-y-1">
                    {results.graph.datas.map(data => (
                      <li key={slug(data.label)} className="flex flex-col">
                        <span className="text-[10px]">{data.label}</span>
                        <span className="text-[12px]">{data.value}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {results.graph.relatedBusiness &&
                results.graph.relatedBusiness.length > 0 && (
                  <>
                    <h4 className="mt-3 mb-1 font-normal">
                      Entreprises associées
                    </h4>
                    <ul className="flex flex-col space-y-1">
                      {results.graph.relatedBusiness.map(business => (
                        <li key={slug(business.name)} className="flex flex-col">
                          <span className="text-[10px]">{business.type}</span>
                          <span className="text-[12px]">{business.name}</span>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
            </div>
          )}
          {results.questions && results.questions.length > 0 && (
            <div className="mt-3 flex flex-col space-y-3">
              {results.questions.map((question, index) => {
                let label;
                let description;
                let link;
                if ('object' === typeof question) {
                  label = question.label;
                  description = question.description;
                  link = question.link;
                } else {
                  label = question;
                }
                const [, , domain] = link
                  ? link.split('/')
                  : [undefined, undefined, undefined];

                return (
                  <div
                    key={slug(label)}
                    className="flex justify-between space-x-3"
                  >
                    <span className="flex-1 flex flex-col space-y-1 block bg-light-500 border rounded p-3">
                      <h2 className="font-normal text-center text-[14px]">
                        {label}
                      </h2>
                      <p className="">{description}</p>
                      <div className="flex justify-between">
                        {domain && (
                          <a
                            className="text-[#5f6368]"
                            href={link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {domain}
                          </a>
                        )}
                        {/* <Button */}
                        {/*  className="ml-1" */}
                        {/*  datas={{ */}
                        {/*    'data-index': index, */}
                        {/*    'data-type': 'question', */}
                        {/*  }} */}
                        {/*  iconLeft={IconArrowUp} */}
                        {/*  onClick={handleCreateSubDocumentOnClick} */}
                        {/*  size="xs" */}
                        {/*  variant="link" */}
                        {/* /> */}
                      </div>
                    </span>
                  </div>
                );
              })}
            </div>
          )}

          {results.stats && <p>{results.stats}</p>}
          {results.items && results.items.length > 0 && (
            <div className="flex flex-col space-y-3">
              {results.items.map(
                ({ description, link, title: label }, index) => {
                  if (!link) {
                    return null;
                  }

                  const [, , domain] = link.split('/');

                  return (
                    <div
                      key={objectHash({ index, link })}
                      className="flex flex-col"
                    >
                      <div className="flex justify-between">
                        <span className="text-[#5f6368]">{domain}</span>
                        <Button
                          datas={{
                            'data-url': link,
                          }}
                          iconLeft={IconFileAlt}
                          onClick={handleExtractOnClick}
                          size="xs"
                          variant="link"
                        />
                      </div>

                      <a
                        className="text-[#1a0dab]"
                        href={link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {label}
                      </a>
                      <p className="">{description}</p>
                    </div>
                  );
                },
              )}
            </div>
          )}

          {results.searches && results.searches.length > 0 && (
            <div className="mt-3 flex flex-col space-y-3">
              {results.searches.map(searche => (
                <div
                  key={slug(searche)}
                  className="flex justify-between space-x-3 bg-light-500 border rounded px-3 py-0.5"
                >
                  <span className="flex-1 block">{searche}</span>
                  {/* <Button */}
                  {/*  className="ml-1" */}
                  {/*  datas={{ */}
                  {/*    'data-index': index, */}
                  {/*    'data-type': 'search', */}
                  {/*  }} */}
                  {/*  iconLeft={IconArrowUp} */}
                  {/*  onClick={handleCreateSubDocumentOnClick} */}
                  {/*  size="xs" */}
                  {/*  variant="link" */}
                  {/* /> */}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </HOCGroup>
  );
};

export default DataGoogleResults;
