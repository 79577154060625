/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */

module.exports = {
  px: '1px',
  0: '0',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem', // xxs
  2.5: '0.625rem',
  3: '0.75rem', // xs
  3.5: '0.875rem',
  4: '1rem', // s
  5: '1.25rem',
  6: '1.5rem', // m
  7: '1.75rem',
  8: '2rem', // l
  9: '2.25rem',
  10: '2.5rem', // xl
  10.5: '2.625rem',
  11: '2.75rem',
  12: '3rem', // xxl
  13: '3.25rem',
  13.5: '3.375rem',
  14: '3.5rem', // xxxl
  15: '3.75rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
  main: '100vh',
  'apps-mobile': '100vw',
  'apps-mobile-open': 'calc(100vw - 50px)',
  'main-mobile': 'calc(100vh - 80px)',
  'menu-mobile': 'calc(100vw - 49px)',
  'popup-mobile': 'calc(100vw - 24px)',
  'popup-desktop': 'calc(100vw - 50px - 48px - 207px)',
  'list-item': 'calc(100vw - 84px)',
};
