import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataEspacesListProps } from '~/datas/props';

import DataListRender from './Render';

const DataEspacesList: FC<DataEspacesListProps> = props => {
  const { name } = props;

  return <FieldArray component={DataListRender} name={name} props={props} />;
};

export default DataEspacesList;
