import { StockType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Stock extends ModelEspace<StockType> {
  constructor(props: Omit<ModelEspaceProps<StockType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'stocks',
      orderDirection: 'desc',
      orderField: 'createdAt',
    });
  }

  public initialize(data?: { [key: string]: any }): Partial<StockType> {
    return super.initialize({
      ...data,
      quantity: data?.quantity ?? 1,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public set(id: string, values: Partial<StockType>): Promise<void> {
    throw new Error('Impossible de mettre à jour un stock');
  }
}

export default Stock;
