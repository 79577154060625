import {
  DocumentType,
  ProductSlotType,
  ProductVariantValuesType,
  ProduitType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import objectHash from 'object-hash';
import { DataProps, Group } from 'packages/formidable';
import { ProduitData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import slug from 'slug';

import HOCGroup from '~/components/Group/HOC';
import Modal from '~/components/View/Modal';
import Form from '~/containers/Espace/Form';
import VariantsList from '~/datas/Product/Variants/List';
import IconRedo from '~/icons/Redo';
import variantParams from '~/params/produit/variant.json';

export interface DataProductVariantsProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  docId: string;
  formName: string;
}

const DataProductVariants: FC<DataProductVariantsProps> = function ({
  display,
  docId,
  espaceId,
  formName,
  label,
}) {
  const dispatch = useDispatch();
  const [variantId, setVariantId] = useState<string>();
  const [variants, setVariants] = useState<DocumentType<ProduitType>[]>();

  useEffect(() => {
    let isMounted = true;
    const produitData = new ProduitData({
      espaceId,
      wheres: {
        kind: 'variant',
        parent: docId,
      },
    });

    const unsub = produitData.watch(docs => {
      if (isMounted) {
        setVariants(docs);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, espaceId]);

  const handleGenerateVariantsOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    // Il faut forcer l'enregistrement du produit, car les déclinaisons sont associées aux options
    dispatch(submit(formName));

    const produitData = new ProduitData({ espaceId });

    const product = await produitData.findById(docId);

    const generatedValues: {
      label: string;
      values: (string | ProductSlotType)[];
    }[] = [];
    if (product.slots && product.slots.length > 0) {
      generatedValues.push({
        label: 'slot',
        values: [...product.slots],
      });
    }
    if (product.options && product.options.length > 0) {
      generatedValues.push(...product.options);
    }

    if (generatedValues && generatedValues.length > 0) {
      const promises = generatedValues
        .reduce((acc, field) => {
          const newAcc: ProductVariantValuesType[] = [];
          const fieldLabel = slug(field.label);

          if (0 === acc.length) {
            return field.values.map(value => {
              if ('string' === typeof value) {
                return {
                  options: {
                    [fieldLabel]: value,
                  },
                };
              }

              return {
                slot: value,
              };
            });
          }

          field.values.forEach(value => {
            acc.forEach(a => {
              if ('string' === typeof value) {
                // c'est une option
                const tmp: ProductVariantValuesType = {
                  ...a,
                  options: {
                    ...a.options,
                    [fieldLabel]: value as string,
                  },
                };
                newAcc.push(tmp);
              } else {
                // c'est un créneau
                newAcc.push({
                  ...a,
                  slot: value,
                });
              }
            });
          });

          return newAcc;
        }, [] as ProductVariantValuesType[])
        .map(variantValues => {
          const variantCode = objectHash({ ...variantValues });

          return produitData
            .find({
              wheres: {
                variantCode,
                deleted: false,
                kind: 'variant',
                parent: docId,
              },
            })
            .then(querySnapshot => {
              // Est-ce que la déclinaison existe ?
              if (0 < querySnapshot.length) {
                return undefined;
              }

              let newLabel = variantValues.slot
                ? `${dayjs(variantValues.slot.startDate).format(
                    'dddd D MMM YYYY',
                  )} à ${variantValues.slot.startTime}`
                : '';
              const tmpOptions = variantValues.options;
              if (tmpOptions && Object.keys(tmpOptions).length > 0) {
                newLabel = Object.keys(tmpOptions).reduce((acc, key) => {
                  const tmpLabel = tmpOptions[key];

                  return acc ? `${acc} | ${tmpLabel}` : tmpLabel;
                }, newLabel);
              }

              // la combinaison n'existe pas, il faut la créer
              const newVariant = produitData.initialize({
                variantCode,
                variantValues,
                hasGlobalPricing: false,
                kind: 'variant',
                label: newLabel,
                parent: docId,
              });

              return produitData.create(newVariant);
            });
        });

      await Promise.all(promises);
    }
  };

  const handleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const id = event.currentTarget.getAttribute('data-id');
    if (id) {
      setVariantId(id);
    }
  };

  const handleCloseOnClick = () => {
    setVariantId(undefined);
  };

  const variant = variantId
    ? variants?.find(v => v.id === variantId)
    : undefined;

  return (
    <>
      {variantId && variant && (
        <Modal closeOnClick={handleCloseOnClick} title={variant.label}>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <div className="sticky top-0">
                <Group title="Déclinaisons">
                  {undefined !== variants && variants.length > 0 && (
                    <VariantsList
                      editOnClick={handleOnClick}
                      variants={variants}
                    />
                  )}
                </Group>
              </div>
            </div>
            <div className="col-span-2">
              <Form<ProduitType>
                displayClose={false}
                displayComments={false}
                displayHeader={false}
                displayHidden
                displayView={false}
                docId={variantId}
                footerProps={{
                  className: 'flex justify-end',
                }}
                formName="variant"
                // submitProps={{
                //   label: 'Enregistrer',
                //   variant: 'action',
                // }}
                hideSubmitButton
                model={new ProduitData({ espaceId, params: variantParams })}
                parentCollectionName="produits"
                type="update"
              />
            </div>
          </div>
        </Modal>
      )}
      <HOCGroup
        addIcon={IconRedo}
        addOnClick={handleGenerateVariantsOnClick}
        display={display}
        title={label ?? 'Liste des déclinaisons'}
      >
        <div>
          {undefined === variants && (
            <p>Chargement en cours des déclinaisons</p>
          )}
          {0 === variants?.length && <p>Aucune déclinaison</p>}
          {undefined !== variants && variants.length > 0 && (
            <VariantsList editOnClick={handleOnClick} variants={variants} />
          )}
        </div>
      </HOCGroup>
    </>
  );
};

export default DataProductVariants;
