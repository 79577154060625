import { CollectionType, DocumentType } from '@innedit/innedit-type';
import { DataField, DataFieldProps } from 'packages/formidable';
import { CollectionData, DocumentData, GenericData } from 'packages/innedit';
import React, { useEffect, useState, VFC } from 'react';

export interface DataDocumentsSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  collectionId: string;
  espaceId: string;
}

const DataDocumentsSelect: VFC<DataDocumentsSelectProps> = ({
  collectionId,
  espaceId,
  name,
  ...props
}) => {
  const [collection, setCollection] = useState<DocumentType<CollectionType>>();
  const [options, setOptions] = useState<DocumentType<any>[]>();

  useEffect(() => {
    let isMounted = true;
    if ('collections/' === collectionId.slice(0, 'collections/'.length)) {
      const id = collectionId.slice('collections/'.length);
      if (id) {
        const collectionData = new CollectionData();
        collectionData
          .findById(id)
          .then(document => {
            if (isMounted && document) {
              const newModel = new DocumentData({
                ...document,
                parentCollectionName: 'collections',
                parentId: id,
              });

              setCollection(document);

              return newModel.find();
            }

            return undefined;
          })
          .then(documents => {
            if (isMounted && documents) {
              setOptions(documents);
            }

            return false;
          })
          .catch(console.error);
      }
    } else {
      const genericData = new GenericData({
        espaceId,
        collectionName: collectionId,
        orderDirection: 'asc',
        orderField: 'label',
      });

      genericData
        .find()
        .then(documents => {
          if (isMounted) {
            setOptions(documents);
          }

          return false;
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, [collectionId]);

  // TODO voir pour vérifier l'affichage du label par rapport au labelFields
  return (
    <DataField
      {...props}
      componentType="select"
      name={name}
      options={
        !options
          ? [{ label: 'Chargement en cours', value: '' }]
          : options.map(option => ({
              label:
                collection &&
                collection.labelFields &&
                collection.labelFields.length > 0
                  ? option[collection.labelFields[0]]
                  : option.label,
              value: option.id,
            }))
      }
    />
  );
};

export default DataDocumentsSelect;
