import { DocumentType, PaymentType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import ItemRequiresCapture from '~/datas/Paiement/Manager/Stripe/RequiresCapture';
import RequiresPaymentMethod from '~/datas/Paiement/Manager/Stripe/RequiresPaymentMethod';
import ItemSucceeded from '~/datas/Paiement/Manager/Stripe/Succeeded';

const ItemStripe: FC<{ payment: DocumentType<PaymentType> }> = function ({
  payment,
}) {
  return (
    <>
      {!['canceled', 'requires_capture', 'requires_payment_method'].includes(
        payment.status,
      ) && <ItemSucceeded payment={payment} />}
      {'requires_capture' === payment.status &&
        'manual' === payment.captureMethod &&
        payment.amountCapturable > 0 && (
          <ItemRequiresCapture payment={payment} />
        )}
      {'requires_payment_method' === payment.status && (
        <RequiresPaymentMethod payment={payment} />
      )}
    </>
  );
};

export default ItemStripe;
