import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import DataAttributeDisplayRender from '~/datas/Attribute/Display/Render';
import { DataAttributeDisplayProps } from '~/datas/props';

const DataAttributeDisplay: FC<DataAttributeDisplayProps> = function (props) {
  return (
    <FieldArray
      component={DataAttributeDisplayRender}
      name={props.name}
      props={props}
    />
  );
};

export default DataAttributeDisplay;
