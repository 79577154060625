import { SchemaData } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import { DataSchemasFieldsProps } from '~/datas/props';
import IconFileImport from '~/icons/FileImport';
import IconPlus from '~/icons/Plus';

import DataSchemasFieldsItem from './Item';

const DataSchemasFieldsRender: FC<
  WrappedFieldArrayProps & Omit<DataSchemasFieldsProps, 'name'>
> = ({ description, display, fields, formName, params, title }) => {
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fields.push({
      name: '',
      value: '',
    });
  };

  const schemaId =
    params && formName && params[formName]
      ? params[formName].schemaId
      : undefined;

  const handleUpOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (undefined !== i && i > 0) {
      // On inverse le index et le index-1
      fields.swap(i - 1, i);
    }
  };

  const handleDownOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (fields && i < fields.length - 1) {
      // On inverse le index et le index+1
      fields.swap(i + 1, i);
    }
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  const handleImportOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const schemaData = new SchemaData();
    const schema = await schemaData.findById(schemaId);

    if (schema && schema.fields && schema.fields.length > 0) {
      schema.fields.forEach(field => {
        // On recherche s'il n'y a pas déjà un field avec le même name
        const index =
          fields.length > 0
            ? fields.getAll().findIndex(({ name }) => name === field.name)
            : undefined;
        if (undefined !== index && index >= 0) {
          // on replace le fields
          fields.insert(index, field);
          fields.remove(index + 1);
        } else {
          fields.push(field);
        }
      });
    }
  };

  return (
    <HOCGroup
      customInfos={
        <>
          {schemaId && (
            <Button
              iconLeft={IconFileImport}
              onClick={handleImportOnClick}
              variant="sub-action"
            />
          )}

          <Button
            iconLeft={IconPlus}
            onClick={handleAddOnClick}
            variant="sub-action"
          />
        </>
      }
      description={description}
      display={display}
      title={title}
    >
      {fields.length > 0 && (
        <div className="flex flex-col space-y-2">
          {fields.map((field: string, index: number) => (
            <DataSchemasFieldsItem
              key={field}
              deleteOnClick={handleDeleteOnClick}
              downOnClick={handleDownOnClick}
              formName={formName}
              index={index}
              name={field}
              params={params}
              upOnClick={handleUpOnClick}
            />
          ))}
        </div>
      )}
      {0 === fields.length && <p>Aucun attribut</p>}
    </HOCGroup>
  );
};

export default DataSchemasFieldsRender;
