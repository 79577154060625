import { DataType, DocumentType, PageType } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import { GroupBody, GroupTitle } from 'packages/formidable/components/Group';
import { PageData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Button from '~/components/Button';
import Media from '~/components/Media';
import { Box, Data, DataProps } from '~/packages/formidable';

export interface DataPageParentProps extends Omit<DataProps, 'componentType'> {
  channelId: string;
  datas?: DataType[];
  espaceId: string;
  parentId: string;
}

const DataPageParent: FC<DataPageParentProps> = ({
  channelId,
  datas,
  espaceId,
  parentId,
  title,
  titleProps,
  ...props
}) => {
  const [page, setPage] = useState<DocumentType<PageType>>();

  useEffect(() => {
    let isMounted = true;
    if (parentId) {
      const pageData = new PageData({
        channelId,
        espaceId,
      });

      pageData.watchById(parentId, newDocument => {
        if (isMounted) {
          setPage(newDocument);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [parentId]);

  return (
    <Box>
      {page && (
        <>
          {title && <GroupTitle {...titleProps}>{title}</GroupTitle>}
          <GroupBody className="bg-light-500">
            <div className="grid grid-cols-3 gap-3">
              {page.medias && page.medias.length > 0 && (
                <div>
                  <Media
                    className="rounded"
                    media={page.medias[0]}
                    options={{
                      height: 65,
                      width: 65,
                    }}
                  />
                </div>
              )}

              <div className="col-span-2">
                <h2 className="text-md">{page.label}</h2>
                <Button
                  to={`/espaces/${espaceId}/channels/${channelId}/pages/${parentId}/update/`}
                  variant="link"
                >
                  Retour à la page
                </Button>
              </div>
            </div>
            {datas &&
              datas.length > 0 &&
              datas.map(data => (
                <Data
                  key={objectHash(data)}
                  {...props}
                  {...data}
                  params={{
                    ...props.params,
                    parentId,
                    parentCollectionName: 'pages',
                  }}
                />
              ))}
          </GroupBody>
        </>
      )}
    </Box>
  );
};

export default DataPageParent;
