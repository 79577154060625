import 'firebase/auth';

import { SchemaType } from '@innedit/innedit-type';

import Model, { ModelProps } from '../Model';

class Schema extends Model<SchemaType> {
  constructor(props?: Omit<ModelProps<SchemaType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'schemas',
      orderDirection: props?.orderDirection || 'asc',
      orderField: props?.orderField || 'label',
      queryBy: 'label',
    });
  }
}

export default Schema;
