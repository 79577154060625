import { ChannelType, DocumentType } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import { Box, Data } from 'packages/formidable';
import { GroupBody, GroupTitle } from 'packages/formidable/components/Group';
import { ChannelData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Button from '~/components/Button';
import { DataChannelParentProps } from '~/datas/props';

const DataChannelParent: FC<DataChannelParentProps> = ({
  channelId,
  datas,
  espaceId,
  title,
  titleProps,
  ...props
}) => {
  const [channel, setChannel] = useState<DocumentType<ChannelType>>();

  useEffect(() => {
    let isMounted = true;
    if (channelId) {
      const channelData = new ChannelData({
        espaceId,
      });

      channelData.watchById(channelId, newDocument => {
        if (isMounted) {
          setChannel(newDocument);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [channelId]);

  return (
    <Box>
      {channel && (
        <>
          {title && <GroupTitle {...titleProps}>{title}</GroupTitle>}
          <GroupBody className="bg-light-500">
            <div className="grid grid-cols-3 gap-3">
              <div className="col-span-2">
                <h2 className="text-md">{channel.label}</h2>
                <Button
                  to={`/espaces/${espaceId}/channels/${channelId}/update`}
                  variant="link"
                >
                  Retour au canal
                </Button>
              </div>
            </div>
            {datas &&
              datas.length > 0 &&
              datas.map(data => (
                <Data
                  key={objectHash(data)}
                  {...props}
                  {...data}
                  params={{
                    ...props.params,
                    parentCollectionName: 'channels',
                    parentId: channelId,
                  }}
                />
              ))}
          </GroupBody>
        </>
      )}
    </Box>
  );
};

export default DataChannelParent;
