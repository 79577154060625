import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';
import { DataProps } from 'packages/formidable';
import { AnalyticsData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import IconLoading from '~/icons/Loading';
import IconRedo from '~/icons/Redo';

export interface DataAnalyticsProps extends Omit<DataProps, 'componentType'> {
  channelId: string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const DataAnalytics: FC<DataAnalyticsProps> = ({ channelId }) => {
  const mounted = useRef(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [nbPages, setNbPages] = useState<number>();
  const [nbSessions, setNbSessions] = useState<number>();
  const [nbUsers, setNbUsers] = useState<number>();
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [chartData, setChartData] = useState<any>();

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const generate = (after: string, before: string) => {
    if (mounted.current) {
      setIsLoading(true);
      setError(undefined);
      AnalyticsData.kpis({
        channelId,
        end: dayjs(before).add(1, 'd').format('YYYY-MM-DD'),
        start: after,
      })
        .then(result => {
          if (mounted.current) {
            setNbPages(result.nbPages);
            setNbSessions(result.nbSessions);
            setNbUsers(result.nbUsers);
            setIsLoading(false);
          }

          return true;
        })
        .catch(e => {
          toast.error(e);

          if (mounted.current) {
            setError(e.message);
          }
        });
    }

    AnalyticsData.users({
      channelId,
      end: dayjs(before).add(1, 'd').format('YYYY-MM-DD'),
      start: after,
    })
      .then(result => {
        if (mounted.current) {
          setChartData({
            datasets: [
              {
                data: result.map(row => row.count),
                label: 'Utilisateurs',
              },
            ],
            labels: result.map(row => dayjs(row.createdAt).format('D MMM')),
          });
        }

        return mounted.current;
      })
      .catch(e => {
        toast.error(e);

        if (mounted.current) {
          setError(e.message);
        }
      });
  };

  useEffect(() => {
    const today = dayjs();
    const newEnd = today.format('YYYY-MM-DD');
    const newStart = today.subtract(1, 'M').format('YYYY-MM-DD');

    if (mounted.current) {
      setStart(newStart);
      setEnd(newEnd);
      generate(newStart, newEnd);
    }
  }, []);

  const handleOnClick = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (start && end) {
      generate(start, end);
    }
  };

  const handleStartOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setStart(value);
    if (end) {
      generate(value, end);
    }
  };

  const handleEndOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setEnd(value);
    if (start) {
      generate(start, value);
    }
  };

  return (
    <HOCGroup
      customInfos={
        <Button
          iconLeft={IconRedo}
          onClick={handleOnClick}
          variant="sub-action"
        />
      }
      display="content"
      title="Statistiques"
    >
      {start && end && (
        <div className="-mx-6 -mt-6 mb-2 flex justify-center space-x-3 border-b px-6 pb-2 pt-2">
          <input
            className="rounded border px-1"
            onChange={handleStartOnChange}
            type="date"
            value={start}
          />
          <span>-</span>
          <input
            className="rounded border px-1"
            onChange={handleEndOnChange}
            type="date"
            value={end}
          />
        </div>
      )}

      {isLoading && !error && (
        <div className="flex h-[53px] items-center justify-center space-x-3">
          <IconLoading /> <span>Chargement en cours des statistiques ...</span>
        </div>
      )}
      {error && (
        <div className="flex h-[53px] items-center justify-center">
          <span className="text-danger-500">{error}</span>
        </div>
      )}
      {!isLoading && !error && (
        <div className="flex justify-around">
          {nbUsers !== undefined && (
            <div className="flex flex-col">
              <span>Utilisateurs</span>
              <span className="text-xl font-medium">{nbUsers}</span>
            </div>
          )}
          {nbSessions !== undefined && (
            <div className="flex flex-col">
              <span>Visites</span>
              <span className="text-xl font-medium">{nbSessions}</span>
            </div>
          )}
          {nbPages !== undefined && (
            <div className="flex flex-col">
              <span>Pages</span>
              <span className="text-xl font-medium">{nbPages}</span>
            </div>
          )}
        </div>
      )}

      {chartData && (
        <div className="px-1.5">
          <Line
            data={chartData}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
            }}
          />
        </div>
      )}
    </HOCGroup>
  );
};

export default DataAnalytics;
