import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';
import { DataSubscriptionProductsProps } from '~/datas/props';
import DataSubscriptionProductsSearch from '~/datas/Subscription/Products/Search';

import DataSubscriptionProductsRender from './Render';

const DataSubscriptionProducts: FC<DataSubscriptionProductsProps> = function ({
  display,
  name,
  title = 'Produits',
  ...props
}) {
  return (
    <HOCGroup display={display} title={title}>
      <DataSubscriptionProductsSearch
        espaceId={props.espaceId}
        formName={props.formName}
        name={name}
      />
      <FieldArray
        component={DataSubscriptionProductsRender}
        name={name}
        props={props}
      />
    </HOCGroup>
  );
};

export default DataSubscriptionProducts;
