import styled from '@emotion/styled';
import { ContentType, DocumentType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { diff } from 'deep-object-diff';
import { DataFieldProps } from 'packages/formidable';
import { ContentData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { change } from 'redux-form';

import Button from '~/components/Button';
import IconArrowUp from '~/icons/ArrowUp';
import { ReducersProps } from '~/reducers';
import control from '~/styles/control';
import spacing from '~/styles/spacing';

import Item from './Item';

const BoxTextarea = styled.div`
  background-color: ${control.bg.color.base};
  border-color: ${control.border.color.base};
  border-radius: ${control.radius.base};
  padding-left: ${spacing[4]};
  padding-right: ${spacing[2]};
  padding-bottom: 4px;
  padding-top: 4px;

  &:focus-within {
    background-color: ${control.bg.color.focus};
    border-color: ${control.border.color.hover};
  }
`;
const Textarea = styled.textarea`
  resize: none;
  outline: none;
  min-height: 24px;
  line-height: 24px;

  &:focus {
    outline: none;
  }
`.withComponent(TextareaAutosize);

export interface DataContentsExtraProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  parentCollectionName: string;
  parentId: string;
}

const usePreviousFormValues = (value?: any): any | undefined => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const DataContentsExtra: FC<DataContentsExtraProps> = ({
  espaceId,
  formName,
  label,
  name,
  parentCollectionName,
  parentId,
}) => {
  const dispatch = useDispatch();
  // const ref = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState<string>('');
  const [contents, setContents] = useState<DocumentType<ContentType>[]>();

  const formValues = useSelector(
    (state: ReducersProps<any>) => state.form[formName],
  );

  const previousValues = usePreviousFormValues(formValues);
  const differences: any = diff(previousValues, formValues);
  const { submitSucceeded } = differences;
  const reset =
    previousValues &&
    previousValues.values &&
    previousValues.values[name] &&
    previousValues.values[name].localeCompare(formValues.values[name]) > 0;

  const createContent = async (txt: string): Promise<void> => {
    const contentData = new ContentData({
      espaceId,
      parentCollectionName,
      parentId,
      parentField: name,
    });

    try {
      setText('');
      const data = contentData.initialize({
        order: contents?.length ?? 0,
        text: txt,
      });
      await toast.promise(contentData.create(data), {
        error: "Une erreur c'est produit lors de la création",
        pending: "En cours de création d'un contenu",
        success: 'La création du contenu a réussi',
      });
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  useEffect(() => {
    if (submitSucceeded && text && text !== '') {
      createContent(text);
    }
  }, [submitSucceeded, text]);

  useEffect(() => {
    if (reset && text) {
      setText('');
    }
  }, [reset, text]);

  // useEffect(() => {
  //   const current = ref?.current;
  //   const handleOnKeypress = (event: KeyboardEvent) => {
  //     if ('Enter' === event.key) {
  //       if (!event.altKey && current) {
  //         // On peut enregistrer un contenu
  //         createContent(current.value)
  //           .then(() => setText(''))
  //           .catch(error => toast.error(error.message));
  //       } else {
  //         // Il faut ajouter le retour à la ligne
  //         setText(oldText => `${oldText}\n`);
  //       }
  //     }
  //   };
  //
  //   if (current) {
  //     current.addEventListener('keypress', handleOnKeypress);
  //   }
  //
  //   return () => {
  //     if (current) {
  //       current.removeEventListener('keypress', handleOnKeypress);
  //     }
  //   };
  // }, [ref]);

  useEffect(() => {
    let isMounted = true;
    const contentData = new ContentData({
      espaceId,
      parentCollectionName,
      parentId,
      orderDirection: 'asc',
      orderField: 'createdAt',
      parentField: name,
    });

    contentData.watch(docs => {
      if (isMounted) {
        setContents(docs);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [espaceId, name, parentCollectionName, parentId]);

  const handleOnChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;

    setText(value);
    dispatch(change(formName, name, dayjs().toISOString()));
  };

  const handleUpdateField = () => {
    dispatch(change(formName, name, dayjs().toISOString()));
  };

  const handleOnCreate = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    return createContent(text);
  };

  return (
    <div>
      <span>{label}</span>
      <div>
        {contents && contents.length > 0 && (
          <div className="flex flex-col space-y-3 mb-6">
            {contents.map(content => (
              <Item
                key={content.id}
                onChange={handleUpdateField}
                reset={reset}
                submitSucceeded={submitSucceeded}
                value={content}
              />
            ))}
          </div>
        )}
      </div>
      <BoxTextarea className="flex items-end border">
        <Textarea
          className="rounded-r-none flex-1 bg-transparent m-1"
          onChange={handleOnChange}
          placeholder="Nouveau contenu"
          value={text}
        />
        <div>
          <Button
            className="rounded-full m-1"
            color="neutral"
            iconLeft={IconArrowUp}
            onClick={handleOnCreate}
            size="xs"
            square
          />
        </div>
      </BoxTextarea>
    </div>
  );
};

export default DataContentsExtra;
