import { DataProps, DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import ButtonGroup from '~/components/Button/Group';
import { ItemEL } from '~/datas/Contents/Crud/Item';
import IconDelete from '~/icons/Delete';
import IconPaste from '~/icons/Paste';
import { spacing } from '~/styles/theme';

export interface DataHyperLinkProps {
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  pasteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}

const DataHyperLink: FC<DataHyperLinkProps> = ({
  deleteOnClick,
  pasteOnClick,
  index,
  name,
}) => (
  <ItemEL className="item-list flex flex-row min-h-12 border p-1 rounded bg-light-300 items-center justify-between">
    <DataSection
      className="flex-1"
      datas={[
        {
          cols: ['none', 5],
          componentType: 'grid',
          datas: [
            {
              componentType: 'input',
              labelShow: false,
              name: 'label',
              placeholder: 'Libellé',
              wrapperProps: {
                col: ['full', 3],
              },
            },
            {
              componentType: 'input',
              fieldProps: {
                autoComplete: 'url',
              },
              labelShow: false,
              name: 'href',
              placeholder: 'URL',
              type: 'url',
              wrapperProps: {
                col: ['full', 2],
              },
            },
          ],
          gap: spacing[1],
        } as DataProps,
      ]}
      name={name}
    />
    <div className="ml-1 flex justify-between text-neutral-700">
      <div className="flex space-x-3 text-xs" />
      <div className="actions justify-center items-center">
        <ButtonGroup>
          <Button
            color="neutral"
            datas={{
              'data-field': name,
            }}
            iconLeft={IconPaste}
            onClick={pasteOnClick}
            size="xs"
            variant="outline"
          />
          {deleteOnClick && (
            <Button
              color="neutral"
              datas={{
                'data-index': index,
              }}
              iconLeft={IconDelete}
              onClick={deleteOnClick}
              size="xs"
              variant="outline"
            />
          )}
        </ButtonGroup>
      </div>
    </div>
  </ItemEL>
);

export default DataHyperLink;
