import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../Box';
import { DataFieldProps } from '../props';

const CustomBottomSC = styled.div`
  float: right;
`;

const CustomTopSC = styled.div`
  float: right;
`;

const FieldLabelSC = styled.label``;

const FieldWrapperComponent: FC<DataFieldProps> = ({
  children,
  componentType,
  customBottom,
  customBottomProps,
  customInfos,
  customInfosProps,
  customTop,
  customTopProps,
  id,
  label,
  labelShow = true,
  name,
  wrapperProps,
}) => {
  const { t } = useTranslation();

  if ('hidden' === componentType) {
    return <>{children}</>;
  }

  return (
    <Box {...wrapperProps}>
      {customInfos && (
        <CustomTopSC {...customInfosProps}>{customInfos}</CustomTopSC>
      )}
      {customTop && <CustomTopSC {...customTopProps}>{customTop}</CustomTopSC>}

      {labelShow && (
        <FieldLabelSC htmlFor={id}>
          {t ? t(label || name) : label || name}
        </FieldLabelSC>
      )}
      {children}

      {customBottom && (
        <CustomBottomSC {...customBottomProps}>{customBottom}</CustomBottomSC>
      )}
    </Box>
  );
};

export default FieldWrapperComponent;
