import {
  DocumentType,
  PaymentType,
  ProfessionalType,
} from '@innedit/innedit-type';
import classnames from 'classnames';
import objectHash from 'object-hash';
import { ProfessionalData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import Input from '~/components/Input';

import Professionals from '../Professionals';

interface ItemItemSucceededProps {
  payment: DocumentType<PaymentType>;
}

const ItemSucceeded: FC<ItemItemSucceededProps> = ({ payment }) => {
  const [, setProfessionals] = useState<DocumentType<ProfessionalType>[]>();

  const [selectedItem, setSelectedItem] = useState<number>();
  const [amount, setAmount] = useState<number>(0);
  const [maxAmount, setMaxAmount] = useState<number>(0);

  useEffect(() => {
    const professionnelModel = new ProfessionalData({
      espaceId: payment.espaceId,
    });
    const unsub = professionnelModel.watch(
      newDocs => {
        setProfessionals(newDocs);
      },
      {
        wheres: {
          hidden: false,
          stripeAccountHidden: false,
          stripeAccountVerified: true,
        },
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [payment.espaceId]);

  useEffect(() => {
    setSelectedItem(undefined);
    setMaxAmount(0);
    setAmount(0);
  }, [payment]);

  const handleOnChangeItem = (event: SyntheticEvent<HTMLInputElement>) => {
    if (undefined !== event.currentTarget.getAttribute('data-index')) {
      const index = parseInt(
        event.currentTarget.getAttribute('data-index') as string,
        10,
      );

      let newAmount = 0;

      if (payment.items) {
        newAmount =
          payment.items[index].quantity * payment.items[index].price -
          (payment.items[index].refundedAmount || 0);
      }

      setAmount(newAmount);
      setMaxAmount(newAmount);
      setSelectedItem(index);
    }
  };

  const handleOnChangeAmount = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const a = Number(event.currentTarget.value);
    setAmount(Math.min(a, maxAmount));
  };

  const handleRefundOnSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let url = `espaces/${payment.espaceId}/refunds/create`;
    url += `?paiement=${payment.id}&amount=${amount}&item=${selectedItem}`;

    fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`)
      .then(r => r.json())
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="mt-6 flex flex-col space-y-3">
        {payment.items?.map((item, index) => {
          const solde = item.quantity * item.price - (item.refundedAmount || 0);

          return (
            <div
              key={objectHash({ index, item })}
              className={classnames('flex flex-col', {
                'line-through': Boolean(item.canceledAt),
              })}
            >
              <div className="flex items-center space-x-3">
                <input
                  checked={selectedItem === index}
                  data-index={index}
                  disabled={
                    Boolean(item.canceledAt) ||
                    (undefined !== item.refundedAmount &&
                      item.refundedAmount > 0 &&
                      0 === solde)
                  }
                  onChange={handleOnChangeItem}
                  type="radio"
                />

                {item.thumbnail && (
                  <img alt={item.label} src={item.thumbnail} width={30} />
                )}
                <span className="overflow-hidden truncate text-ellipsis">
                  {item.label}
                </span>
                {undefined !== item.refundedAmount &&
                  item.refundedAmount > 0 &&
                  0 === solde && <span>Remboursé</span>}
                {undefined !== item.refundedAmount &&
                  item.refundedAmount > 0 &&
                  solde > 0 && (
                    <span className="whitespace-nowrap">{solde} €</span>
                  )}
                {solde > 0 && (
                  <span
                    className={classnames('whitespace-nowrap', {
                      'line-through':
                        undefined !== item.refundedAmount &&
                        item.refundedAmount > 0,
                    })}
                  >
                    {item.quantity > 1
                      ? `${item.quantity} x ${item.price} € = ${
                          item.quantity * item.price
                        } €`
                      : `${item.quantity * item.price} €`}
                  </span>
                )}
              </div>

              <Professionals
                espaceId={payment.espaceId}
                index={index}
                item={item}
                paymentId={payment.id}
              />
            </div>
          );
        })}
      </div>
      {undefined !== selectedItem && (
        <>
          {payment.amountReceived - payment.amountRefunded > 0 && (
            <div className="mt-6 flex flex-col">
              <h2>Remboursement</h2>
              <div className="flex">
                <Input
                  className="h-8 w-1/2 rounded-none rounded-l border-r-0"
                  max={maxAmount}
                  name="amount"
                  onChange={handleOnChangeAmount}
                  type="number"
                  value={amount}
                />
                <Button
                  className="w-1/2 rounded-none rounded-r"
                  color="neutral"
                  onClick={handleRefundOnSubmit}
                  size="sm"
                  text="Rembourser"
                  variant="groove"
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ItemSucceeded;
