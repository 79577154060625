import { ModelType } from '@innedit/innedit-type';

import Model, { ModelProps } from '../../Model';

class Generic<T extends ModelType> extends Model<T> {
  constructor(props: ModelProps<T>) {
    super({
      labelFields: ['label'],
      ...props,
    });
  }
}

export default Generic;
