import { DataSection } from 'packages/formidable';
import React, { FC, memo, SyntheticEvent } from 'react';

import ViewModal from '../../../components/View/Modal';
import data from './data.json';

interface ModalProps {
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  name: string;
  params?: { [key: string]: any };
  title: string;
}

const Modal: FC<ModalProps> = ({
  closeOnClick,
  formName,
  name,
  params,
  title,
}) => (
  <ViewModal
    buttons={{
      right: [{ label: 'Fermer', onClick: closeOnClick }],
    }}
    closeOnClick={closeOnClick}
    title={title}
  >
    <DataSection
      datas={data}
      formName={formName}
      name={name}
      params={{
        ...params,
        name,
      }}
    />
  </ViewModal>
);

const propsAreEqual = (prevProps: ModalProps, nextProps: ModalProps): boolean =>
  prevProps.formName === nextProps.formName &&
  prevProps.name === nextProps.name;

export default memo(Modal, propsAreEqual);
