import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';
import { DataEmailSectionsProps } from '~/datas/props';

import DataEmailSectionsItem from './Item';

const DataEmailSectionsRender: FC<
  WrappedFieldArrayProps & Omit<DataEmailSectionsProps, 'name'>
> = ({ display, espaceId, fields, formName, label, params }) => {
  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    fields.push({
      label: '',
    });
  };

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      display={display}
      title={label ?? 'Sections'}
    >
      {fields.length > 0 && (
        <>
          {fields.map((field, index) => (
            <DataEmailSectionsItem
              key={field}
              collectionName={params.collectionName}
              // data={fields.get(index)}
              deleteOnClick={handleDeleteOnClick}
              docId={params.docId}
              espaceId={espaceId}
              formName={formName}
              index={index}
              name={field}
            />
          ))}
        </>
      )}
      {0 === fields.length && <p>Aucune section</p>}
    </HOCGroup>
  );
};
export default DataEmailSectionsRender;
