import classnames from 'classnames';
import objectHash from 'object-hash';
import { Field } from 'packages/formidable';
import { parse } from 'query-string';
import React, {
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import Actions, { MenuType } from '~/components/Actions';
import { ActionProps } from '~/components/Actions/props';
import Button from '~/components/Button';
import { ListTabProps } from '~/components/Filter';
import FilterList from '~/components/Filter/List';
import { FilterType } from '~/components/Filter/types';
import Search from '~/components/Search';
import ToggleMenu from '~/components/ToggleMenu';
import IconChevronDown from '~/icons/ChevronDown';
import IconEllipsisH from '~/icons/EllipsisH';
import IconWindowMenu from '~/icons/WindowMenu';

import ViewTitle from './Title';

const MyCustomInput: FC<{ input: any }> = ({ input: { value } }) => (
  <span>{value}</span>
);

interface TitleProps {
  className?: string;
  title: string | string[] | any;
}

const Title: FC<TitleProps> = ({ className, title }) => {
  if (Array.isArray(title)) {
    return (
      <ViewTitle className={className}>
        {title.map(item => (
          <Field
            key={`title_${item.attribut}`}
            component={MyCustomInput}
            disabled
            name={item.attribut}
          />
        ))}
      </ViewTitle>
    );
  }

  if ('object' === typeof title) {
    return (
      <ViewTitle className={className}>
        <Field component={MyCustomInput} disabled name={title.attribut} />
      </ViewTitle>
    );
  }

  return title ? <ViewTitle className={className}>{title}</ViewTitle> : null;
};

const Header: FC<{
  displaySearch?: boolean;
  filters?: FilterType[];
  menu?: MenuType;
  search?: string;
  subMenu?: (ActionProps | ActionProps[])[];
  subTitle?: ReactNode;
  tabIndex?: number;
  tabs?: ListTabProps[];
  title?: string | TitleProps;
}> = function ({
  displaySearch,
  filters,
  menu = { left: [], right: [] },
  search,
  subMenu,
  subTitle,
  tabIndex = 0,
  tabs,
  title,
}) {
  const [nbActions, setNbActions] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLUListElement>(null);
  const [openTabs, setOpenTabs] = useState<boolean>(false);
  const [openActions, setOpenActions] = useState<boolean>(false);
  const params = search ? parse(search) : undefined;

  const handleToggleOpenActionsOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    setOpenActions(o => !o);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenActions(false);
    }
    if (tabRef.current && !tabRef.current.contains(event.target)) {
      setOpenTabs(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    const handleOnResize = () => {
      const { current } = divRef;
      if (current) {
        const useSpace = (current.offsetWidth - 24 - 36 - 36) / 4;
        setNbActions(Math.floor(useSpace / 36));
      }
    };
    window.addEventListener('resize', handleOnResize);

    handleOnResize();

    return () => {
      window.removeEventListener('resize', handleOnResize);
    };
  }, []);

  const rightMenu = menu?.right ? [...menu.right] : [];
  const displayRightMenu = [...rightMenu.splice(0, nbActions)];

  if (rightMenu.length > 0 || (filters && filters.length > 0)) {
    if (
      1 === rightMenu.length &&
      (filters === undefined || 0 === filters.length)
    ) {
      displayRightMenu.push(rightMenu[0]);
    } else {
      displayRightMenu.push({
        icon: IconEllipsisH,
        label: 'Ouvrir la fenêtre avec les options',
        onClick: handleToggleOpenActionsOnClick,
      });
    }
  }

  const handleOnClick = () => {
    setOpenTabs(o => !o);
  };

  return (
    <>
      <div
        ref={divRef}
        className="sticky top-0 z-30 bg-background bg-opacity-95"
      >
        <div className="flex flex-row items-center space-x-3 py-1">
          <nav className="flex">
            <ToggleMenu iconLeft={IconWindowMenu} variant="action" />
            {menu && menu.left && menu.left.length > 0 && (
              <Actions items={menu.left} />
            )}
          </nav>

          <div className="flex-1 overflow-hidden">
            {title && <Title className="truncate" title={title} />}
          </div>

          {displayRightMenu.length > 0 && (
            <nav>
              <Actions items={displayRightMenu} />
              {openActions && (
                <div
                  ref={ref}
                  className="absolute right-3 top-[48px] z-[100] min-w-[200px] max-w-popup-mobile overflow-y-auto rounded-md bg-dark-500 md:right-6 md:max-w-popup-desktop"
                >
                  {rightMenu && rightMenu.length > 0 && (
                    <div>
                      <Actions
                        className="flex flex-col"
                        items={rightMenu}
                        variant="option"
                      />
                    </div>
                  )}
                  <FilterList items={filters} params={params} />
                </div>
              )}
            </nav>
          )}
        </div>
        {subMenu && subMenu.length > 0 && (
          <div className=" pb-3">
            <Actions
              className="flex items-center space-x-1.5 overflow-x-auto rounded-[14px]"
              items={subMenu}
              variant="tab"
            />
          </div>
        )}
      </div>

      {displaySearch && <Search q={params?.q as string | undefined} />}
      {subTitle && <h2 className="px-6 pb-1.5 text-[22px]">{subTitle}</h2>}

      {tabs && (
        <div className="relative mt-3 flex justify-center md:justify-start">
          <div
            className={classnames('flex cursor-pointer items-center space-x-2')}
            onClick={handleOnClick}
            role="presentation"
          >
            <div>
              <IconChevronDown
                className={classnames('h-[12px] w-[12px]', {
                  'rotate-180 transform': openTabs,
                })}
              />
            </div>
            <div className="flex-1">
              {tabs[tabIndex] ? tabs[tabIndex].label : '-'}
            </div>
          </div>
          {openTabs && (
            <div className="absolute left-3 top-[33px] z-[100] flex w-popup-mobile justify-center md:left-0 md:w-auto">
              <ul ref={tabRef} className="rounded bg-dark-500 px-6 py-3">
                {(Array.isArray(tabs) ? tabs : [tabs]).map((tab, index) => (
                  <li key={objectHash(tab)}>
                    <Button
                      className={classnames(
                        'h-[32px] w-full justify-center text-light-600 focus:text-light-300 focus:outline-none md:justify-start',
                        {
                          'is-active': tabIndex === index,
                        },
                      )}
                      data-index={index}
                      to={tab.pathname}
                      type="button"
                      variant="clear"
                    >
                      {tab.label}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
